import { Axios } from 'axios'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'
import { Fundraiser } from '../getFundraiser/getFundraiser.types'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { EditFundraiserParams } from './editFundraiser.types'

export const editFundraiser: MutationFn<EditFundraiserParams, GsResponse<Fundraiser>> = async (
  client: Axios,
  params: EditFundraiserParams
) => {
  const data = new FormData()
  Object.keys(params.page).length !== 0 && data.append('page', JSON.stringify(params.page))
  params.image && data.append('image', params.image)

  const response = await client.patch<RawResponse<Fundraiser>>(`/fundraisers/${params.id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true
  })

  return toGsResponse(response.status, response.data)
}
