import { useParams } from 'react-router-dom-v6'
import { useTranslation } from 'react-i18next'
import { InfiniteData } from '@tanstack/react-query'
import { Box, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'

import { useInfiniteQuery, useLogger } from '@percent/workplace-giving/common/hooks'
import { AttendeesList } from '@percent/domain/volunteering'
import { GetOpportunityParticipantsResponse } from '@percent/workplace-giving/api/opportunity/getOpportunityParticipants/getOpportunityParticipants.types'
import { getOpportunityParticipants } from '@percent/workplace-giving/api/opportunity/getOpportunityParticipants/getOpportunityParticipants'
import { ButtonText, Loader } from '@percent/lemonade'
import * as Styles from './Attendees.styles'

type AttendeesProps = {
  participantSpots?: number | null
  currentUserAttending?: boolean
}

export function Attendees({ participantSpots, currentUserAttending }: Readonly<AttendeesProps>) {
  const { opportunityId } = useParams()
  const { t } = useTranslation()
  const { logError } = useLogger()

  const {
    data: attendees,
    error,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuery(['getOpportunityParticipants', opportunityId, { pageSize: 5 }], getOpportunityParticipants)

  const totalResults = attendees && attendees.pages.length > 0 ? attendees.pages[0].totalResults : 0

  const mappedData: InfiniteData<GetOpportunityParticipantsResponse['data']> | undefined = attendees && {
    ...attendees,
    pages: attendees.pages.map(page => page.data)
  }

  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error, logError])

  useEffect(() => {
    refetch()
  }, [currentUserAttending, refetch])

  const attendeesSummaryCopy = useMemo(() => {
    if (!totalResults) return t('workplace_giving.volunteering.noAttendees')

    if (participantSpots !== undefined && participantSpots !== null && !!totalResults)
      return t('workplace_giving.volunteering.attendeesSummaryWithLimit', {
        attending: totalResults,
        spotsLeft: participantSpots - totalResults
      })

    return t('workplace_giving.volunteering.attendeesSummary', {
      attending: totalResults
    })
  }, [totalResults, participantSpots, t])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Box sx={Styles.Wrapper}>
      <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.whoIsAttending')}</Typography>
      <Typography>{attendeesSummaryCopy}</Typography>
      {mappedData ? <AttendeesList data={mappedData} /> : null}
      {isFetching ? <Loader /> : null}
      {hasNextPage ? (
        <ButtonText disabled={isLoading || isFetching} onPress={() => fetchNextPage()} variant="default">
          {t('workplace_giving.home.loadMore')}
        </ButtonText>
      ) : null}
    </Box>
  )
}
