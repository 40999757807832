import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Title: Styles = {
  marginBottom: lemonadeTheme.sizes[4],
  fontSize: lemonadeTheme.sizes[6],
  lineHeight: lemonadeTheme.sizes[8],
  fontWeight: lemonadeTheme.fontWeights.semiBold
}

export const ItemWrapper: Styles = {
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  gap: lemonadeTheme.sizes[4],
  padding: `${lemonadeTheme.sizes[4]} 0`,
  borderBottom: `1px solid ${customPalette.gray90}`
}

export const AdditionalInfo: Styles = {}

export const ActionsWrapper: Styles = {
  marginLeft: 'auto'
}
