import { GetAccountFundraiser } from '../getAccountFundraisers/getAccountFundraisers.types'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CloseFundraiserProps, CloseFundraiserResponse } from './closeFundraiser.types'

export const closeFundraiser: MutationFn<CloseFundraiserProps, GetAccountFundraiser | undefined> = async (
  client,
  { id }
) => {
  try {
    const response = await client.post<CloseFundraiserResponse>(
      `/fundraisers/${id}/close`,
      {},
      {
        withCredentials: true
      }
    )

    return response.data.data
  } catch (e) {
    return undefined
  }
}
