import { useEffect, useRef } from 'react'
import { stringifyUrl } from 'query-string'

import { config } from '@percent/workplace-giving/config/config'

const WIDGET_ID = 'ze-snippet'

export function useZendeskWidget() {
  const scriptRef = useRef<HTMLScriptElement>()

  useEffect(() => {
    if (config.keys.zendeskWidgetKey) {
      const zendeskScript = document.getElementById(WIDGET_ID)

      if (zendeskScript) {
        scriptRef.current = zendeskScript as HTMLScriptElement
      } else {
        const widgetSrc = stringifyUrl({
          url: 'https://static.zdassets.com/ekr/snippet.js',
          query: {
            key: config.keys.zendeskWidgetKey
          }
        })

        const newZendeskScript = document.createElement('script')
        newZendeskScript.setAttribute('id', WIDGET_ID)
        newZendeskScript.setAttribute('src', widgetSrc)

        document.body.appendChild(newZendeskScript)
      }
    }

    return () => {
      scriptRef.current?.remove()
    }
  }, [])

  return null
}
