import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { translationResources } from './resources'
import localeJson from './data/en/wpg.json'

i18n.use(initReactI18next).init({
  resources: translationResources,
  lng: 'en-US',
  fallbackLng: 'en-US',
  defaultNS: 'workplaceGiving',
  returnEmptyString: false
})

// eslint-disable-next-line import/no-default-export
export default i18n

type FilteredPath<T, K extends keyof T = keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? `${K}.${FilteredPath<T[K], keyof T[K]>}`
    : K
  : never

type TranslationPaths<T> = FilteredPath<T>

export type LocaleKey = TranslationPaths<typeof localeJson>
