import { useContext } from 'react'

import { RateLimitContext } from '@percent/workplace-giving/context/rateLimit/rateLimitContext/RateLimitContext'

export const useRateLimit = () => {
  const context = useContext(RateLimitContext)

  if (context === undefined) {
    throw new Error('RateLimitContext must be within RateLimitContextProvider')
  }

  return context
}
