import { Opportunity } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

export const getOpportunityDetails: QueryFn<[string, string], Opportunity> = async (client, [_, opportunityId]) => {
  const res = await client.get<{ data: Opportunity }>(`/opportunities/${opportunityId}/details`, {
    withCredentials: true
  })

  return res.data.data
}

export const getOpportunities: QueryFn<[string, string[]], Opportunity[]> = async (client, [_, opportunityIds]) =>
  Promise.all(opportunityIds.map(async id => getOpportunityDetails(client, ['getOpportunityDetails', id])))
