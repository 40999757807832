import { Box } from '@mui/material'

import * as Styles from './SidePanel.styles'
import { MatchingBudget } from '@percent/workplace-giving/common/components/Donation/MatchingBudget/MatchingBudget'
import { TotalDonated } from '@percent/workplace-giving/common/components/Donation/TotalDonated/TotalDonated'
import { useMatchingBudget, useDonationWallet, useDonationSummary } from '@percent/workplace-giving/common/hooks'
import { SidePanelSkeleton } from './SidePanelSkeleton'
import { SidePanelContainer } from '@percent/workplace-giving/common/components/Layout/SidePanelContainer/SidePanelContainer'
import { SidePanelFooter } from '@percent/workplace-giving/common/components/SidePanelFooter/SidePanelFooter'
import { WalletBalance } from '@percent/workplace-giving/common/components/WalletBalance/WalletBalance'

type SidePanelProps = Readonly<{
  home?: boolean
}>

export function SidePanel({ home = false }: SidePanelProps) {
  const {
    data: donationSummary,
    isFetching: donationSummaryLoading,
    refetch: refetchDonationSummary
  } = useDonationSummary()
  const {
    data: matching,
    isFetching: donationMatchingSummaryLoading,
    refetch: refetchDonationMatchingSummary
  } = useMatchingBudget()
  const { data: wallet, isFetching: donationWalletLoading, refetch: refetchDonationWallet } = useDonationWallet()

  const isLoading =
    (!donationSummary && donationSummaryLoading) ||
    (!matching && donationMatchingSummaryLoading) ||
    (!wallet && donationWalletLoading)

  const refetchBudgetData = () => {
    refetchDonationMatchingSummary()
    refetchDonationSummary()
  }

  if (isLoading) {
    return <SidePanelSkeleton />
  }

  return (
    <SidePanelContainer offset={home ? -266 : 0}>
      {!donationWalletLoading && wallet && <WalletBalance balance={wallet.balance} />}
      <Box sx={Styles.TotalDonationContainer}>
        {donationSummary && (
          <TotalDonated
            totals={donationSummary}
            refetchBudgetData={refetchBudgetData}
            refetchDonationWallet={refetchDonationWallet}
          />
        )}
        {matching?.usedAmount && matching?.remainingAmount && matching?.resetDate ? (
          <MatchingBudget
            remainingAmount={matching.remainingAmount}
            usedAmount={matching.usedAmount}
            pendingAmount={matching.pendingAmount}
            resetDate={matching.resetDate}
          />
        ) : null}
      </Box>
      <SidePanelFooter />
    </SidePanelContainer>
  )
}
