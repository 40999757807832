import styled from 'styled-components'

import { Styles } from '@percent/workplace-giving/theme'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Container: Styles = {
  marginBottom: '40px',
  display: 'flex',
  flexFlow: 'column',
  gap: '28px',
  flex: 6,

  [queryWidthLessThan('xl')]: {
    flex: 1
  }
}

export const ResultsSet: Styles = {
  display: 'flex',
  flexFlow: 'column',
  gap: '16px'
}

export const NumberResults: Styles = {
  color: theme => theme.palette.text.primary,
  [queryWidthLessThan('xl')]: {
    marginTop: '20px'
  },
  display: 'flex',
  alignItems: 'center',
  img: {
    margin: '0 4px'
  },
  svg: {
    margin: '0 4px'
  },
  [queryWidthLessThan('xs')]: {
    display: 'block',
    svg: {
      display: 'inline-block',
      width: '26px',
      verticalAlign: 'top'
    }
  }
}

type StyledBoldProps = {
  noCursor?: boolean
}

export const StyledCountryBold = styled.b<StyledBoldProps>`
  font-weight: 500;
  margin-right: 4px;
`

export const StyledCountryContainer = styled.div`
  display: flex;
  background-color: ${lemonadeTheme.colors.gray70};
  padding: 4px 6px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 4px;
`

export const StyledSpan = styled.span`
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  display: flex;
  align-items: center;
`
