import { CardContentWrapper } from '../../OpportunitiesList/OpportunitiesList.styles'

import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const SignUpContainer: Styles = {
  borderRadius: lemonadeTheme.borderRadii.large,
  padding: lemonadeTheme.sizes[5],
  marginBottom: lemonadeTheme.sizes[4],
  boxShadow: '0px 2px 16px 0px #0D0D0D14',
  backgroundColor: lemonadeTheme.colors.white,

  '& p': {
    fontSize: lemonadeTheme.fontSizes.bodySmall
  }
}

export const DetailsContainer: Styles = {
  ...CardContentWrapper,

  svg: {
    minWidth: lemonadeTheme.sizes[6]
  }
}

export const DateTime = (opportunityCancelled: boolean): Styles => ({
  p: {
    textDecoration: opportunityCancelled ? 'line-through' : 'none',
    textDecorationColor: customPalette.gray300
  }
})

export const OrganisationInfo: Styles = {
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginTop: lemonadeTheme.sizes[6],

  '& > div ': {
    'p:first-of-type': {
      color: customPalette.gray300,
      fontSize: lemonadeTheme.fontSizes.bodySmall
    }
  }
}

export const OrganiserInfo: Styles = {
  paddingX: lemonadeTheme.sizes[4],
  color: customPalette.gray600,
  fontSize: lemonadeTheme.fontSizes.bodySmall
}

export const CancelAttendance: Styles = {
  display: 'flex',
  flexWrap: 'wrap !important',

  '& > p': {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6],
    color: customPalette.gray600
  }
}

export const Address: Styles = {
  button: {
    whiteSpace: 'normal',
    textAlign: 'left'
  }
}

export const Description: Styles = {
  fontWeight: lemonadeTheme.fontWeights.medium,
  lineHeight: lemonadeTheme.sizes[6],
  marginBottom: lemonadeTheme.sizes[6]
}

export const AlertWrapper: Styles = {
  flexFlow: 'column !important',
  width: '100%',
  flexWrap: 'nowrap',
  marginBottom: `${lemonadeTheme.sizes[6]} !important`
}

export const TextLight: Styles = {
  color: `${customPalette.gray300} !important`
}
