import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Wrapper: Styles = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: `${lemonadeTheme.sizes[4]} 0`,
  borderBottom: `1px solid ${customPalette.gray90}`
}

export const IconWrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: lemonadeTheme.sizes[10],
  minWidth: lemonadeTheme.sizes[10],
  height: lemonadeTheme.sizes[10],
  marginRight: lemonadeTheme.sizes[4],
  backgroundColor: customPalette.gray70,
  borderRadius: lemonadeTheme.borderRadii.small
}

export const Title: Styles = {
  fontSize: lemonadeTheme.sizes[4],
  lineHeight: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.medium
}

export const Content: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[6],
  color: customPalette.gray600
}
