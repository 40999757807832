import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Container: Styles = {
  boxSizing: 'border-box',
  backgroundColor: lemonadeTheme.colors.white,
  borderRadius: '8px',
  overflow: 'hidden',

  '& > *': {
    fontFamily: 'Inter, sans-serif !important'
  }
}

export const ContentWrapper: Styles = {
  boxSizing: 'border-box',
  maxHeight: '92vh',
  padding: '32px 24px 32px',
  overflowY: 'auto',

  '@media (min-width: 768px)': {
    padding: '40px 56px 32px'
  }
}

export const Header: Styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: lemonadeTheme.sizes[5]
}

export const Icon: Styles = {
  fontSize: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '72px',
  width: '72px',
  minWidth: '72px',
  marginLeft: lemonadeTheme.sizes[2],
  borderRadius: '50%',
  backgroundColor: lemonadeTheme.colors.warning100
}

export const Title: Styles = {
  fontWeight: '600',
  fontSize: lemonadeTheme.fontSizes.h5,
  lineHeight: lemonadeTheme.sizes[8],
  color: lemonadeTheme.colors.black
}

export const Description: Styles = {
  fontWeight: '400',
  fontSize: lemonadeTheme.fontSizes.body,
  lineHeight: lemonadeTheme.sizes[6],
  color: customPalette.gray600
}

export const Form: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}

export const ErrorMessage: Styles = {
  color: customPalette.alert400
}

export const Wave: Styles = {
  animationName: 'wave-animation',
  animationDuration: '2.5s',
  animationIterationCount: 'infinite',
  transformOrigin: '70% 70%',
  display: 'inline-block',

  '@keyframes wave-animation': {
    '0%': { transform: 'rotate( 0.0deg)' },
    '10%': { transform: 'rotate(14.0deg)' },
    '20%': { transform: 'rotate(-8.0deg)' },
    '30%': { transform: 'rotate(14.0deg)' },
    '40%': { transform: 'rotate(-4.0deg)' },
    '50%': { transform: 'rotate(10.0deg)' },
    '60%': { transform: 'rotate( 0.0deg)' },
    '100%': { transform: 'rotate( 0.0deg)' }
  }
}

export const Spacer: Styles = {
  height: '1px',
  width: '100%',
  backgroundColor: lemonadeTheme.colors.gray80
}

export const ButtonsWrapper: Styles = {
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
  gap: lemonadeTheme.sizes[2],
  marginTop: lemonadeTheme.sizes[6]
}

export const TermsInfo: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[6]
}
