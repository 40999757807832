import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, Modal } from '@percent/lemonade'

type CloseFundraiserSuccessModalProps = {
  open: boolean
  handleClose: VoidFunction
}

export function CloseFundraiserSuccessModal({ open, handleClose }: CloseFundraiserSuccessModalProps) {
  const { t } = useTranslation()

  return (
    <Modal open={open}>
      <AcknowledgeModal
        title={t('workplace_giving.myFundraiser.close.success.title')}
        description={t('workplace_giving.myFundraiser.close.success.description')}
        result="positive"
        buttonText={t('workplace_giving.myFundraiser.close.success.close')}
        handleClose={handleClose}
      />
    </Modal>
  )
}
