import { FlagAvatar, Text } from '@percent/lemonade'
import { CurrencyCode } from '@percent/utility'
import { Alpha2Code } from 'i18n-iso-countries'
import styled from 'styled-components'

const Flag = styled.div`
  ${({ theme }) => `
  pointer-events: none;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  margin-right: ${theme.sizes.s};
  gap: ${theme.sizes.xs};

  & span {
    color: ${theme.colors.gray600};
  }`}
`

export const CurrencyFlag = ({ currency }: { currency: CurrencyCode }) => {
  const currencyFlagCode = currency.substring(0, 2) as Alpha2Code
  return (
    <Flag>
      <FlagAvatar code={currencyFlagCode} />
      <Text size="small">{currency}</Text>
    </Flag>
  )
}

export const CurrencyInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const CurrencyFlagBox = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
`
