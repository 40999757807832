import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import { Text, Icon } from '@percent/lemonade'
import { PaginationItem } from 'libs/shared/ui-lemonade/src/components/pagination/pagination-item'
import * as Styles from './CursorPagination.styles'

export interface PaginationProps {
  totalRecords?: number
  nextPage: () => void
  previousPage: () => void
  hasNextPage?: boolean
  hasPreviousPage?: boolean
}

export function CursorPagination({
  nextPage,
  previousPage,
  totalRecords,
  hasNextPage,
  hasPreviousPage
}: PaginationProps) {
  const { t } = useTranslation()

  return (
    <Box sx={Styles.Wrapper}>
      <Box sx={Styles.Results}>
        <Text as="span" size="small">
          {totalRecords ? `${t('workplace_giving.pagination.results', { count: totalRecords })}` : ''}
        </Text>
      </Box>
      <Box sx={Styles.Flex}>
        <PaginationItem isDisabled={!hasPreviousPage} onPress={() => previousPage()}>
          <Icon name="chevron-left" size="s" />
        </PaginationItem>

        <PaginationItem isDisabled={!hasNextPage} onPress={() => nextPage()}>
          <Icon name="chevron-right" size="s" />
        </PaginationItem>
      </Box>
    </Box>
  )
}
