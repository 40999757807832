/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import { useContext } from 'react'
import { UNSAFE_NavigationContext, useNavigate, useLocation } from 'react-router-dom-v6'
import { QueryParamAdapter, QueryParamAdapterComponent } from 'use-query-params'

/**
 * Query Param Adapter for react-router v6
 */
export const ReactRouter6Adapter: QueryParamAdapterComponent = ({ children }) => {
  const { navigator } = useContext(UNSAFE_NavigationContext)
  const navigate = useNavigate()
  const location = useLocation()

  const adapter: QueryParamAdapter = {
    replace(location) {
      navigate(location.search || '?', {
        replace: true,
        state: location.state
      })
    },
    push(location) {
      navigate(location.search || '?', {
        replace: false,
        state: location.state
      })
    },
    get location() {
      // be a bit defensive here in case of an unexpected breaking change in React Router
      return (navigator as any)?.location ?? location
    }
  }

  return children(adapter)
}
