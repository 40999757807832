import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UseQueryResult } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v6'

import { dayjs, getDurationTime } from '../../../../utils/dayjs/dayjs'

import { CopyContentButton } from '@percent/domain/giving'
import { Alert, Avatar, Button, ButtonText, Icon, Tooltip } from '@percent/lemonade'
import * as Styles from './OpportunityDetailsSidebar.styles'
import {
  Opportunity,
  OpportunityLocationType,
  OpportunityStatus,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { useAuth, useLogger } from '@percent/workplace-giving/common/hooks'
import { SidePanelContainer } from '@percent/workplace-giving/common/components/Layout/SidePanelContainer/SidePanelContainer'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { registerForOpportunity } from '@percent/workplace-giving/api/opportunity/registerForOpportunity/registerForOpportunity'
import { RegisterAction } from '@percent/workplace-giving/api/opportunity/registerForOpportunity/registerForOpportunity.types'
import { getStartsIn } from './getStartsIn'
import { OpportunityDetailsSidebarSkeleton } from '@percent/workplace-giving/app/Volunteer/OpportunityDetails/OpportunityDetailsSidebar/OpportunityDetailsSidebarSkeleton'
import {
  getAccountFromAuthState,
  getPartnerFromAuthState,
  getTimeZoneFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { createShortLink } from '@percent/utility'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { AddToCalendar } from './AddToCalendar/AddToCalendar'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import defaultOrgLogo from '@percent/workplace-giving/assets/icons/placeholder-logo.svg'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { LocaleKey } from '@percent/workplace-giving/i18n/config'

export const getEventStartsTranslations = (scale: 'days' | 'hours' | 'minutes' | 'started'): string => {
  const map = {
    days: 'workplace_giving.common.days',
    hours: 'workplace_giving.common.hours',
    minutes: 'workplace_giving.common.minutes',
    started: 'workplace_giving.volunteering.opportunityHasStarted'
  } as const

  return map[scale]
}

export const getEventsStartsInTranslations = (scale: 'days' | 'hours' | 'minutes' | 'started') => {
  const map = {
    days: 'workplace_giving.volunteering.opportunityStartsDays',
    hours: 'workplace_giving.volunteering.opportunityStartsHours',
    minutes: 'workplace_giving.volunteering.opportunityStartsMinutes',
    started: 'workplace_giving.volunteering.opportunityHasStarted'
  } as const

  return map[scale]
}

export function OpportunityDetailsSidebar({
  refetch,
  isLoading,
  noSessions,
  ...props
}: Opportunity & Pick<UseQueryResult, 'refetch' | 'isLoading'> & { noSessions?: boolean }) {
  const {
    status,
    type,
    endDate,
    spots,
    participation,
    id,
    startDate,
    location,
    organisation,
    organiser,
    participantsCount,
    timeTracking,
    external,
    isSeries
  } = props
  const { track } = useAnalytics()
  const { t } = useTranslation()
  const { logError } = useLogger()
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const { state } = useAuth()
  const timeZone = getTimeZoneFromAuthState(state) ?? undefined
  const account = getAccountFromAuthState(state)!
  const partner = getPartnerFromAuthState(state)!

  const navigate = useNavigate()

  const { mutateAsync, isLoading: isLoadingMutation } = useMutation({
    mutationFn: registerForOpportunity,
    onSuccess: res => {
      if (okResponse(res)) {
        refetch()
      } else {
        logError(res.error)
        setOpenErrorModal(true)
      }
    },
    onError: e => {
      logError(e)
      setOpenErrorModal(true)
    }
  })

  const { formatDate, formatDurationDateTime, formatTime } = useDateTimeFmt()

  const opportunityCancelled = status === OpportunityStatus.CANCELLED
  const opportunityEnded = endDate ? dayjs(endDate) <= dayjs(new Date()) : false
  const fullyBooked = participantsCount === spots
  const hideJoinButton =
    participation.status === 'participating' || opportunityEnded || opportunityCancelled || fullyBooked || isSeries
  const loadSkeleton = isLoading || isLoadingMutation

  const scrollToSessions = () => {
    const sessionsSection = document.getElementById('sessions-section')

    sessionsSection?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleRegisterAction = async (action: RegisterAction) => {
    await mutateAsync({ id, action, timeZone })
    track({
      event:
        action === RegisterAction.register
          ? 'Volunteering Opportunity Join'
          : 'Volunteering Opportunity Cancel Attendance',
      properties: {
        opportunityType: type,
        opportunityLocationType: location.type
      }
    })
  }

  const eventStarts = startDate ? getStartsIn(new Date(startDate)) : undefined
  const eventStartsInTranslation = eventStarts ? getEventsStartsInTranslations(eventStarts.type) : undefined
  const eventStartsTranslation = eventStarts ? getEventStartsTranslations(eventStarts.type) : undefined
  const eventHasAlreadyStarted = dayjs(startDate).isBefore(new Date())

  const getOpportunityStatusAlert = () => {
    if (opportunityCancelled) {
      return <Alert variant="warning" title={t('workplace_giving.volunteering.alert.opportunityCancelled')} />
    }

    if (opportunityEnded) {
      return <Alert variant="info" title={t('workplace_giving.volunteering.alert.opportunityEnded')} />
    }

    if (participation.status === 'participating') {
      const getTimeTrackingCopy = () => {
        if (type === OpportunityType.PROJECT) {
          return t('workplace_giving.volunteering.alert.manualTimeTrackingProject')
        }

        if (timeTracking === 'manual') {
          return t('workplace_giving.volunteering.alert.manualTimeTrackingEvent')
        }

        return t('workplace_giving.volunteering.alert.automaticTimeTrackingEvent')
      }

      return (
        <>
          <Alert variant="success" title={t('workplace_giving.volunteering.alert.youAreAttending')}>
            {getTimeTrackingCopy()}
          </Alert>
          <Box sx={Styles.CancelAttendance}>
            <Typography mr={0.5}> {t('workplace_giving.volunteering.cantMakeIt')}</Typography>
            <ButtonText onPress={() => handleRegisterAction(RegisterAction.unregister)}>
              {t('workplace_giving.volunteering.cancelYourAttendance')}
            </ButtonText>
          </Box>
        </>
      )
    }

    if (fullyBooked) {
      return (
        <Alert variant="info" title={t('workplace_giving.volunteering.alert.fullyBooked.title')}>
          {t('workplace_giving.volunteering.alert.fullyBooked.description')}
        </Alert>
      )
    }

    return null
  }

  const opportunityAddressDetails = useMemo(() => {
    if (
      location.type === OpportunityLocationType.VIRTUAL &&
      participation.status === 'participating' &&
      !opportunityEnded &&
      location.link
    ) {
      return (
        <ButtonText
          onPress={() => {
            if (location.type === OpportunityLocationType.VIRTUAL && location.link) {
              window.open(location.link, '_blank')
            }
          }}
        >
          {t('workplace_giving.volunteering.joinOnlineLink')}
        </ButtonText>
      )
    }

    if (location.type === OpportunityLocationType.VIRTUAL) {
      return <Typography>{t('workplace_giving.volunteering.virtualOpportunity')}</Typography>
    }

    if (location.lat && location.long) {
      return (
        <ButtonText
          onPress={() => {
            window.open(
              `https://google.com/maps/place/${location.addressLineOne},+${location.city}+${location.zipCode},+${location.country}/@${location.lat},${location.long},17z`,
              '_blank',
              'noopener noreferrer'
            )
          }}
        >
          {`${location.addressLineTwo || ''}${location.addressLineTwo ? ', ' : ''}${location.addressLineOne}, ${
            location.city
          } ${location.zipCode}, ${location.country}`}
        </ButtonText>
      )
    }

    return (
      <Typography>
        {`${location.addressLineTwo || ''}${location.addressLineTwo ? ', ' : ''}${location.addressLineOne}, ${
          location.city
        } ${location.zipCode}, ${location.country}`}
      </Typography>
    )
  }, [location, opportunityEnded, participation.status, t])

  const getOpportunityDurationDetails = useMemo(() => {
    if (startDate && endDate) {
      const { days, hours, minutes } = getDurationTime(startDate, endDate, true)

      if (days > 1 || (days === 1 && hours)) {
        return `${t('workplace_giving.volunteering.opportunityDuration.multipleDays')}`
      }

      if (days === 1) {
        return `${t('workplace_giving.volunteering.opportunityDuration.days', { count: 1 })}`
      }

      if (hours) {
        return `${t('workplace_giving.volunteering.opportunityDuration.hours', { count: hours })}${
          minutes ? ` ${t('workplace_giving.volunteering.opportunityDuration.minutes', { count: minutes })}` : ''
        }`
      }

      return `${t('workplace_giving.volunteering.opportunityDuration.minutes', { count: minutes })}`
    }

    return undefined
  }, [endDate, startDate, t])

  const getOpportunityOrganiserTranslation = useMemo(() => {
    if (external?.provider) {
      return organiser?.id === account?.id
        ? t('workplace_giving.volunteering.externalProviderOpportunityCreatedByYou', {
            providerName: external?.provider
          })
        : t('workplace_giving.volunteering.externalProviderOpportunity', { providerName: external?.provider })
    }

    return organiser?.id === account?.id
      ? t('workplace_giving.sidepanel.organisedByYou')
      : `${t('workplace_giving.sidepanel.organisedBy')} ${organiser?.fullName || partner.name} `
  }, [account, external, organiser, partner, t])

  if (loadSkeleton) {
    return <OpportunityDetailsSidebarSkeleton alertSkeleton={participation.status !== 'not_participating'} />
  }

  return (
    <SidePanelContainer>
      <Box data-testid="opportunity-details-sidebar" sx={Styles.SignUpContainer}>
        <Box
          sx={Styles.DetailsContainer}
          mb={
            !isSeries &&
            (!!getOpportunityStatusAlert() ||
              timeTracking !== 'manual' ||
              startDate ||
              endDate ||
              (!opportunityEnded && eventStarts && eventStartsInTranslation && eventStartsTranslation))
              ? 4
              : 0
          }
        >
          {getOpportunityStatusAlert() ? <Box sx={Styles.AlertWrapper}>{getOpportunityStatusAlert()}</Box> : null}
          {!startDate ? null : (
            <>
              {eventHasAlreadyStarted && !endDate ? null : (
                <Box sx={Styles.DateTime(opportunityCancelled)}>
                  <Icon name="calendar" size="m" color="neutral300" />
                  <Box>
                    <Typography>{formatDate(new Date(startDate))}</Typography>
                    <Typography sx={Styles.TextLight}>
                      {formatDurationDateTime(new Date(startDate), endDate ? new Date(endDate) : undefined)}
                    </Typography>
                  </Box>
                </Box>
              )}
              {getOpportunityDurationDetails ? (
                <Box sx={Styles.DateTime(opportunityCancelled)}>
                  <Icon name="duration" size="m" color="neutral300" />
                  <Box>
                    <Typography>{`${t(
                      'workplace_giving.volunteering.opportunityDuration.lasts'
                    )} ${getOpportunityDurationDetails} `}</Typography>
                  </Box>
                </Box>
              ) : null}
            </>
          )}
          {!startDate && !!endDate ? (
            <Box sx={Styles.DateTime(opportunityCancelled)}>
              <Icon name="calendar" size="m" color="neutral300" />
              <Box>
                <Typography sx={Styles.TextLight}>{t('workplace_giving.volunteering.opportunityEndsOn')}</Typography>
                <Typography>{formatDate(new Date(endDate))}</Typography>
                <Typography sx={Styles.TextLight}>{formatTime(new Date(endDate))}</Typography>
              </Box>
            </Box>
          ) : null}
          {!opportunityEnded && eventStarts && eventStartsInTranslation && eventStartsTranslation && (
            <>
              {timeTracking === 'manual' ? null : (
                <Box>
                  <Icon name="clock" size="m" color="neutral300" />
                  <Typography>
                    {eventStarts.count
                      ? t(eventStartsInTranslation, { count: eventStarts.count })
                      : t(eventStartsTranslation as LocaleKey)}
                  </Typography>
                </Box>
              )}
              <Box sx={Styles.Address}>
                <Icon
                  name={location.type === OpportunityLocationType.VIRTUAL ? 'virtual-meeting' : 'pin'}
                  size="m"
                  color="neutral300"
                />
                {opportunityAddressDetails}
              </Box>
            </>
          )}
        </Box>
        {isSeries ? (
          <Typography sx={Styles.Description}>{t('workplace_giving.volunteering.multiSessionEvent')}</Typography>
        ) : null}
        {!opportunityEnded && !opportunityCancelled ? (
          <Box display="flex" flexDirection="column" gap={2}>
            {isSeries && noSessions ? (
              <Tooltip content={t('workplace_giving.volunteering.noSessionsAvailable.title')} placement="top">
                <Box>
                  <Button stretch size="large" onPress={scrollToSessions} disabled={noSessions}>
                    {t('workplace_giving.volunteering.findSession')}
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
            {isSeries && !noSessions ? (
              <Button stretch size="large" onPress={scrollToSessions} disabled={noSessions}>
                {t('workplace_giving.volunteering.findSession')}
              </Button>
            ) : null}
            {!hideJoinButton && (
              <Button stretch size="large" onPress={() => handleRegisterAction(RegisterAction.register)}>
                {t('workplace_giving.volunteering.joinOpportunity')}
              </Button>
            )}

            <CopyContentButton
              stretch
              size="large"
              content={window.location.href}
              onPress={() => {
                track({
                  event: 'Volunteering Opportunity Link Sharing',
                  properties: {
                    opportunityType: type
                  }
                })
              }}
            >
              {t('workplace_giving.common.copyShareLink')}
            </CopyContentButton>
            {participation.status === 'participating' && type !== OpportunityType.PROJECT ? (
              <AddToCalendar opportunity={props} />
            ) : null}
          </Box>
        ) : null}
        {!opportunityEnded && !opportunityCancelled && organisation ? (
          <Box sx={Styles.OrganisationInfo}>
            <Avatar
              image={organisation.logo || defaultOrgLogo}
              alt={organisation.displayName || organisation.name}
              size="medium"
              backgroundSizeMode="contain"
            />
            <Box marginLeft={1}>
              <Typography>
                {t('workplace_giving.wizard.supportedNonprofit')}: {organisation.displayName || organisation.name}
              </Typography>
              {organisation?.website ? (
                <ButtonText
                  onPress={() => {
                    window.open(organisation.website!, '_blank', 'noopener noreferrer')
                  }}
                >
                  {createShortLink(organisation.website)}
                </ButtonText>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box display="flex" mr="1rem" justifyContent="space-between">
        <Typography sx={Styles.OrganiserInfo}>{getOpportunityOrganiserTranslation}</Typography>
        {organiser?.id === account?.id && !external?.provider && (
          <ButtonText
            onPress={() => {
              track({
                event: 'Volunteering Opportunity Edit Start',
                properties: {
                  origin: 'Opportunity Details',
                  opportunityType: type
                }
              })
              navigate(AppRoute.EDIT_OPPORTUNITY.replace(':opportunityId', id))
            }}
            data-testid="opportunity-edit-button"
          >
            {t('workplace_giving.common.edit')}
          </ButtonText>
        )}
      </Box>
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.api.modal.joinOpportunity')}
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
      />
    </SidePanelContainer>
  )
}
