import { Children, ReactElement, useMemo } from 'react'
import { Box, Skeleton, Step, StepContent, StepLabel, Stepper } from '@mui/material'

import { StepperIcon } from '../StepperIcon/StepperIcon'

import * as Styles from './ImpactStepper.styles'

interface ImpactStepperProps {
  children: ReactElement[]
  isLoading?: boolean
}

export function ImpactStepper({ children, isLoading }: ImpactStepperProps) {
  const StepIconProps = useMemo(
    () => ({
      icon: <StepperIcon />
    }),
    []
  )

  return (
    <Stepper activeStep={-1} orientation="vertical" sx={Styles.Stepper}>
      {Children.map(children, child => {
        return (
          <Step key={child?.props.key} expanded>
            {isLoading ? (
              <StepLabel
                sx={Styles.StepLabel}
                StepIconComponent={() => <Skeleton variant="circular" width={24} height={24} />}
              />
            ) : (
              <StepLabel sx={Styles.StepLabel} StepIconProps={StepIconProps} data-testid="impact-step-label" />
            )}
            <StepContent sx={Styles.StepContent}>
              <Box sx={Styles.StepOffset}>{child}</Box>
            </StepContent>
          </Step>
        )
      })}
    </Stepper>
  )
}
