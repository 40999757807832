export const redactFromSearch = (param: string, search?: string) => {
  if (!search) return undefined
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get(param)

  if (!token) return search
  searchParams.delete(param)
  searchParams.set(param, 'redacted')
  const queryString = searchParams.toString()

  return decodeURIComponent(queryString)
}

export const redactFromLocationProperties = (param: string) => {
  const redactedSearch = redactFromSearch(param, window.location.search)

  return {
    search: redactedSearch,
    url: `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }${redactedSearch ? `?${redactedSearch}` : ''}${
      window.location.hash || ''
    }`,
  }
}
