import { Box } from '@mui/material'
import React from 'react'

import * as Styles from './Page.styles'

export function Page({ children, testId }: Readonly<{ children: React.ReactNode; testId?: string }>) {
  return (
    <Box data-testid={testId} sx={Styles.PageWrapper}>
      {children}
    </Box>
  )
}
