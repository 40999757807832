import { Box, Card, Skeleton } from '@mui/material'

import * as Styles from '../SessionEvent.styles'

export function SessionEventItemLoader() {
  return (
    <Card sx={Styles.ItemWrapper(false)} data-testid="sessionItemLoader">
      <Skeleton sx={Styles.CalendarWrapper} variant="rectangular" />
      <Box>
        <Skeleton variant="text" width={120} />
        <Skeleton variant="text" width={100} />
      </Box>
    </Card>
  )
}
