import { Box } from '@mui/material'

import { Spacer } from '@percent/lemonade'
import { DescriptionProps } from './Description.types'
import { TitleAndStory } from './TitleAndStory/TitleAndStory'
import { HeroImage } from './HeroImage/HeroImage'
import { Tags } from './Tags/Tags'

export function Description({ image, name, story, tags, location, startDate, itinerary }: Readonly<DescriptionProps>) {
  return (
    <Box data-testid="description-details">
      <HeroImage image={image} />
      <Spacer size={8} axis="vertical" />
      {tags && <Tags highlightFirstTag tags={tags} />}
      <TitleAndStory name={name} story={story} location={location} startDate={startDate} itinerary={itinerary} />
    </Box>
  )
}
