import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { ssoLogin } from '@percent/workplace-giving/api/auth/ssoLogin/ssoLogin'
import {
  AuthAction,
  getLoginType
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'

const defaultResponse = {
  data: {
    data: {}
  },
  status: 200
}

export const RATE_LIMIT_HIT = 'rate-limit-hit'

const interceptUnauthorised = async (dispatch: (_: AuthAction) => void, client: AxiosInstance, email: string) => {
  if (!email) {
    dispatch({
      type: 'LOGOUT'
    })

    return defaultResponse
  }
  try {
    const loginType = getLoginType()

    if (loginType === 'sso') {
      const res = await ssoLogin(client, { email })

      if (res.result === 'error') {
        dispatch({
          type: 'LOGOUT'
        })

        return defaultResponse
      }
      dispatch({
        type: 'LOADING'
      })
      window.location.href = res.data.loginUrl

      return defaultResponse
    }
    dispatch({
      type: 'LOGOUT'
    })

    return defaultResponse
  } catch (e) {
    dispatch({
      type: 'LOGOUT'
    })

    return defaultResponse
  }
}

export const parseRetryAfter = (retryAfter: string | undefined) => {
  return retryAfter ? parseInt(retryAfter, 10) * 1000 : undefined
}

export const responseSuccessInterceptor =
  (
    dispatch: (_: AuthAction) => void,
    client: AxiosInstance,
    email: string,
    setRateLimitHit: (_: { retryAfter?: number | undefined }) => void
  ) =>
  async (response: AxiosResponse) => {
    if (response.status === 401) {
      return interceptUnauthorised(dispatch, client, email)
    }

    if (response.status === 429) {
      setRateLimitHit({
        retryAfter: parseRetryAfter(response.headers['Retry-After'])
      })

      return Promise.reject(new Error(RATE_LIMIT_HIT))
    }

    return response
  }

export const responseFailureInterceptor =
  (
    dispatch: (_: AuthAction) => void,
    client: AxiosInstance,
    email: string,
    setRateLimitHit: (_: { retryAfter?: number | undefined }) => void
  ) =>
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      return interceptUnauthorised(dispatch, client, email)
    }

    if (error.response?.status === 429) {
      setRateLimitHit({
        retryAfter: parseRetryAfter(error.response.headers['Retry-After'])
      })

      return Promise.reject(new Error(RATE_LIMIT_HIT))
    }

    return Promise.reject(error)
  }
