import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { scope } from '../../hoc/withPermissions'
import { usePermission } from '../../hooks/usePermission/usePermission'
import { useMatchRequestForm } from '../../hooks/useMatchRequestForm/useMatchRequestForm'
import { useAnalytics } from '../../hooks/useAnalytics/useAnalytics'
import { GamificationBadges } from '../GamificationBadges/GamificationBadges'

import * as Styles from './SidePanelFooter.styles'
import { ButtonText } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'

export function SidePanelFooter() {
  const { track } = useAnalytics()
  const { dispatch } = useMatchRequestForm()
  const navigation = useNavigate()
  const { t } = useTranslation()
  const has = usePermission()
  const volunteeringEnabled = has(scope('volunteering'))
  const createFundraiserEnabled = has(scope('donation_link'))

  const gamificationEnabled = has(scope('gamification'))

  return (
    <Box sx={Styles.Wrapper}>
      {gamificationEnabled ? (
        <Box mb={2}>
          <Typography sx={Styles.AchievementsTitle}>{t('workplace_giving.sidePanel.achievements')}</Typography>
          <GamificationBadges />
        </Box>
      ) : null}
      <Box>
        <Typography sx={Styles.SectionName}>{t('workplace_giving.navigation.donate')}</Typography>
        <Box sx={Styles.ActionsWrapper}>
          {createFundraiserEnabled ? (
            <ButtonText
              onPress={() => {
                track({
                  event: 'Donation Fundraiser Create Start',
                  properties: {
                    origin: 'Sidebar'
                  }
                })
                navigation(AppRoute.FUNDRAISER_WIZARD)
              }}
              variant="primary"
            >
              {t('workplace_giving.navigation.createFundraiser')}
            </ButtonText>
          ) : null}
          <ButtonText
            onPress={() => {
              dispatch({ type: 'OPEN' })
              track({
                event: 'Donation Match Request Start',
                properties: {
                  origin: 'Sidebar'
                }
              })
            }}
            variant="primary"
          >
            {t('workplace_giving.navigation.submitReceipt')}
          </ButtonText>
        </Box>
      </Box>

      {volunteeringEnabled ? (
        <>
          <Divider sx={Styles.Divider} />
          <Box>
            <Typography sx={Styles.SectionName}>{t('workplace_giving.navigation.volunteer')}</Typography>
            <Box sx={Styles.ActionsWrapper}>
              <ButtonText
                onPress={() => {
                  track({
                    event: 'Volunteering Opportunity Create Start',
                    properties: {
                      origin: 'Sidebar'
                    }
                  })
                  navigation(AppRoute.CREATE_OPPORTUNITY)
                }}
                variant="primary"
              >
                {t('workplace_giving.navigation.createOpportunity')}
              </ButtonText>
              <ButtonText
                onPress={() => {
                  track({
                    event: 'Volunteering Time Logging Start',
                    properties: {
                      origin: 'Sidebar'
                    }
                  })
                  navigation(AppRoute.LOG_VOLUNTEERING_ACTIVITY)
                }}
                variant="primary"
              >
                {t('workplace_giving.navigation.logTime')}
              </ButtonText>
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  )
}
