import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Wrapper: Styles = {
  padding: `${lemonadeTheme.sizes[4]} ${lemonadeTheme.sizes[6]}`
}

export const SectionName: Styles = {
  marginBottom: lemonadeTheme.sizes[1],
  fontSize: lemonadeTheme.sizes[3],
  lineHeight: lemonadeTheme.sizes[6],
  color: customPalette.gray600
}

export const ActionsWrapper: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: lemonadeTheme.sizes[6]
}

export const Divider: Styles = {
  borderColor: customPalette.gray80,
  margin: `${lemonadeTheme.sizes[4]} 0`,
  borderBottomWidth: '2px'
}

export const AchievementsTitle: Styles = {
  marginBottom: lemonadeTheme.sizes[4],
  fontSize: lemonadeTheme.sizes[5],
  lineHeight: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.semiBold
}
