import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'
import { useContext, useMemo } from 'react'

import { splitSdkConfig, Feature, featureNames, features } from '@percent/workplace-giving/providers/SplitSdkProvider'

export function useFeatureFlag() {
  const treatments = useTreatments(Object.keys(splitSdkConfig.features))
  const { isReady, isTimedout } = useContext(SplitContext)

  const featureFlagValue = useMemo(() => {
    const featureFlags = featureNames.reduce((acc, feature) => {
      acc[feature] =
        (isReady && treatments[features[feature].key].treatment === 'on') ||
        (isTimedout && features[feature].defaultValue === 'on')

      return acc
    }, {} as Record<Feature, boolean>)

    return { ...featureFlags, _meta: { isReady } }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isTimedout])

  return featureFlagValue
}
