import { Box, Skeleton } from '@mui/material'

export function SidePanelSkeleton() {
  return (
    <Box data-testid="side-panel-loader">
      <Skeleton variant="rectangular" height={306} style={{ borderRadius: 16 }} />

      <Box marginTop={4} paddingX={3} display="flex" flexDirection="column" gap={3}>
        <Skeleton variant="rectangular" height={24} />

        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" gap={2}>
            <Skeleton variant="rectangular" height={48} width={48} style={{ borderRadius: 8 }} />
            <Skeleton variant="rectangular" height={64} width={248} />
          </Box>
          <Box display="flex" gap={2}>
            <Skeleton variant="rectangular" height={48} width={48} style={{ borderRadius: 8 }} />
            <Skeleton variant="rectangular" height={86} width={248} />
          </Box>
        </Box>
        <Skeleton variant="rectangular" height={32} />
      </Box>
    </Box>
  )
}
