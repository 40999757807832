import { Axios } from 'axios'

import { GsResponse } from '../../goodstack'
import { Opportunity } from '../../search/searchOpportunities/searchOpportunities.types'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CreateEventSeriesSessionParams } from './create-opportunity.types'
import { createEventSeriesSession } from './create-event-series-session'

export const createEventSeriesSessions: MutationFn<
  [CreateEventSeriesSessionParams[]],
  PromiseSettledResult<GsResponse<Opportunity>>[]
> = async (client: Axios, [sessionsParams]) =>
  Promise.allSettled(sessionsParams.map(async session => createEventSeriesSession(client, [session])))
