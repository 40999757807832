import { useTranslation } from 'react-i18next'

import { ActionModal, Modal } from '@percent/lemonade'

type CancelOpportunityModalProps = {
  open: boolean
  handleClose: VoidFunction
  handleCancelEvent: VoidFunction
  isLoading: boolean
}

export function CancelOpportunityModal({
  open,
  handleClose,
  handleCancelEvent,
  isLoading
}: Readonly<CancelOpportunityModalProps>) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={handleClose}>
      <ActionModal
        title={t('workplace_giving.cancelOpportunity.title')}
        primaryButtonText={t('workplace_giving.cancelOpportunity.cancelOpportunity')}
        secondaryButtonText={t('workplace_giving.cancelOpportunity.keepOpportunity')}
        handleClose={handleClose}
        handleSubmit={handleCancelEvent}
        loading={isLoading}
      >
        {t('workplace_giving.cancelOpportunity.description')}
      </ActionModal>
    </Modal>
  )
}
