import { useAuth } from '../useAuth/useAuth'
import { usePermission } from '../usePermission/usePermission'
import { scope } from '../../hoc/withPermissions'
import { useQuery } from '../useQuery/useQuery'

import { getAccountConfigFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { donationMatchingSummary } from '@percent/workplace-giving/api/account/donationMatchingSummary/donationMatchingSummary'

export const useMatchingBudget = () => {
  const { state: authState } = useAuth()
  const accountConfig = getAccountConfigFromAuthState(authState)
  const has = usePermission()

  const currencyCode = accountConfig?.currency
  const enabled = has(scope('donation_matching')) && !!currencyCode

  return useQuery(['donationMatchingSummary', { currencyCode: currencyCode! }], donationMatchingSummary, {
    enabled,
    staleTime: 1000 * 20
  })
}
