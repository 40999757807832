import { AxiosInstance } from 'axios'

import { GetAccountResponse } from '@percent/workplace-giving/api/auth/getAccount/getAccount.types'

export const getAccount = async (client: AxiosInstance) => {
  const { data } = await client.get<GetAccountResponse>('/account', {
    withCredentials: true
  })

  return data.data
}
