import { Box, Skeleton } from '@mui/material'

export function MyOpportunitySkeleton() {
  return (
    <Box width="100%" pt={5} data-testid="my-opportunity-skeleton">
      <Box mt={4} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <Skeleton variant="rectangular" height={32} width={70} />
          <Skeleton variant="rectangular" height={32} width={50} />
        </Box>
        <Skeleton variant="rectangular" height={32} width={200} />
      </Box>
      <Box mt={3} display="flex" flexDirection="column" gap={2}>
        <Skeleton variant="rectangular" height={48} />
        <Skeleton variant="rectangular" height={48} />
        <Skeleton variant="rectangular" height={48} />
        <Skeleton variant="rectangular" height={48} />
        <Skeleton variant="rectangular" height={48} />
        <Skeleton variant="rectangular" height={48} />
      </Box>
    </Box>
  )
}
