import { createContext } from 'react'

type RateLimitContext = {
  rateLimitHit: boolean
  setRateLimitHit: (value: { retryAfter?: number }) => void
}

export const RateLimitContext = createContext<RateLimitContext>({
  rateLimitHit: false,
  setRateLimitHit: () => {}
})
