import { Box, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Link } from 'react-router-dom-v6'

import { SignInCard } from '../SignInCard/SignInCard'
import * as Styles from '../SignInCard/SignInCard.styles'

import { Button, FormField, Spinner, TextInput } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { forgotPassword } from '@percent/workplace-giving/api/auth/forgotPassword/forgotPassword'
import { emailRegex } from '@percent/utility'

export function ForgotPassword() {
  const { t } = useTranslation()

  const { mutateAsync, isLoading, isSuccess, isError } = useMutation({
    mutationFn: forgotPassword
  })

  const { errors, values, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('workplace_giving.validation.requiredField'))
          .matches(emailRegex, t('workplace_giving.login.email.invalidEmailError'))
      }),
    onSubmit: async ({ email }) => {
      await mutateAsync({ email })
    }
  })

  return (
    <SignInCard
      title={
        isSuccess ? t('workplace_giving.forgotPassword.success.title') : t('workplace_giving.forgotPassword.title')
      }
      description={
        isSuccess
          ? t('workplace_giving.forgotPassword.success.description')
          : t('workplace_giving.forgotPassword.description')
      }
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner size="l" />
        </Box>
      ) : isSuccess ? (
        <Box sx={Styles.LinksWrapper({ centered: true })}>
          <Link to={AppRoute.SIGN_IN}>{t('workplace_giving.forgotPassword.nowSignInHere')}</Link>
        </Box>
      ) : (
        <Box component="form" sx={Styles.Form} onSubmit={handleSubmit}>
          <FormField
            label={t('workplace_giving.login.email.label')}
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email}
            data-testid="email"
            necessity="required"
          >
            <TextInput
              name="email"
              value={values.email}
              placeholder={t('workplace_giving.login.email.placeholder')}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>

          {isError ? (
            <Typography variant="body2" sx={Styles.ErrorMessage}>
              {t('workplace_giving.errors.unexpected')}
            </Typography>
          ) : null}

          <Button variant="primary" type="submit">
            {t('workplace_giving.forgotPassword.sendEmail')}
          </Button>

          <Box sx={Styles.LinksWrapper({ centered: true })}>
            <Typography>
              <Trans
                t={t}
                i18nKey="workplace_giving.forgotPassword.lookingForSignIn"
                components={[<Link to={AppRoute.SIGN_IN} />]}
              />
            </Typography>
          </Box>
        </Box>
      )}
    </SignInCard>
  )
}
