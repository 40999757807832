import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Title: Styles = {
  marginBottom: lemonadeTheme.sizes[6],
  fontSize: lemonadeTheme.sizes[6],
  lineHeight: lemonadeTheme.sizes[8],
  fontWeight: lemonadeTheme.fontWeights.semiBold
}

export const SectionTitle: Styles = {
  marginBottom: lemonadeTheme.sizes[4],
  fontSize: lemonadeTheme.sizes[5],
  lineHeight: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.semiBold,

  '&:not(&:first-of-type)': {
    marginTop: lemonadeTheme.sizes[8]
  }
}

export const FlexWrapper: Styles = {
  display: 'flex',
  flexFlow: 'column',
  gap: lemonadeTheme.sizes[4],

  '@media(min-width: 1024px)': {
    flexFlow: 'row'
  }
}

export const Form: Styles = {
  '& > div': {
    marginBottom: lemonadeTheme.sizes[4]
  }
}

export const CurrencyInputWrapper: Styles = {
  position: 'relative',
  width: '100%'
}

export const CurrencyInput = (status: 'danger' | 'default'): Styles => ({
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: '0px',
  padding: '0px',
  margin: '0px 0px 8px',
  border: '0px',
  verticalAlign: 'top',
  width: '100%',

  '& input': {
    boxSizing: 'border-Box',
    padding: `${lemonadeTheme.sizes.xs} ${lemonadeTheme.sizes.s}`,
    margin: '0px',
    lineHeight: '24px',
    outline: 'none',
    fontSize: '14px',

    color: lemonadeTheme.colors.black,

    '::placeholder': {
      color: lemonadeTheme.colors.gray600
    },

    borderRadius: '0.25rem',
    ...(status === 'default'
      ? {
          border: `0.0625rem solid ${lemonadeTheme.colors.gray80}`,

          '&:hover': {
            border: `0.0625rem solid ${lemonadeTheme.colors.black}`,
            outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.gray80}`
          },
          '&:focus, &:active': {
            border: `0.0625rem solid ${lemonadeTheme.colors.black}`
          }
        }
      : status === 'danger'
      ? {
          border: `0.0625rem solid ${lemonadeTheme.colors.alert400}`,

          '&:hover': {
            outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.alert100}`
          },
          '&:focus, &:active': {
            border: `0.0625rem solid ${lemonadeTheme.colors.alert400}`
          }
        }
      : {})
  }
})

export const CurrencyWrapper: Styles = {
  position: 'absolute',
  top: '6px',
  right: '16px',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '16px',
  pointerEvents: 'none',

  '& span': {
    color: '#5A6256'
  },

  '& img': {
    margin: lemonadeTheme.sizes.xs
  }
}

export const OrganisationSearchWrapper = {
  display: 'flex',
  width: '100%',
  gap: '10px',
  '@media (max-width: 460px)': {
    flexDirection: 'column'
  }
}

export const CountrySelectWrapper = {
  width: 200,
  '@media (max-width: 460px)': {
    marginRight: '0px',
    width: '100%'
  }
}
