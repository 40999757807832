import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { TimeLogCard } from '../../../common/components/TimeLogCard/TimeLogCard'

import * as Styles from './VolunteerList.styles'
import { IllustratedMessage } from '@percent/lemonade'
import { ImpactStepper } from '@percent/workplace-giving/common/components/ImpactStepper/ImpactStepper'
import { CursorPagination } from '@percent/workplace-giving/common/components/CursorPagination/CursorPagination'
import { PastDonationCardSkeleton } from '@percent/workplace-giving/common/components/Donation/PastDonationCard/PastDonationCardSkeleton'
import { ListResponse } from '@percent/workplace-giving/api/goodstack'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { VolunteerTimeLogItem } from '@percent/workplace-giving/api/volunteering/getVolunteerList/getVolunteerList.types'

type VolunteerListProps = {
  isLoading: boolean
  error: any
  data?: ListResponse<VolunteerTimeLogItem>
  hasNextPage: boolean
  hasPreviousPage: boolean
  fetchNextPage: VoidFunction
  fetchPreviousPage: VoidFunction
}

export function VolunteerList({
  isLoading,
  error,
  data,
  hasNextPage,
  hasPreviousPage,
  fetchNextPage,
  fetchPreviousPage
}: VolunteerListProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (error) {
    return (
      <Box marginTop="24px" data-testid="volunteer-list-error">
        <IllustratedMessage
          illustration="general-error"
          title={t('workplace_giving.errors.somethingWentWrong')}
          description={t('workplace_giving.errors.tryAgainLater')}
        />
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={Styles.Wrapper} data-testid="volunteer-list-skeleton-loader">
        <ImpactStepper isLoading>{[<PastDonationCardSkeleton isMatchRequestVariant />]}</ImpactStepper>
      </Box>
    )
  }

  if (data?.totalResults === 0) {
    return (
      <Box marginTop="24px" data-testid="volunteer-list-no-entries">
        <IllustratedMessage
          illustration="expired-session"
          title={t('workplace_giving.myImpact.volunteer.noEntries.title')}
          description={t('workplace_giving.myImpact.volunteer.noEntries.description')}
          cta={{
            label: t('workplace_giving.volunteering.activityForm.logTime'),
            onPress: () => navigate(AppRoute.LOG_VOLUNTEERING_ACTIVITY)
          }}
        />
      </Box>
    )
  }

  return (
    <Box sx={Styles.Wrapper} data-testid="volunteer-list">
      <ImpactStepper>
        {(data?.data || []).map(timeLog => (
          <TimeLogCard key={timeLog.id} timeLog={timeLog} />
        ))}
      </ImpactStepper>
      <CursorPagination
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        totalRecords={data?.totalResults}
        nextPage={fetchNextPage}
        previousPage={fetchPreviousPage}
      />
    </Box>
  )
}
