import { useContext } from 'react'

import { ClientContext } from '@percent/workplace-giving/context/client/clientContext/ClientContext'

export const useClient = () => {
  const context = useContext(ClientContext)

  if (context === undefined) {
    throw new Error('ClientContext must be within ClientProvider')
  }

  return context
}
