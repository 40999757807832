import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { InfiniteData } from '@tanstack/react-query'
import { useMemo, useState } from 'react'

import { ButtonText, IllustratedMessage } from '@percent/lemonade'
import { Opportunity } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import * as Styles from './OpportunitySessions.styles'
import { SessionItem } from './SessionItem'
import { RegisterAction } from '@percent/workplace-giving/api/opportunity/registerForOpportunity/registerForOpportunity.types'
import { useAuth, useLogger, useMutation } from '@percent/workplace-giving/common/hooks'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { getTimeZoneFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { registerForOpportunity } from '@percent/workplace-giving/api/opportunity/registerForOpportunity/registerForOpportunity'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'

type OpportunitySessionsProps = {
  sessions: InfiniteData<Opportunity[]>
  isLoading: boolean
  hasNextPage?: boolean
  fetchNextPage?: VoidFunction
  refetchSessions: VoidFunction
}

export function OpportunitySessions({
  sessions,
  isLoading,
  refetchSessions,
  hasNextPage,
  fetchNextPage
}: OpportunitySessionsProps) {
  const { t } = useTranslation()
  const { track } = useAnalytics()
  const { logError } = useLogger()
  const { state } = useAuth()
  const timeZone = getTimeZoneFromAuthState(state) ?? undefined
  const [updatingOpportunity, setUpdatingOpportunity] = useState<string | undefined>()
  const [openErrorModal, setOpenErrorModal] = useState(false)

  const flattenedSessionsData = useMemo(() => sessions.pages.flat(1), [sessions])

  const { mutateAsync } = useMutation({
    mutationFn: registerForOpportunity,
    onSuccess: res => {
      if (okResponse(res)) {
        refetchSessions()
      } else {
        logError(res.error)
        setOpenErrorModal(true)
      }
      setUpdatingOpportunity(undefined)
    },
    onError: e => {
      logError(e)
      setUpdatingOpportunity(undefined)
      setOpenErrorModal(true)
    }
  })

  const handleRegisterAction = async (action: RegisterAction, opportunity: Opportunity) => {
    setUpdatingOpportunity(opportunity.id)
    await mutateAsync({ id: opportunity.id, action, timeZone })
    track({
      event:
        action === RegisterAction.register
          ? 'Volunteering Opportunity Join'
          : 'Volunteering Opportunity Cancel Attendance',
      properties: {
        opportunityType: opportunity.type,
        opportunityLocationType: opportunity.location.type
      }
    })
  }

  return (
    <Box id="sessions-section">
      <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.selectNextSessionToJoin')}</Typography>
      {!flattenedSessionsData.length ? (
        <IllustratedMessage
          illustration="nothing-to-see"
          title={t('workplace_giving.volunteering.noSessionsAvailable.title')}
          description={t('workplace_giving.volunteering.noSessionsAvailable.description')}
        />
      ) : (
        <>
          {flattenedSessionsData.map(sessionEvent => (
            <SessionItem
              key={sessionEvent.id}
              opportunity={sessionEvent}
              handleRegisterAction={handleRegisterAction}
              isLoading={updatingOpportunity === sessionEvent.id}
            />
          ))}
        </>
      )}
      {hasNextPage && fetchNextPage ? (
        <ButtonText isDisabled={isLoading}>{t('workplace_giving.common.seeMore')}</ButtonText>
      ) : null}
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.api.modal.joinOpportunity')}
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
      />
    </Box>
  )
}
