import { Box } from '@mui/material'

import { Icon } from '@percent/lemonade'
import * as Styles from './StepperIcon.styles'

export function StepperIcon() {
  return (
    <Box sx={Styles.IconWrapper}>
      <Icon name="heart" size="s" />
    </Box>
  )
}
