import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const LoaderWrapper: Styles = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexGrow: 1,
  width: '100%',
  height: '100%',
  minWidth: '100%',
  paddingTop: '20px'
}

export const LoaderContentWrapper: Styles = {
  ...LoaderWrapper,
  paddingY: 0,
  paddingX: '24px',
  paddingBottom: '28px'
}

export const LoaderLogoBox: Styles = {
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  marginTop: '32px',
  marginBottom: '32px',

  '@media (min-width: 600px)': {
    marginTop: '36px'
  }
}

export const Logo: Styles = {
  transform: 'none',
  marginRight: '12px',
  borderRadius: '8px'
}

export const LoaderTitleBox: Styles = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start'
}

export const Content: Styles = {
  display: 'flex',
  width: '100%'
}

export const FlexColumn: Styles = {
  ...Content,
  flexFlow: 'column',
  alignItems: 'flex-start',
  marginBottom: '16px'
}

export const FlexRow: Styles = {
  ...Content,
  flexFlow: 'row',
  alignItems: 'flex-start'
}

export const InputSkeleton: Styles = {
  width: '100%',
  height: '56px',
  marginBottom: '8px',
  backgroundColor: `white !important`,
  borderRadius: '8px',
  border: `1px solid ${customPalette.gray20}`
}

export const PresetValuesSkeleton: Styles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',

  '& > span': {
    width: '23%',
    height: '34px',
    borderRadius: '4px'
  }
}
