import { useCallback, useMemo, useReducer } from 'react'

import { MatchRequestFormContext } from '../matchRequestFormContext/MatchRequestFormContext'

import { MatchRequestFormContextControllerProps } from './MatchRequestFormContextController.types'

const DEFAULT_STATE = {
  isOpen: false,
  isSuccess: undefined
}

export type MatchRequestFormState = {
  isOpen: boolean
  isSuccess?: boolean
}

export type MatchRequestFormAction =
  | {
      type: 'OPEN'
    }
  | {
      type: 'SUCCESS'
    }
  | {
      type: 'CLOSE'
    }

function reducer(state: MatchRequestFormState, action: MatchRequestFormAction): MatchRequestFormState {
  switch (action.type) {
    case 'OPEN': {
      return {
        isOpen: true
      }
    }
    case 'SUCCESS': {
      return {
        ...state,
        isSuccess: true
      }
    }
    case 'CLOSE': {
      return {
        isOpen: false,
        isSuccess: undefined
      }
    }
    default: {
      return state
    }
  }
}

export function MatchRequestFormContextController({
  children,
  defaultState = DEFAULT_STATE
}: MatchRequestFormContextControllerProps) {
  const [state, d] = useReducer(reducer, defaultState)

  const dispatch = useCallback((action: MatchRequestFormAction) => {
    d(action)
  }, [])

  const value = useMemo(
    () => ({
      state,
      dispatch
    }),
    [state, dispatch]
  )

  return <MatchRequestFormContext.Provider value={value}>{children}</MatchRequestFormContext.Provider>
}
