import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { Fundraiser } from './getFundraiser.types'

export const getFundraiser: QueryFn<[string, string], Fundraiser> = async (client, [_, id]) => {
  const { data } = await client.get<{ data: Fundraiser }>(`/fundraisers/${id}`, {
    withCredentials: true
  })

  return data.data
}

export const getFundraisers: QueryFn<[string, string[]], Fundraiser[]> = async (client, [_, fundraiserIds]) =>
  Promise.all(fundraiserIds.map(async id => getFundraiser(client, ['getFundraiser', id])))
