import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom-v6'
import '@mantine/core/styles.css'
import '@mantine/tiptap/styles.css'
import '@mantine/dates/styles.css'

import { AppProviders } from '@percent/workplace-giving/providers/AppProviders'
import { AppRoutes } from '@percent/workplace-giving/routing/AppRoutes'
import { useZendeskWidget } from '@percent/workplace-giving/common/hooks/useZendeskWidget/useZendeskWidget'
import { GenericError } from '@percent/workplace-giving/common/components/GenericError/GenericError'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export function UnconnectedApp() {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = t('workplace_giving.head.title')
  }, [])
  useZendeskWidget()

  return (
    <AppProviders>
      <Sentry.ErrorBoundary fallback={<GenericError />}>
        <AppRoutes />
        <ScrollToTop />
      </Sentry.ErrorBoundary>
    </AppProviders>
  )
}

export const App = UnconnectedApp
