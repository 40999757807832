import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const CategoryBox: Styles = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  '& > p': {
    flex: 1,
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    marginRight: lemonadeTheme.sizes[3],
    lineHeight: lemonadeTheme.sizes[6]
  }
}

export const TagsWrapper: Styles = {
  display: 'flex',
  flex: 2,
  gap: lemonadeTheme.sizes[2],
  flexWrap: 'wrap',
  justifyContent: 'flex-end'
}
