import countries from 'i18n-iso-countries'

import i18n from './config'

i18n.languages
  .map(s => s.split('-')[0])
  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  .forEach(lang => countries.registerLocale(require(`i18n-iso-countries/langs/${lang}.json`)))

// eslint-disable-next-line import/no-default-export
export default countries
