import { stepConnectorClasses, stepContentClasses } from '@mui/material'

import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const StepLabel: Styles = {
  width: '24px'
}

export const StepOffset: Styles = {
  marginTop: `calc((28px + ${lemonadeTheme.sizes.m}) * -1)`,
  marginLeft: '11px'
}

export const StepContent: Styles = {
  marginLeft: '11px'
}

export const Stepper: Styles = {
  // this is to fix some offset weirdness going on
  marginTop: lemonadeTheme.sizes.m,
  marginLeft: '-41px',
  [`& .${stepContentClasses.root}`]: {
    borderColor: '#F5F5F6',
    borderWidth: '2px',
    paddingLeft: '17px',
    paddingRight: 0
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#F5F5F6',
    borderWidth: '2px',
    minHeight: lemonadeTheme.sizes.xl,
    marginLeft: '-1px'
  }
}
