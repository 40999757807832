import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const AchievementsBox: Styles = {
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: lemonadeTheme.sizes[3]
}

export const AchievementName: Styles = {
  marginBottom: lemonadeTheme.sizes[1],
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.medium
}

export const AchievementStepsDescription: Styles = {
  fontSize: lemonadeTheme.sizes[3],
  lineHeight: lemonadeTheme.sizes[5],
  color: customPalette.gray300
}

export const StepsList: Styles = {
  margin: 0,
  padding: 0,
  listStyleType: 'none',

  '& > li': {
    position: 'relative',
    marginBottom: lemonadeTheme.sizes[1],
    paddingLeft: lemonadeTheme.sizes[4],
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6],

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: lemonadeTheme.sizes[3],
      transform: 'translateY(-50%)',
      display: 'block',
      width: lemonadeTheme.sizes[2],
      height: lemonadeTheme.sizes[2],
      borderRadius: lemonadeTheme.borderRadii.small
    }
  }
}

export const StepsListItem = (completed?: boolean): Styles => ({
  color: completed ? customPalette.gray300 : lemonadeTheme.colors.black,
  textDecoration: completed ? 'line-through' : 'none',
  textDecorationColor: customPalette.gray300,

  '&::before': {
    backgroundColor: completed ? customPalette.info400 : customPalette.gray90
  }
})
