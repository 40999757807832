import { AxiosRequestConfig } from 'axios'

export const requestTokenInterceptor = (
  config: AxiosRequestConfig,
  partnerPublicKey: string | undefined
): AxiosRequestConfig => {
  if (partnerPublicKey && !config.withCredentials) {
    return {
      ...config,
      headers: {
        Authorization: partnerPublicKey,
        ...config.headers
      }
    }
  }

  return config
}
