import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import { Libraries, useLoadScript } from '@react-google-maps/api'

import { AsyncSelect, Loader } from '@percent/lemonade'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'

type AddressLookupProps = {
  onSelect?: (value: Address) => void
}

export type Address = {
  addressLineOne: string | undefined
  addressLineTwo: string | undefined
  city: string | undefined
  country: string | undefined
  zipCode: string | undefined
  lat?: number
  long?: number
}

const googleToGSCountryNameMap: Record<string, string> = {
  'United States': 'United States of America'
}

export function parseAddressFromComponents(components: google.maps.places.PlaceResult['address_components']): Address {
  const result = {
    addressLineOne: [] as string[],
    addressLineTwo: [] as string[],
    city: undefined as string | undefined,
    country: undefined as string | undefined,
    zipCode: undefined as string | undefined
  }

  if (!components) {
    return {
      ...result,
      addressLineOne: result.addressLineOne.length > 0 ? result.addressLineOne.join(', ').trim() : undefined,
      addressLineTwo: result.addressLineTwo.length > 0 ? result.addressLineTwo.join(', ').trim() : undefined
    }
  }

  components.forEach(component => {
    component.types.forEach(type => {
      if (type === 'subpremise') {
        result.addressLineTwo.push(component.long_name)
      }

      if (type === 'street_number') {
        result.addressLineOne.push(component.long_name)
      }

      if (type === 'route') {
        result.addressLineOne.push(component.long_name)
      }

      if (['neighborhood', 'sublocality', 'sublocality_level_2'].includes(type)) {
        result.addressLineOne.push(component.long_name)
      }

      if (['locality', 'postal_town'].includes(type)) {
        if (!result.city) {
          result.city = component.long_name
        }
      }

      if (type === 'country') {
        result.country = googleToGSCountryNameMap[component.long_name] ?? component.long_name
      }

      if (['postal_code', 'postal_code_prefix'].includes(type)) {
        if (!result.zipCode) {
          result.zipCode = component.long_name
        }
      }
    })
  })

  return {
    ...result,
    addressLineOne: result.addressLineOne.length > 0 ? result.addressLineOne.join(', ').trim() : undefined,
    addressLineTwo: result.addressLineTwo.length > 0 ? result.addressLineTwo.join(', ').trim() : undefined
  }
}

export function PlacesAutocomplete({ onSelect }: AddressLookupProps) {
  const {
    ready,
    suggestions: { data, loading },
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {}
  })

  const handleInput = (query: string) => {
    setValue(query)
  }

  const handleSelect = (val: SelectOption | null) => {
    if (!val?.value) {
      return
    }
    getDetails({ placeId: val.value }).then(d => {
      if (typeof d === 'string') {
        return
      }
      onSelect?.({
        ...parseAddressFromComponents(d.address_components),
        lat: d.geometry?.location?.lat(),
        long: d.geometry?.location?.lng()
      })
    })
  }

  return (
    <AsyncSelect
      options={data.map(a => ({
        label: a.description,
        value: a.place_id
      }))}
      setQuery={handleInput}
      loading={loading}
      onChange={handleSelect}
      disabled={!ready}
      placeholder="Search for a location"
    />
  )
}

const libraries = ['places'] as Libraries
const googleMapsApiKey = process.env.NX_REACT_APP_GMAP_API_KEY!

export function AddressLookup(props: AddressLookupProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
    libraries
  })

  if (!isLoaded) return <Loader />

  return <PlacesAutocomplete {...props} />
}
