import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const LoadMoreContainer: Styles = {
  display: 'flex',
  flexFlow: 'column'
}

export const TitleBox: Styles = {
  display: 'flex',
  flexFlow: 'column',
  flexWrap: 'no-wrap',
  gap: lemonadeTheme.sizes[4],

  '@media (min-width: 768px)': {
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export const Title: Styles = {
  width: '100%',
  fontSize: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.semiBold,
  lineHeight: lemonadeTheme.sizes[8]
}

export const ListWrapper: Styles = {
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  gap: lemonadeTheme.sizes[4],
  marginY: lemonadeTheme.sizes[8],

  '& > div': {
    width: '100%',

    '@media (min-width: 768px)': {
      width: `calc(50% - ${lemonadeTheme.sizes[2]})`
    }
  }
}

export const CardContentWrapper: Styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: lemonadeTheme.sizes[2],
    marginBottom: lemonadeTheme.sizes[2]
  },

  '& p': {
    color: lemonadeTheme.colors.gray800
  }
}

export const ContentFooter: Styles = {
  '& p': {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    fontWeight: lemonadeTheme.fontWeights.medium,
    lineHeight: lemonadeTheme.sizes[6]
  }
}

export const FooterOrgInfo: Styles = {
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'top',
  gap: lemonadeTheme.sizes[8]
}

export const Chip: Styles = {
  color: customPalette.gray600,

  '&.Mui-disabled': {
    opacity: 1,
    fontWeight: 500,
    color: customPalette.black,
    backgroundColor: customPalette.gray70
  }
}
