import { RawResponse, toGsResponse } from '../../goodstack'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { AccountAchievementsResponse } from './achievements.types'

export const getAccountAchievements: QueryFn<[string], AccountAchievementsResponse | undefined> = async client => {
  return client
    .get<RawResponse<AccountAchievementsResponse>>('/account/achievements', {
      validateStatus: code => [200].includes(code)
    })
    .then(r => toGsResponse(r.status, r.data))
    .then(r => (r.result === 'success' ? r.data : undefined))
    .catch(() => undefined)
}
