import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Button: Styles = {
  display: 'inline-flex',
  flexWrap: 'nowrap',
  gap: lemonadeTheme.sizes[1],

  '& > span': {
    fontWeight: lemonadeTheme.fontWeights.medium
  }
}

export const BtnText: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[6],
  borderBottom: `1px solid ${customPalette.gray600}`
}

export const FiltersCounter: Styles = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: lemonadeTheme.sizes[4],
  width: 'auto',
  height: lemonadeTheme.sizes[4],
  paddingX: lemonadeTheme.sizes[1],
  borderRadius: lemonadeTheme.borderRadii.large,
  background: customPalette.primary400,
  color: lemonadeTheme.colors.white,
  fontSize: lemonadeTheme.sizes[3]
}
