import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const PageContainer: Styles = {
  paddingTop: lemonadeTheme.sizes.l,
  paddingBottom: lemonadeTheme.sizes.l,
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  gap: lemonadeTheme.sizes.xxl,
  [queryWidthLessThan('xl')]: {
    width: 'auto'
  }
}

export const SidePanelWrapper: Styles = {
  maxWidth: '360px',
  minWidth: '300px'
}

export const PageTitle: Styles = {
  fontSize: 20,
  gap: '1.5rem',
  [queryWidthLessThan('l')]: {
    display: 'none'
  }
}
