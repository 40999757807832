import { useTranslation } from 'react-i18next'

import { ActionModal, Modal } from '@percent/lemonade'

type CloseFundraiserModalProps = {
  open: boolean
  handleClose: VoidFunction
  handleCloseFundraiser: VoidFunction
  isLoading: boolean
  fundraiserName?: string
}

export function CloseFundraiserModal({
  open,
  handleClose,
  handleCloseFundraiser,
  isLoading,
  fundraiserName
}: Readonly<CloseFundraiserModalProps>) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={handleClose}>
      <ActionModal
        title={t('workplace_giving.myFundraiser.close.title')}
        primaryButtonText={t('workplace_giving.myFundraiser.close.confirm')}
        secondaryButtonText={t('workplace_giving.myFundraiser.close.cancel')}
        handleClose={handleClose}
        handleSubmit={handleCloseFundraiser}
        loading={isLoading}
      >
        {t('workplace_giving.myFundraiser.close.description', { fundraiser: fundraiserName })}
      </ActionModal>
    </Modal>
  )
}
