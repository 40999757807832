import { useTranslation } from 'react-i18next'

import { FundraiserModalProps } from './FundraiserModal.types'
import { AcknowledgeModal, ConfirmationModal, Modal, Text } from '@percent/lemonade'

export function FundraiserModal({ type, handleClose, handleSubmit, openModal }: FundraiserModalProps) {
  const { t } = useTranslation()

  const aknowledgeModalProps = {
    published: {
      result: 'positive' as 'positive' | 'negative',
      title: t('workplace_giving.fundraiser.modal.published.title'),
      description: t('workplace_giving.fundraiser.modal.published.description'),
      buttonText: t('workplace_giving.fundraiser.modal.published.buttonText'),
      handleClose
    },
    edit: {
      result: 'positive' as 'positive' | 'negative',
      title: t('workplace_giving.fundraiser.modal.edit.success.title'),
      description: t('workplace_giving.fundraiser.modal.edit.success.description'),
      buttonText: t('workplace_giving.fundraiser.modal.published.buttonText'),
      handleClose
    },
    error: {
      result: 'negative' as 'positive' | 'negative',
      title: t('workplace_giving.fundraiser.modal.error.title'),
      description: t('workplace_giving.fundraiser.modal.error.description'),
      buttonText: t('workplace_giving.fundraiser.modal.error.buttonText'),
      handleClose
    },
    edit_error: {
      result: 'negative' as 'positive' | 'negative',
      title: t('workplace_giving.fundraiser.modal.edit.error.title'),
      description: t('workplace_giving.fundraiser.modal.edit.error.description'),
      buttonText: t('workplace_giving.fundraiser.modal.error.buttonText'),
      handleClose
    },
    cancel: {
      title: t('workplace_giving.fundraiser.modal.cancel.title'),
      primaryButtonText: t('workplace_giving.fundraiser.modal.cancel.primaryButtonText'),
      secondaryButtonText: t('workplace_giving.fundraiser.modal.cancel.secondaryButtonText'),
      handleClose,
      handleSubmit: handleSubmit || (() => {})
    }
  }

  return (
    <Modal open={openModal}>
      {type === 'cancel' ? (
        <ConfirmationModal {...aknowledgeModalProps.cancel}>
          <Text size="small">{t('workplace_giving.fundraiser.modal.cancel.description')}</Text>
        </ConfirmationModal>
      ) : (
        <AcknowledgeModal {...aknowledgeModalProps[type]} />
      )}
    </Modal>
  )
}
