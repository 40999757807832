import { useMemo } from 'react'
import Axios from 'axios'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ClientContext } from '../clientContext/ClientContext'

import { ClientProviderProps } from './ClientContextController.types'
import { requestTokenInterceptor } from './interceptors/requestInterceptors'
import { config } from '@percent/workplace-giving/config/config'

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

const gsAxios = Axios.create({
  baseURL: config.urls.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

export function ClientContextController({
  children,
  client: clientOverride,
  gsClient: gsClientOverride
}: ClientProviderProps) {
  const queryClient = new QueryClient({})

  const partnerPublicKey = config.keys.publicKey

  const gsClient = gsClientOverride ?? gsAxios

  useMemo(() => {
    gsClient.interceptors.request.use(axiosRequestConfig =>
      requestTokenInterceptor(axiosRequestConfig, partnerPublicKey)
    )
  }, [partnerPublicKey])

  const clients = useMemo(() => {
    return {
      client: clientOverride ?? axios,
      gsClient
    }
  }, [])

  return (
    <ClientContext.Provider value={clients}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ClientContext.Provider>
  )
}
