import { createContext } from 'react'
import axios, { AxiosInstance } from 'axios'

type CientContext = {
  gsClient: AxiosInstance
  client: AxiosInstance
}

export const ClientContext = createContext<CientContext>({
  gsClient: axios,
  client: axios
})
