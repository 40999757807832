import { useAuth } from '../useAuth/useAuth'
import { useQuery } from '../useQuery/useQuery'

import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { donationSummary } from '@percent/workplace-giving/api/account/donationSummary/donationSummary'

export const useDonationSummary = () => {
  const { state: authState } = useAuth()
  const accountConfig = getAccountConfigFromAuthState(authState)
  const partner = getPartnerFromAuthState(authState)

  const currencyCode = accountConfig?.currency ?? partner?.currencyCode

  return useQuery(['donationSummary', { currencyCode: currencyCode! }], donationSummary, {
    enabled: authState.status === 'authorized' && !!currencyCode,
    staleTime: 1000 * 20
  })
}
