import { useContext } from 'react'

import { AuthStateContext } from '@percent/workplace-giving/context/auth/authContext/AuthContext'

export const useAuth = () => {
  const context = useContext(AuthStateContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthContextController')
  }

  return context
}
