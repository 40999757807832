import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import {
  GetAccountBudgetResponse,
  OpportunitiesBudget
} from '@percent/workplace-giving/api/opportunity/getAccountBudget/getAccountBudget.types'

export const getAccountBudget: QueryFn<[string], GetAccountBudgetResponse['data']> = async client => {
  const res = await client.get<{ data: OpportunitiesBudget }>(`/account/volunteering-summary`, {
    withCredentials: true
  })

  return res.data.data
}
