import { Navigate, Outlet } from 'react-router-dom-v6'
import { Box } from '@mui/material'

import { useAuth } from '@percent/workplace-giving/common/hooks/useAuth/useAuth'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { Spinner } from '@percent/lemonade'

export function AllowUnauthenticatedAccountOnlyRoute() {
  const { state } = useAuth()

  if (state.status === 'loading' || state.status === 'first_load') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Spinner size={8} />
      </Box>
    )
  }

  if (state.status === 'unauthorized') {
    return (
      <Box
        sx={{
          '*': {
            fontFamily: 'Inter, sans-serif'
          }
        }}
      >
        <Outlet />
      </Box>
    )
  }

  return <Navigate to={AppRoute.HOME} />
}
