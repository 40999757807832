import { SearchResponse } from '../../goodstack'
import { Opportunity } from '../../search/searchOpportunities/searchOpportunities.types'

import { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

type GetOpportunitiesQueryParams = {
  parentId?: string
  direction?: 'ASC' | 'DESC'
  pageSize?: number
}

export const getOpportunities: InfiniteQueryFn<
  [string, GetOpportunitiesQueryParams],
  string,
  SearchResponse<Opportunity>
> = async (client, [_, { parentId, direction = 'ASC', pageSize = 25 }], cursor) =>
  (
    await (cursor
      ? client.get<SearchResponse<Opportunity>>(cursor, {
          withCredentials: true
        })
      : client.get<SearchResponse<Opportunity>>('/opportunities', {
          params: { parentId, direction, pageSize },
          withCredentials: true
        }))
  ).data
