import { useCallback } from 'react'

import { AuthChecker } from '../../hoc/withPermissions'
import { useAuth } from '../useAuth/useAuth'
import { useFeatureFlag } from '../useFeatureFlag/useFeatureFlag'

export function usePermission() {
  const { state } = useAuth()
  const featureFlags = useFeatureFlag()

  const has = useCallback(
    (...checkers: AuthChecker[]) => {
      return state.status === 'authorized' && checkers.every(checker => checker({ state, featureFlags }))
    },
    [featureFlags, state]
  )

  return has
}
