import { RawResponse, toGsResponse } from '../../goodstack'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { Money } from '@percent/utility'

export type DonationWalletResponse = {
  id: string
  accountId: string
  partnerId: string
  balance: Money
}

export const donationWallet: QueryFn<[string], DonationWalletResponse | undefined> = async client => {
  return client
    .get<RawResponse<DonationWalletResponse>>('/account/donation-wallet', {
      validateStatus: code => [200, 403, 404].includes(code)
    })
    .then(r => toGsResponse(r.status, r.data))
    .then(r => (r.result === 'success' ? r.data : undefined))
}
