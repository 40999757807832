import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme } from '@percent/lemonade'

export const Flex: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.sizes.xs
}

export const Wrapper: Styles = {
  ...Flex,
  justifyContent: 'space-between',
  padding: '16px'
}

export const Results: Styles = {
  minWidth: '70px'
}
