import { useTranslation } from 'react-i18next'

import { FeaturedCharityCard } from '@percent/workplace-giving/common/components/FeaturedCharityCard/FeaturedCharityCard'
import { useAuth, useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import {
  getCountryCodeFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { Carousel } from '@percent/lemonade'
import { getFeatured } from '@percent/workplace-giving/api/featured/featured'
import { Featured } from '@percent/workplace-giving/api/featured/featured.types'
import { FeaturedFundraiserCard } from '@percent/workplace-giving/common/components/FeaturedFundraiserCard/FeaturedFundraiserCard'
import { FeaturedCardSkeleton } from '@percent/domain/giving'
import { FeaturedCardsProps } from './FeaturedCards.types'
import { FeaturedOpportunityCard } from '@percent/workplace-giving/common/components/FeaturedOpportunityCard/FeaturedOpportunityCard'

const getCountry = (f: Featured): string | undefined =>
  f.country ?? (f.kind === 'featured_organisation' ? f.organisation.countryCode : undefined)

export function FeaturedCards({ mode = 'donation' }: Readonly<FeaturedCardsProps>) {
  const { t } = useTranslation()
  const { state } = useAuth()
  const partner = getPartnerFromAuthState(state)!
  const country = getCountryCodeFromAuthState(state)
  const { logError } = useLogger()

  const {
    data: featured,
    isLoading,
    error
  } = useQuery(['getFeatured', { country, mode }], getFeatured, {
    staleTime: 1000 * 60
  })

  if (country && featured) {
    featured.sort((a, b) => {
      if (getCountry(a) === country) return -1

      return getCountry(b) === country ? 1 : 0
    })
  }

  if (error) {
    logError(error)

    return null
  }

  if (!featured && !isLoading) {
    return null
  }

  return (
    <Carousel
      data-testid="featured-cards"
      items={
        isLoading
          ? [<FeaturedCardSkeleton key="skeleton" />]
          : (featured ?? []).map(f => {
              if (f.kind === 'featured_organisation') {
                return (
                  <FeaturedCharityCard
                    key={f.organisationId}
                    partner={partner}
                    featured={f}
                    organisation={f.organisation}
                  />
                )
              }

              if (f.kind === 'featured_fundraiser') {
                return (
                  <FeaturedFundraiserCard
                    key={f.fundraiserId}
                    partner={partner}
                    fundraiser={f.fundraiser}
                    organisation={f.organisation!}
                    description={f.description}
                  />
                )
              }

              if (f.kind === 'featured_opportunity') {
                return (
                  <FeaturedOpportunityCard
                    key={f.opportunity.id}
                    partner={partner}
                    featured={f}
                    opportunity={f.opportunity}
                  />
                )
              }

              return null
            })
      }
      title={
        isLoading
          ? ''
          : mode === 'donation'
          ? t('workplace_giving.featured.title')
          : t('workplace_giving.volunteering.featuredOpportunities')
      }
    />
  )
}
