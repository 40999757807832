import { ListResponse, QueryListFunction } from '../../goodstack'

import { CurrencyCode } from '@percent/utility'
import { Donation, SearchQuery } from './retrieve-donation-list.types'

export const retrieveDonationList: QueryListFunction<
  string,
  SearchQuery & { currencyCode: CurrencyCode; donationIds?: string[]; cursor?: string },
  ListResponse<Donation>
> = async (client, [_, { pageSize = 10, direction = 'DESC', cursor = '', currencyCode, donationIds }]) =>
  client
    .get<ListResponse<Donation>>(`/account/donations`, {
      params: {
        pageSize,
        direction,
        currencyCode,
        ...(cursor && { cursor }),
        ...(donationIds && { donationIds })
      }
    })
    .then(r => r.data)
