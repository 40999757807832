import { scope } from '../../hoc/withPermissions'
import { usePermission } from '../usePermission/usePermission'
import { useQuery } from '../useQuery/useQuery'

import { donationWallet } from '@percent/workplace-giving/api/account/donationWallet/donationWallet'

export const useDonationWallet = () => {
  const has = usePermission()

  const enabled = has(scope('donation_wallet'))

  return useQuery(['donationWallet'], donationWallet, { enabled, staleTime: 1000 * 20 })
}
