import { Axios } from 'axios'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'
import { Fundraiser } from '../getFundraiser/getFundraiser.types'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CreateFundraiserParams } from './createFundraiser.types'

export const createFundraiser: MutationFn<CreateFundraiserParams, GsResponse<Fundraiser>> = async (
  client: Axios,
  params: CreateFundraiserParams
) => {
  const data = new FormData()
  data.append('kind', params.kind)
  data.append('page', JSON.stringify(params.page))
  data.append('organisationId', params.organisationId)
  data.append('campaign', JSON.stringify(params.campaign))
  data.append('image', params.image!)
  data.append('status', params.status)

  const response = await client.post<RawResponse<Fundraiser>>('/fundraisers', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true
  })

  return toGsResponse(response.status, response.data)
}
