import { Styles } from '@percent/workplace-giving/theme'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const SpinnerWrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
}

export const PageContainer =
  (searchElemHeight: number): Styles =>
  theme => ({
    paddingTop: `${searchElemHeight - 14}px`,

    [theme.breakpoints.down('md')]: {
      paddingTop: `${searchElemHeight}px`
    }
  })

export const RecommendedWrapper: Styles = {
  maxWidth: '60%',
  flex: 6,
  [queryWidthLessThan('xl')]: {
    flex: 1,
    maxWidth: '100%'
  }
}

export const SearchResultsWrapper: Styles = theme => ({
  display: 'flex',
  flex: 6,
  [theme.breakpoints.down('md')]: {
    flex: 1,
    flexFlow: 'column'
  }
})
