import { Box, Skeleton } from '@mui/material'

export function DetailsAndDonationListSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={200} />
      <Skeleton variant="rectangular" width="100%" height={1} />
      <Skeleton variant="rectangular" width="50%" height={35} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" width={250} height={35} />
        <Skeleton variant="rectangular" width={70} height={35} />
      </Box>
    </Box>
  )
}
