import styled from 'styled-components'

import { theme as lemonadeTheme } from '@percent/lemonade'
import { customBreakpoints, customPalette } from '@percent/workplace-giving/theme/theme'

export const BannerFallback = styled.div`
  background-color: ${customPalette.gray20};
  border-radius: 3px;
  @media (min-width: ${customBreakpoints.xs}) {
    height: 288px;
  }
`

export const Banner = styled.img`
  width: 100%;
  border-radius: ${lemonadeTheme.borderRadii.large};
  height: auto;
  object-fit: cover;

  @media (min-width: ${customBreakpoints.xs}) {
    height: 288px;
  }
`
