import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect } from 'react'

import {
  OpportunityLocationType,
  ChildSessionEvent
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { useAuth, useLogger } from '@percent/workplace-giving/common/hooks'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { config } from '@percent/workplace-giving/config/config'
import { isValidWebsiteURL } from '@percent/utility'
import { SessionEventForm } from './SessionEventForm'
import { addProtocolToWebsiteUrl } from '@percent/workplace-giving/utils/url/url'

export type SessionEventModalProps = {
  isOpen: boolean
  onClose: VoidFunction
  onSubmit: (newEvent: ChildSessionEvent) => void
  isEditFlow: boolean
}

export function CreateSessionEvent({ isOpen, onClose, onSubmit, isEditFlow }: Readonly<SessionEventModalProps>) {
  const { t } = useTranslation()
  const { state } = useAuth()
  const defaultCountry = getCountryCodeFromAuthState(state)!
  const { logError } = useLogger()

  const handleClose = () => {
    resetForm()
    onClose()
  }

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    validateField,
    resetForm,
    validateForm
  } = useFormik({
    initialValues: {
      startDate: undefined,
      endDate: undefined,
      locationType: '',
      locationUrl: '',
      participantSpots: undefined,
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      country: defaultCountry,
      zipCode: '',
      long: 0,
      lat: 0
    },
    validationSchema: () =>
      Yup.object().shape({
        startDate: Yup.date()
          .nullable()
          .test('minStartDateInTheFuture', t('workplace_giving.validation.dateInTheFuture'), value => {
            if (value) {
              return !dayjs(value).isBefore(dayjs())
            }

            return true
          })
          .required(t('workplace_giving.validation.requiredField')),
        endDate: Yup.date()
          .nullable()
          .test('endDateAfterStartDate', t('workplace_giving.validation.endDateAfterStartDate'), value => {
            if (!values.startDate) {
              return true
            }

            if (value) {
              return dayjs(value).isAfter(dayjs(values.startDate).add(5, 'minutes'), 'milliseconds')
            }

            return true
          })
          .required(t('workplace_giving.validation.requiredField')),
        locationType: Yup.string().required(t('workplace_giving.validation.requiredField')),
        locationUrl: Yup.string().when({
          is: () => values.locationType === OpportunityLocationType.VIRTUAL,
          then: isValidWebsiteURL(t('workplace_giving.validation.url'))
            .min(1, t('workplace_giving.validation.requiredField'))
            .max(2048, t('workplace_giving.validation.maxCharacters', { max: 2048 }))
        }),
        addressLineOne: Yup.string().when({
          is: () => values.locationType === OpportunityLocationType.OFFLINE,
          then: Yup.string()
            .min(1, t('workplace_giving.validation.requiredField'))
            .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))
            .required(t('workplace_giving.validation.requiredField'))
        }),
        addressLineTwo: Yup.string().when({
          is: () => values.locationType === OpportunityLocationType.OFFLINE,
          then: Yup.string()
            .min(1, t('workplace_giving.validation.requiredField'))
            .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))
        }),
        city: Yup.string().when({
          is: () => values.locationType === OpportunityLocationType.OFFLINE,
          then: Yup.string()
            .min(1, t('workplace_giving.validation.requiredField'))
            .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))
            .required(t('workplace_giving.validation.requiredField'))
        }),
        zipCode: Yup.string().when({
          is: () => values.locationType === OpportunityLocationType.OFFLINE,
          then: Yup.string()
            .min(1, t('workplace_giving.validation.requiredField'))
            .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))
            .required(t('workplace_giving.validation.requiredField'))
        }),
        country: Yup.string().when({
          is: () => values.locationType === OpportunityLocationType.OFFLINE,
          then: Yup.string()
            .min(1, t('workplace_giving.validation.requiredField'))
            .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))
            .required(t('workplace_giving.validation.requiredField'))
        }),
        participantSpots: Yup.number()
          .positive()
          .max(
            config.maxParticipantSpots,
            t('workplace_giving.validation.maxAmount', { max: config.maxParticipantSpots })
          )
          .nullable()
      }),
    onSubmit: async data => {
      const {
        locationType,
        locationUrl,
        startDate,
        endDate,
        participantSpots,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        zipCode,
        long,
        lat
      } = data

      try {
        onSubmit({
          id: _.uniqueId(),
          startDate: startDate as unknown as Date,
          endDate: endDate as unknown as Date,
          participantSpots,
          location:
            locationType === OpportunityLocationType.VIRTUAL
              ? {
                  type: OpportunityLocationType.VIRTUAL,
                  link: locationUrl.length ? addProtocolToWebsiteUrl(locationUrl) : null
                }
              : {
                  type: OpportunityLocationType.OFFLINE,
                  addressLineOne,
                  addressLineTwo,
                  city,
                  country,
                  zipCode,
                  long,
                  lat
                }
        })
        handleClose()
      } catch (e) {
        logError(e)
      }
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  useEffect(() => {
    validateForm()
  }, [validateForm, values.locationType])

  return (
    <SessionEventForm
      isEditFlow={isEditFlow}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      isLoading={isSubmitting}
      isValid={isValid}
      values={values}
      touched={touched}
      errors={errors}
      handleChange={handleChange}
      handleBlur={handleBlur}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      validateField={validateField}
    />
  )
}
