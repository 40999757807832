import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const FundraiserMobilePage: Styles = {
  display: 'flex',
  gap: lemonadeTheme.sizes[8],
  flexFlow: 'column'
}

export const Divider: Styles = {
  width: '100%',
  border: '1px solid #E9E8EB'
}

export const Wrapper: Styles = {
  padding: lemonadeTheme.sizes.m,
  borderRadius: '8px',
  boxShadow: '0px 2px 16px 0px #0D0D0D14',
  display: 'flex',
  flexDirection: 'column',
  gap: lemonadeTheme.sizes.s
}
