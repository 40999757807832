import { Axios } from 'axios'

import { Opportunity } from '../../search/searchOpportunities/searchOpportunities.types'
import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { EditOpportunityLocationParams } from './edit-opportunity.types'

export const editOpportunityLocation: MutationFn<[EditOpportunityLocationParams], GsResponse<Opportunity>> = async (
  client: Axios,
  [params]
) => {
  const response = await client.put<RawResponse<Opportunity>>(`/opportunities/${params.id}/location`, params.location, {
    withCredentials: true,
    validateStatus: (_: number) => true
  })

  return toGsResponse(response.status, response.data)
}
