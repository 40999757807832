import { useContext } from 'react'

import { MatchRequestFormContext } from '@percent/workplace-giving/context/matchRequestForm/matchRequestFormContext/MatchRequestFormContext'

export const useMatchRequestForm = () => {
  const context = useContext(MatchRequestFormContext)

  if (context === undefined) {
    throw new Error('useMatchRequestForm must be used within an MatchRequestFormContextController')
  }

  return context
}
