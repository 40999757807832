import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import * as Styles from './OffPlatformMatchRequestsList.styles'
import { IllustratedMessage } from '@percent/lemonade'
import { ImpactStepper } from '@percent/workplace-giving/common/components/ImpactStepper/ImpactStepper'
import { CursorPagination } from '@percent/workplace-giving/common/components/CursorPagination/CursorPagination'
import { MatchRequestCard } from '@percent/workplace-giving/common/components/Donation/MatchRequestCard/MatchRequestCard'
import { PastDonationCardSkeleton } from '@percent/workplace-giving/common/components/Donation/PastDonationCard/PastDonationCardSkeleton'
import { DonationMatchRequest } from '@percent/workplace-giving/api/donation-match-request/get-list/retrieve-request-list.types'
import { ListResponse } from '@percent/workplace-giving/api/goodstack'

type OffPlatformMatchRequestsListProps = {
  isLoading: boolean
  error: any
  data?: ListResponse<DonationMatchRequest>
  hasNextPage: boolean
  hasPreviousPage: boolean
  fetchNextPage: VoidFunction
  fetchPreviousPage: VoidFunction
}

export function OffPlatformMatchRequestsList({
  isLoading,
  error,
  data,
  hasNextPage,
  hasPreviousPage,
  fetchNextPage,
  fetchPreviousPage
}: OffPlatformMatchRequestsListProps) {
  const { t } = useTranslation()

  if (error) {
    return (
      <Box marginTop="24px" data-testid="off-platform-match-requests-list-error">
        <IllustratedMessage
          illustration="general-error"
          title={t('workplace_giving.errors.somethingWentWrong')}
          description={t('workplace_giving.errors.tryAgainLater')}
        />
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={Styles.Wrapper} data-testid="off-platform-request-list-skeleton-loader">
        <ImpactStepper isLoading>{[<PastDonationCardSkeleton isMatchRequestVariant />]}</ImpactStepper>
      </Box>
    )
  }

  if (data?.totalResults === 0) {
    return (
      <Box marginTop="24px" data-testid="off-platform-match-requests-list-no-donations">
        <IllustratedMessage
          illustration="no-payouts"
          title={t('workplace_giving.sidepanel.offplatform.heading')}
          description={t('workplace_giving.sidepanel.offplatform.submit.heading')}
        />
      </Box>
    )
  }

  return (
    <Box sx={Styles.Wrapper} data-testid="off-platform-request-list">
      <ImpactStepper>
        {(data?.data || []).map(request => (
          <MatchRequestCard key={request.id} request={request as DonationMatchRequest} />
        ))}
      </ImpactStepper>
      <CursorPagination
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        totalRecords={data?.totalResults}
        nextPage={fetchNextPage}
        previousPage={fetchPreviousPage}
      />
    </Box>
  )
}
