import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { GetVolunteeringActivitiesResponse, VolunteeringActivity } from './getVolunteeringActivities.types'

export const getVolunteeringActivities: QueryFn<[string], GetVolunteeringActivitiesResponse['data']> = async client => {
  const res = await client.get<{ data: VolunteeringActivity[] }>(`/volunteering-activities`, {
    withCredentials: true
  })

  return res.data.data
}
