import { useEffect } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom-v6'

import { setLoginType } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'

export function SSOGateway() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const deeplink = searchParams.get('deeplink')
    setLoginType('sso')

    if (deeplink) {
      navigate(deeplink)
    }
  }, [searchParams])

  return <Navigate to={AppRoute.HOME} />
}
