export const getQueryParamsObjWithoutToken = (urlParams: URLSearchParams) => {
  const entries = urlParams.entries()
  const searchParamsObj: Record<string, string> = {}
  for (const [key, value] of entries) {
    if (key !== 'token') {
      searchParamsObj[key] = value
    }
  }

  return searchParamsObj
}
