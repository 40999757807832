import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom-v6'
import * as Sentry from '@sentry/react'

import { Home } from '../app/Home/Home'
import { SSOSignIn } from '../app/Auth/SSOSignIn/SSOSignIn'
import { EmailSignIn } from '../app/Auth/EmailSignIn/EmailSignIn'
import { SSOGateway } from '../app/Auth/SSOGateway/SSOGateway'
import { ForgotPassword } from '../app/Auth/ForgotPassword/ForgotPassword'
import { SignWithTwoFA } from '../app/Auth/TwoFactorAuthentication/SignInWithTwoFA/SignWithTwoFA'
import { getPageTrackingName } from '../utils/getPageTrackingName/getPageTrackingName'
import { getAccountFromAuthState } from '../context/auth/authContextController/AuthContextController'
import { getQueryParamsObjWithoutToken } from '../utils/getQueryParamsObjWithoutToken'
import { useAnalytics } from '../common/hooks/useAnalytics/useAnalytics'
import { Fundraiser } from '../app/Fundraiser/Fundraiser'
import { CreateOpportunity } from '../app/Volunteer/OpportunityForm/CreateOpportunity'
import { EditOpportunity } from '../app/Volunteer/OpportunityForm/Edit/EditOpportunity'
import { MyFundraiser } from '../app/Fundraiser/MyFundraiser/MyFundraiser'
import { MyProfile } from '../app/MyProfile/MyProfile'
import { FundraiserWizard } from '../app/Fundraiser/FundraiserWizard/FundraiserWizard'
import { EditFundraiserWizard } from '../app/Fundraiser/FundraiserWizard/Edit/EditFundraiserWizard'
import { LogVolunteeringActivity } from '../app/Volunteer/LogVolunteeringActivity/LogVolunteeringActivity'
import { scope, withAuth } from '../common/hoc/withPermissions'

import { AllowAuthenticatedAccountOnlyRoute } from './AllowAuthenticatedAccountOnlyRoute'
import { AppRoute } from './AppRoute.enum'
import { AllowUnauthenticatedAccountOnlyRoute } from './AllowUnauthenticatedAccountOnlyRoute'
import { MyImpact } from '@percent/workplace-giving/app/MyImpact/MyImpact'
import { Volunteer } from '@percent/workplace-giving/app/Volunteer/Volunteer'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { OpportunityDetails } from '@percent/workplace-giving/app/Volunteer/OpportunityDetails/OpportunityDetails'
import { MyOpportunity } from '@percent/workplace-giving/app/MyOpportunity/MyOpportunity'
import { HandleNotFoundRedirection } from './HandleNotFoundRedirection'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function NavigateHome() {
  return <Navigate to={AppRoute.HOME} />
}
const AllowFundraising = withAuth(scope('donation_link'))(Outlet, NavigateHome)
const AllowVolunteering = withAuth(scope('volunteering'))(Outlet, NavigateHome)
const AllowCreateFundraising = withAuth(scope('donation_link'))(Outlet, NavigateHome)

export function AppRoutes() {
  const { state } = useAuth()
  const user = getAccountFromAuthState(state)
  const { page } = useAnalytics()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const authorizedPageName = getPageTrackingName(pathname)

    if (user?.id && authorizedPageName) {
      page({
        name: authorizedPageName,
        category: 'WPG',
        properties: {
          ...getQueryParamsObjWithoutToken(searchParams)
        }
      })
    }
  }, [pathname, searchParams, page, user?.id])

  return (
    <SentryRoutes>
      <Route element={<AllowUnauthenticatedAccountOnlyRoute />}>
        <Route path={AppRoute.SIGN_IN} element={<EmailSignIn />} />
        <Route path={AppRoute.OTP_VERIFY} element={<SignWithTwoFA />} />
        <Route path={AppRoute.SSO_SIGN_IN} element={<SSOSignIn />} />
        <Route path={AppRoute.FORGOT_PASSWORD} element={<ForgotPassword />} />
      </Route>

      <Route element={<AllowAuthenticatedAccountOnlyRoute />}>
        <Route path={AppRoute.SSO_GATEWAY} element={<SSOGateway />} />
      </Route>

      <Route element={<AllowAuthenticatedAccountOnlyRoute />}>
        <Route path={AppRoute.HOME} element={<Home />} />
        <Route path={AppRoute.MY_IMPACT} element={<MyImpact />} />

        <Route element={<AllowFundraising />}>
          <Route path={AppRoute.FUNDRAISER} element={<Fundraiser />} />
          <Route element={<AllowCreateFundraising />}>
            <Route
              path={AppRoute.MY_FUNDRAISER}
              element={
                <MyProfile>
                  <MyFundraiser />
                </MyProfile>
              }
            />
          </Route>
        </Route>

        <Route element={<AllowVolunteering />}>
          <Route path={AppRoute.VOLUNTEER} element={<Volunteer />} />
          <Route path={AppRoute.OPPORTUNITY_DETAILS} element={<OpportunityDetails />} />
          <Route
            path={AppRoute.MY_OPPORTUNITY}
            element={
              <MyProfile>
                <MyOpportunity />
              </MyProfile>
            }
          />
        </Route>
      </Route>

      {/* without layout TODO: Change this to something more elegant */}
      <Route element={<AllowAuthenticatedAccountOnlyRoute withoutLayout />}>
        <Route element={<AllowVolunteering />}>
          <Route key="create-opportunity" path={AppRoute.CREATE_OPPORTUNITY} element={<CreateOpportunity />} />
          <Route key="edit-opportunity" path={AppRoute.EDIT_OPPORTUNITY} element={<EditOpportunity />} />
          <Route path={AppRoute.LOG_VOLUNTEERING_ACTIVITY} element={<LogVolunteeringActivity />} />
        </Route>

        <Route element={<AllowCreateFundraising />}>
          <Route path={AppRoute.FUNDRAISER_WIZARD} element={<FundraiserWizard />} />
          <Route key="edit-fundraiser" path={AppRoute.EDIT_FUNDRAISER} element={<EditFundraiserWizard />} />
        </Route>
      </Route>

      <Route path="*" element={<HandleNotFoundRedirection />} />
    </SentryRoutes>
  )
}
