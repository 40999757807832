import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { CurrencyCode, Money } from '@percent/utility'

type DonationMatchingSummaryParams = {
  currencyCode: CurrencyCode
}

export type DonationMatchingSummaryResponse = {
  remainingAmount?: Money
  usedAmount?: Money
  pendingAmount?: Money
  resetDate?: Date
}

export const donationMatchingSummary: QueryFn<
  [string, DonationMatchingSummaryParams],
  DonationMatchingSummaryResponse
> = async (client, [_, params]) => {
  const response = await client.get<{ data: DonationMatchingSummaryResponse }>('/account/donation-matching-summary', {
    params
  })

  return response.data.data
}
