import { useState } from 'react'

import { DynamicCurrencyInputProps } from './dynamic-currency-input.types'

import { CurrencySelector } from './components/currency-selector'
import { CurrencyCode } from '@percent/utility'
import {
  CurrencyFlagBox,
  CurrencyInputWrapper,
} from './components/currency-components'
import { CurrencyField } from './components/currency-field'

export const DynamicCurrencyInput = ({
  defaultCurrency,
  currencies,
  currencySearchPlaceholder,
  ...props
}: DynamicCurrencyInputProps) => {
  const [currency, setCurrency] = useState<CurrencyCode>(defaultCurrency)

  return (
    <CurrencyInputWrapper>
      <CurrencyField currency={currency} {...props} />
      <CurrencyFlagBox>
        <CurrencySelector
          disabled={props.disabled}
          defaultCurrency={currency}
          onValueChange={(v) => v && setCurrency(v)}
          placeholder={currencySearchPlaceholder}
          currencies={currencies}
        />
      </CurrencyFlagBox>
    </CurrencyInputWrapper>
  )
}
