export const getInitials = (fullName: string | null) => {
  if (!fullName) {
    return ''
  }

  const trimmedFullName = fullName.trim()
  const firstInitial = trimmedFullName.charAt(0).toUpperCase()
  const lastInitial =
    trimmedFullName.split(' ').length > 1
      ? trimmedFullName.charAt(trimmedFullName.lastIndexOf(' ') + 1).toUpperCase()
      : ''

  return `${firstInitial}${lastInitial}`
}
