import { ListResponse } from '../../goodstack'

import { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { Organisation, SearchOrganisationQuery } from './searchOrganisations.types'
import { config } from '@percent/workplace-giving/config/config'

export const searchOrganisations: InfiniteQueryFn<
  [string, SearchOrganisationQuery],
  string,
  ListResponse<Organisation>
> = async (client, [_, { query = '', pageSize = 25, countryCode }], cursor) =>
  (
    await (cursor
      ? client.get<ListResponse<Organisation>>(cursor, {
          headers: {
            Authorization: config.keys.publicKey!
          },
          withCredentials: false
        })
      : client.get<ListResponse<Organisation>>(`/organisations`, {
          params: { query, pageSize, ...(countryCode && { countryCode }) },
          headers: {
            Authorization: config.keys.publicKey!
          },
          withCredentials: false
        }))
  ).data
