import { Axios } from 'axios'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { LogVolunteeringActivityParams } from './logVolunteeringActivity.types'

export const logVolunteeringActivity: MutationFn<[LogVolunteeringActivityParams], GsResponse<any>> = async (
  client: Axios,
  [params]
) => {
  const response = await client.post<RawResponse<any>>(
    '/account/volunteering-time-logs',
    {
      ...params,
      ...(params.platform === 'off-platform' &&
        params.activities && {
          activities: JSON.stringify(params.activities)
        })
    },
    {
      withCredentials: true,
      validateStatus: (_: number) => true
    }
  )

  return toGsResponse(response.status, response.data)
}
