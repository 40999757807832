import { createTheme } from '@mui/material'

export const customPalette = {
  primary100: '#F4EDFF',
  primary400: '#8B4AFF',
  primary600: '#412276',
  secondary100: '#e9fbf9',
  secondary400: '#23d3c1',
  secondary600: '#23c9b8',
  neutral100: '#e5effc',
  neutral300: '#6D6A73',
  neutral400: '#1b034f',
  neutral500: '#56525c',
  neutral600: '#110231',
  info30: '#FBF8FF',
  info100: '#F4EDFF',
  info400: '#8B4AFF',
  warning30: '#FEFBF8',
  warning100: '#FDE3CC',
  warning400: '#E77309',
  success30: '#F7FBFA',
  success100: '#DFF6EC',
  success400: '#008850',
  alert30: '#FEF8F8',
  alert100: '#FCE2E3',
  alert400: '#E71A22',
  white: '#ffffff',
  gray20: '#E1E2E3',
  gray70: '#F5F5F6',
  gray80: '#F1F0F2',
  gray90: '#E9E8EB',
  gray100: '#B6B4B9',
  gray200: '#070C18',
  gray300: '#6D6A73',
  gray400: '#615E67',
  gray500: '#56525C',
  gray600: '#494550',
  gray700: '#3D3944',
  gray800: '#302C38',
  gray900: '#25202D',
  black: '#0D0D0D',
  primary: '#8B3DFF',
  primaryHover: '#412276',
  primaryActive: '#412276',
  primaryFocus: '#412276'
}

const palette = {
  primary: { main: customPalette.primary400 },
  secondary: { main: customPalette.black },
  text: {
    primary: customPalette.gray200,
    secondary: customPalette.gray200
  },
  clientColor: customPalette.primary, // will change and be set based on partner config
  detectedTextColor: customPalette.white
}

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Inter, sans-serif',
    h2: {
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '32px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter, sans-serif';
        }
      `
    }
  }
})

export const customBreakpoints = {
  xs: '425px',
  sm: '768px',
  md: '1024px',
  lg: '1200px',
  xl: '1440px'
}
