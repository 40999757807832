import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'
import { Alpha3Code } from 'i18n-iso-countries'

import { FeaturedFundraiserCardProps } from './FeaturedFundraiserCard.types'
import { FeaturedCard } from '@percent/domain/giving'
import { TooltipTaxInformation } from '@percent/workplace-giving/common/components/TooltipTaxInformation/TooltipTaxInformation'

export function FeaturedFundraiserCard({
  partner,
  fundraiser,
  organisation,
  description
}: FeaturedFundraiserCardProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <FeaturedCard
      type="featured_fundraiser"
      badgeText={t('workplace_giving.featured.chosenBy', {
        partnerName: partner.name
      })}
      title={fundraiser.page.title}
      description={description || fundraiser.page.story}
      descriptionText={t('workplace_giving.featured.donationsWillGoTo')}
      actionButton={() => navigate(`/fundraisers/${fundraiser.id}`)}
      buttonText={t('workplace_giving.common.donate')}
      organisationName={organisation?.displayName || organisation?.name}
      countryCode={organisation?.countryCode}
      imgSrc={fundraiser.page.imageUrl}
      cardButtonInfo={<TooltipTaxInformation orgCountryCode={organisation.countryCode as Alpha3Code} />}
    />
  )
}
