/* eslint-disable no-underscore-dangle */
import { Box, Link } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'
import { useState } from 'react'

import { CursorPagination } from '../../../common/components/CursorPagination/CursorPagination'
import { PastDonationCard } from '../../../common/components/Donation/PastDonationCard/PastDonationCard'

import * as Styles from './PastDonationList.styles'
import { retrieveDonationList } from '@percent/workplace-giving/api/donation/retrieve-donations/retrieve-donation-list'
import { ImpactStepper } from '@percent/workplace-giving/common/components/ImpactStepper/ImpactStepper'
import { useQueryList } from '@percent/workplace-giving/common/hooks/useQueryList/useQueryList'
import { IllustratedMessage, Text } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { PastDonationCardSkeleton } from '@percent/workplace-giving/common/components/Donation/PastDonationCard/PastDonationCardSkeleton'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'

export function PastDonationList() {
  const { t } = useTranslation()
  const { state: authState } = useAuth()
  const accountConfig = getAccountConfigFromAuthState(authState)
  const partner = getPartnerFromAuthState(authState)!
  const currencyCode = accountConfig?.currency ?? partner.currencyCode

  const [params, setParams] = useState({ pageSize: 10, currencyCode })

  const { data, hasNextPage, hasPreviousPage, fetchNextPage, fetchPreviousPage, isLoading, error } = useQueryList(
    'retrieveDonation',
    params,
    retrieveDonationList,
    setParams
  )

  const navigate = useNavigate()

  if (error) {
    return (
      <Box marginTop="24px" data-testid="past-donation-list-error">
        <IllustratedMessage
          illustration="general-error"
          title={t('workplace_giving.errors.somethingWentWrong')}
          description={t('workplace_giving.errors.tryAgainLater')}
        />
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={Styles.Wrapper} data-testid="past-donation-list-skeleton-loader">
        <ImpactStepper isLoading>{[<PastDonationCardSkeleton key="skeleton" />]}</ImpactStepper>
      </Box>
    )
  }

  if (data?.totalResults === 0) {
    return (
      <Box marginTop="24px" data-testid="past-donation-list-no-donations">
        <IllustratedMessage
          illustration="no-donations"
          title={t('workplace_giving.totalDonated.noDonations.title')}
          description={t('workplace_giving.totalDonated.noDonations.motivation')}
          cta={{
            label: t('workplace_giving.myImpact.noDonations.searchForNonprofit'),
            onPress: () => {
              navigate(AppRoute.HOME)
            }
          }}
        />
      </Box>
    )
  }

  return (
    <>
      <Text size="small">
        <Trans
          i18nKey="workplace_giving.myImpact.helpCenterInfo"
          components={[
            <Link sx={Styles.Link} href="https://percentgiving.zendesk.com/hc/en-us" target="_blank" rel="noreferrer" />
          ]}
        />
      </Text>
      <Box sx={Styles.Wrapper} data-testid="past-donation-list">
        <ImpactStepper>
          {(data?.data || []).map(donation => (
            <PastDonationCard key={donation.id} donation={donation} />
          ))}
        </ImpactStepper>
        <CursorPagination
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          totalRecords={data?.totalResults}
          nextPage={fetchNextPage}
          previousPage={fetchPreviousPage}
        />
      </Box>
    </>
  )
}
