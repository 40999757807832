import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const ModalContentWrapper: Styles = {
  outline: 'none !important'
}

export const Title: Styles = {
  marginBottom: lemonadeTheme.sizes[6],
  fontSize: lemonadeTheme.sizes[6],
  lineHeight: lemonadeTheme.sizes[8],
  fontWeight: lemonadeTheme.fontWeights.semiBold
}

export const SectionTitle: Styles = {
  marginBottom: lemonadeTheme.sizes[4],
  fontSize: lemonadeTheme.sizes[5],
  lineHeight: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.semiBold,

  '&:not(&:first-of-type)': {
    marginTop: lemonadeTheme.sizes[8]
  }
}

export const FlexWrapper: Styles = {
  display: 'flex',
  flexFlow: 'column',
  gap: lemonadeTheme.sizes[4],

  '@media(min-width: 1024px)': {
    flexFlow: 'row'
  }
}

export const FormBasicStyles = {
  '*': {
    fontFamily: 'Inter, sans-serif !important'
  },

  '.mantine-MultiSelect-inputField': {
    '&::placeholder': {
      color: `${customPalette.gray300} !important`
    },

    '&[disabled]': {
      cursor: 'not-allowed'
    }
  },

  '.mantine-MultiSelect-section': {
    right: lemonadeTheme.sizes[2],
    width: 'auto !important'
  },

  '.mantine-MultiSelect-pill': {
    height: 'auto',
    margin: '-2px 0',
    backgroundColor: `${customPalette.gray80} !important`
  },

  '.mantine-Pill-label': {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    padding: `2px 0`
  },

  '.mantine-Input-input': {
    transition: 'none',
    borderColor: lemonadeTheme.colors.gray80,
    color: `${lemonadeTheme.colors.black} !important`,
    paddingY: '8px',
    minHeight: '40px',

    '&::placeholder': {
      color: `${customPalette.gray300} !important`
    },

    '&:hover': {
      outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.gray80}`,
      borderColor: lemonadeTheme.colors.black
    },

    '&:focus': {
      borderColor: `${lemonadeTheme.colors.black} !important`
    },

    '&[data-error="true"]': {
      borderColor: lemonadeTheme.colors.alert400
    },

    '&[data-error="true"]:hover': {
      outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.alert100}`,
      borderColor: lemonadeTheme.colors.alert400
    },

    '&:active': {
      borderColor: lemonadeTheme.colors.black
    },

    '&[data-disabled="true"]': {
      outline: 'none',
      borderColor: customPalette.gray80,
      opacity: 1,
      backgroundColor: customPalette.gray80
    }
  }
}

export const Form: Styles = {
  ...FormBasicStyles,
  '& > div': {
    marginBottom: lemonadeTheme.sizes[4]
  }
}

export const AddressLinesWrapper: Styles = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  gap: lemonadeTheme.sizes[4],

  '@media(min-width: 768px)': {
    flexFlow: 'row'
  }
}

export const TimeTrackingLabel: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall
}

export const TimeTrackingDescription: Styles = {
  color: lemonadeTheme.colors.gray600,
  fontSize: lemonadeTheme.fontSizes.bodySmall
}

export const OptionsCardsBox: Styles = {
  display: 'flex',
  flexFlow: 'column',
  gap: lemonadeTheme.sizes[4],
  justifyContent: 'space-between',

  '@media(min-width: 1024px)': {
    flexFlow: 'row'
  },

  '& > div': {
    margin: '0 !important',
    flex: 1
  }
}
