import { SearchResponse } from '../../goodstack'

import { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { SearchVerifiedOrganisationQuery, VerifiedOrganisationSearchResult } from './searchVerifiedOrganisations.types'

export const searchVerifiedOrganisations: InfiniteQueryFn<
  [string, SearchVerifiedOrganisationQuery],
  string,
  SearchResponse<VerifiedOrganisationSearchResult>
> = async (client, [_, { query = '', pageSize = 25, countryCode }], cursor) =>
  (
    await (cursor
      ? client.get<SearchResponse<VerifiedOrganisationSearchResult>>(cursor, {
          withCredentials: true
        })
      : client.get<SearchResponse<VerifiedOrganisationSearchResult>>(`/workplace/organisations`, {
          params: { query, pageSize, ...(countryCode && { countryCode }) },
          withCredentials: true
        }))
  ).data
