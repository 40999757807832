import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IllustratedMessage } from '@percent/lemonade'
import * as Styles from './GenericError.styles'

type GenericErrorProps = {
  title?: string
  description?: string
}

export function GenericError({ title, description }: GenericErrorProps) {
  const { t } = useTranslation()

  return (
    <Box sx={Styles.ErrorContainer} data-testid="generic-error-component">
      <Box sx={Styles.ErrorWrapper}>
        <IllustratedMessage
          illustration="general-error"
          title={title ?? t('workplace_giving.errors.genericError.title')}
          description={description ?? t('workplace_giving.errors.genericError.description')}
        />
      </Box>
    </Box>
  )
}
