import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { ResendReceiptProps } from './resend-receipt.types'

export const resendReceipt: MutationFn<ResendReceiptProps, void> = async (client, { donationId }) => {
  await client.post<void>(
    `/donations/${donationId}/resend-receipt`,
    {},
    {
      withCredentials: true
    }
  )
}
