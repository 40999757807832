import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import * as Styles from '../FundraiserWizard.styles'
import { FundraiserWizardFormProps } from '../FundraisrWizardForm.types'

import { Card, FileInput, FormField, TextInput } from '@percent/lemonade'
import { RichTextEditor } from '@percent/workplace-giving/common/components'
import { Money } from '@percent/utility'
import { DynamicCurrencyInput, OrganisationHeaderWithImage } from '@percent/domain/giving'
import { config } from '@percent/workplace-giving/config/config'

export function EditFundraiserWizardForm({
  handleCoverImageChange,
  coverImageError,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  currency,
  currencies,
  coverImage,
  fundraiserData
}: Readonly<FundraiserWizardFormProps>) {
  const { t, i18n } = useTranslation()
  const intlConfigLang = i18n.language

  return (
    <Box sx={Styles.Form} component="form" onSubmit={event => event.preventDefault()}>
      <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.fundraiserDetails')}</Typography>
      <FormField
        label={t('workplace_giving.wizard.coverImage.label')}
        necessity="required"
        status={coverImageError ? 'danger' : 'default'}
        statusMessage={coverImageError}
        data-testid="coverImageField"
      >
        <FileInput
          placeholder={t('workplace_giving.wizard.fundraiser.coverImage.placeholder')}
          accept={config.acceptedBannerImgFormats}
          dataTestId="coverImageInput"
          onChange={file => handleCoverImageChange(file)}
          showPreview
          initialImage={coverImage}
        />
      </FormField>

      <FormField
        label={t('workplace_giving.wizard.name.label')}
        necessity="required"
        status={touched.title && errors.title ? 'danger' : 'default'}
        statusMessage={errors.title}
        data-testid="nameField"
      >
        <TextInput
          name="title"
          value={values.title}
          placeholder={t('workplace_giving.wizard.name.placeholder')}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormField>

      <FormField
        label={t('workplace_giving.wizard.description.label')}
        necessity="required"
        status={touched.story && errors.story ? 'danger' : 'default'}
        statusMessage={errors.story}
        data-testid="descriptionField"
      >
        <RichTextEditor
          fieldName="description"
          error={!!errors.story && !!touched.story}
          placeholder={t('workplace_giving.wizard.fundraiser.description.placeholder')}
          content={values.story}
          handleBlur={e => {
            handleBlur(e)
            setFieldTouched('story')
          }}
          handleUpdate={(newValue: string) => setFieldValue('story', newValue)}
        />
      </FormField>

      <Typography sx={Styles.SectionTitle} data-testid="goal">
        {t('workplace_giving.wizard.goal.label')}
      </Typography>
      <FormField
        disabled
        label={t('workplace_giving.wizard.goal.label')}
        necessity="required"
        status={touched?.money?.amount && errors?.money?.amount ? 'danger' : 'default'}
        statusMessage={errors?.money?.amount}
        data-testid="amountInput"
      >
        <DynamicCurrencyInput
          id="money.amount"
          name="money.amount"
          defaultCurrency={currency}
          locale={intlConfigLang}
          currencies={currencies}
          value={values?.money?.amount ? (values.money as Money) : undefined}
          placeholder={{ amount: 0, currency }}
          onValueChange={value => {
            if (value) {
              setFieldValue('money', value)
            }
            setFieldValue('money.amount', value?.amount)
          }}
          onBlur={handleBlur}
          allowNegativeValue={false}
        />
      </FormField>

      <Typography sx={Styles.SectionTitle}>{t('workplace_giving.wizard.supportedNonprofit')}</Typography>

      {fundraiserData?.organisation && (
        <Card>
          <Box paddingX={3} paddingTop={3} width="100%" data-testid="organisationDetailsPreview">
            <OrganisationHeaderWithImage
              title={fundraiserData.organisation.displayName || fundraiserData?.organisation.name}
              countryCode={fundraiserData.organisation?.countryCode}
              registryId={fundraiserData.organisation?.registryId}
              iconUrl={fundraiserData.organisation?.logo}
              website={fundraiserData.organisation?.website}
            />
          </Box>
        </Card>
      )}
    </Box>
  )
}
