import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const WalletAmountContainer: Styles = {
  display: 'flex',
  position: 'absolute',
  top: lemonadeTheme.sizes[4],
  right: lemonadeTheme.sizes[4],
  padding: `${lemonadeTheme.sizes[2]} ${lemonadeTheme.sizes[3]}`,
  backgroundColor: lemonadeTheme.colors.white,
  borderRadius: lemonadeTheme.sizes[4],
  gap: lemonadeTheme.sizes[1],
  fontSize: lemonadeTheme.fontSizes.bodySmall,

  span: {
    fontWeight: lemonadeTheme.fontWeights.semiBold
  }
}
