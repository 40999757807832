import { Box } from '@mui/material'

import { Icon } from '@percent/lemonade'
import * as Styles from './ErrorIcon.styles'

export function ErrorIcon() {
  return (
    <Box sx={Styles.IconWrapper}>
      <Icon name="close" size="xl" color="alert400" />
    </Box>
  )
}
