import styled from 'styled-components'

import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

const Flex: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

export const Wrapper: Styles = {
  padding: lemonadeTheme.sizes.m,
  borderRadius: '8px',
  boxShadow: '0px 2px 16px 0px #0D0D0D14',
  display: 'flex',
  flexDirection: 'column',
  gap: lemonadeTheme.sizes.s
}

export const Header: Styles = {
  display: 'flex',
  alignItems: 'center',
  height: '16px',
  gap: '8px',
  position: 'relative',

  '> *:not(:last-child)': {
    position: 'relative'
  },

  '> *:nth-last-of-type(2)': {
    marginRight: '16px'
  },

  '> *:nth-last-of-type(2)::after': {
    content: '"\\2022"',
    color: lemonadeTheme.colors.gray100,
    fontSize: '1rem',
    position: 'absolute',
    right: '-16px',
    top: '50%',
    transform: 'translateY(-50%)'
  }
}

export const HeaderTime: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  fontWeight: lemonadeTheme.fontWeights.regular
}

export const NameBox: Styles = {
  ...Flex,
  flexDirection: 'row',

  a: {
    textDecorationColor: customPalette.gray600,
    textUnderlineOffset: '4px'
  }
}

export const Name: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodyLarge,
  fontWeight: lemonadeTheme.fontWeights.semiBold,
  color: lemonadeTheme.colors.black,
  marginRight: lemonadeTheme.sizes[3]
}

export const PartialAmountBox: Styles = {
  ...Flex,
  justifyContent: 'flex-start',
  '& > p:first-of-type': {
    marginRight: lemonadeTheme.sizes[3]
  },

  '& > p:last-of-type': {
    flexGrow: 1,
    textAlign: 'right'
  }
}

export const PendingPartialAmountBox: Styles = {
  ...PartialAmountBox,
  color: customPalette.gray100,

  '& > p:first-of-type': {
    flex: 1,
    marginRight: lemonadeTheme.sizes[3]
  },

  '& > p:last-of-type, & > div:last-of-type': {
    flex: 1,
    textAlign: 'right'
  }
}

export const PartialAmountText: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall
}

export const Separator: Styles = {
  height: '1px',
  backgroundColor: lemonadeTheme.colors.gray90
}

export const TotalAmountBox: Styles = {
  ...Flex,

  '& > p:first-of-type': {
    flex: 1,
    marginRight: lemonadeTheme.sizes[3]
  },

  '& > p:last-of-type, & > div:last-of-type': {
    flex: 1,
    textAlign: 'right'
  }
}

export const PendingTotalAmountBox: Styles = {
  ...TotalAmountBox,
  color: customPalette.gray100
}

export const TotalAmountText: Styles = {
  fontSize: lemonadeTheme.fontSizes.body,
  fontWeight: lemonadeTheme.fontWeights.semiBold
}

export const ContentBox: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: lemonadeTheme.sizes.xs
}

export const ResendReceiptButton: Styles = {
  position: 'absolute',
  right: 0,
  padding: 0,
  minWidth: lemonadeTheme.sizes.m,
  width: lemonadeTheme.sizes.m,
  height: lemonadeTheme.sizes.m,
  ':hover': {
    background: 'none'
  }
}

export const DonationStatus = styled.span`
  cursor: default;
`
