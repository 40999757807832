import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IllustratedMessage } from '@percent/lemonade'

export function NoDonationView() {
  const { t } = useTranslation()

  return (
    <Box paddingTop={3}>
      <IllustratedMessage
        illustration="no-donations"
        title={t('workplace_giving.myFundraiser.illustratedMessage.title')}
        description=""
      />
    </Box>
  )
}
