import styled from 'styled-components'
import { Icon, ProgressBar } from '@percent/lemonade'
import { FundraiserProgressBarProps } from './progress-bar.types'

const StyledAnimationWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledAnimatedHeart = styled.span<{
  isAnimating: boolean
  heartAnimationPosition: number
}>`
  position: absolute;
  right: auto;
  transform: translateX(-50%);
  top: -15px;
  opacity: 0;
  ${({ heartAnimationPosition }) => `
      left: ${
        heartAnimationPosition <= 100 ? `${heartAnimationPosition}%` : '100%'
      };
  `}

  @keyframes heartAnimation {
    0% {
      opacity: 1;
      top: -20px;
    }
    100% {
      opacity: 0;
      top: -35px;
    }
  }

  ${({ isAnimating }) =>
    isAnimating &&
    `
    animation: heartAnimation;
    animation-delay: 0.5s;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  `}
`

export const FundraiserProgressBar = ({
  progressPercentage,
  heartAnimationPosition,
  isAnimating,
}: FundraiserProgressBarProps) => {
  return (
    <StyledAnimationWrapper>
      <ProgressBar
        progressPercentage={progressPercentage}
        backgroundColorComplete="info400"
        backgroundColorIncomplete="info400"
        shadeColor="gray90"
      />
      <StyledAnimatedHeart
        heartAnimationPosition={heartAnimationPosition}
        isAnimating={isAnimating}
      >
        <Icon name="heart" color="heart" size={4} aria-label="heart" />
      </StyledAnimatedHeart>
    </StyledAnimationWrapper>
  )
}
