import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import {
  TrendingOrganisation,
  TrendingOrganisationsQueryParams,
  TrendingOrganisationsResponse
} from '@percent/workplace-giving/api/trending/trending.types'
import { getOrganisations } from '@percent/workplace-giving/api/organisations/getOrganisation/getOrganisation'

export const getTrending: QueryFn<[string, TrendingOrganisationsQueryParams], TrendingOrganisation[]> = async (
  client,
  [_, { startDate, endDate, currencyCode, limit = 3 }]
) => {
  const { data } = await client.get<{ data: TrendingOrganisationsResponse[] }>(`/donations/organisations/trending`, {
    withCredentials: true,
    params: {
      startDate,
      endDate,
      currencyCode,
      limit
    }
  })

  const trending = data.data

  const organisationIds = trending.map(item => item.organisationId)

  const organisations = await getOrganisations(client, ['', organisationIds])
  const organisationsMap = Object.fromEntries(organisations.map(o => [o.id, o]))

  return trending.map(item => ({
    ...item,
    organisation: organisationsMap[item.organisationId]!
  }))
}
