import { Box, Skeleton } from '@mui/material'

import { Card, theme as lemonadeTheme } from '@percent/lemonade'

export function OpportunityCardSkeleton() {
  return (
    <Card>
      <Box width="100%">
        <Skeleton variant="rectangular" height="140px" width="100%" style={{ padding: lemonadeTheme.sizes[4] }} />
        <Box padding={lemonadeTheme.sizes[6]}>
          <Skeleton variant="rectangular" height={lemonadeTheme.sizes[6]} width="85%" />
          <Box marginTop={lemonadeTheme.sizes[4]} display="flex" flexDirection="column" gap={lemonadeTheme.sizes[2]}>
            <Skeleton variant="rectangular" height={lemonadeTheme.sizes[6]} />
            <Skeleton variant="rectangular" height={lemonadeTheme.sizes[6]} />
            <Skeleton variant="rectangular" height={lemonadeTheme.sizes[6]} />
            <Skeleton variant="rectangular" height={lemonadeTheme.sizes[6]} />
          </Box>

          <Box
            marginTop={lemonadeTheme.sizes[2]}
            paddingTop={lemonadeTheme.sizes[4]}
            borderTop={`1px solid ${lemonadeTheme.colors.gray100}`}
          >
            <Skeleton variant="rectangular" height={lemonadeTheme.sizes[4]} width="50%" />
            <Box
              display="flex"
              justifyContent="space-between"
              gap={lemonadeTheme.sizes[2]}
              marginTop={lemonadeTheme.sizes[4]}
            >
              <Skeleton variant="rectangular" height={lemonadeTheme.sizes[4]} width="55%" />
              <Skeleton
                variant="rectangular"
                height={lemonadeTheme.sizes[6]}
                width={lemonadeTheme.sizes[6]}
                style={{ borderRadius: lemonadeTheme.borderRadii.small }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
