import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEffect, useState } from 'react'
import { browserName, browserVersion } from 'react-device-detect'

import { AnalyticsContext, defaultAnalyticsContext } from '../AnalyticsContext/AnalyticsContext'
import { AnalyticsEvent } from '../AnalyticsContext/AnalyticsContext.types'

import { getDeviceType } from '@percent/utility'

const shouldUseSegment = () =>
  ['sandbox', 'staging', 'preprod', 'qa', 'production', 'preview'].includes(process.env.NX_REACT_APP_ENV as string)

const shouldSample = () => true // could have e.g. 99% sampling here with 0.01 < Math.random()

const getSegmentWriteKey = () => process.env.NX_REACT_APP_SEGMENT_WRITE_KEY

const defaultContext = {
  app: { appName: 'Workplace Giving', id: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA },
  device: { type: getDeviceType(), browserName, browserVersion }
}

const defaultProperties = {
  domain: 'Workplace Giving'
}

export function AnalyticsContextController({ children }: { children: React.ReactNode }) {
  const [analytics, setAnalytics] = useState(defaultAnalyticsContext)

  useEffect(() => {
    const loadAnalytics = async (writeKey: string) => {
      const [segment] = await AnalyticsBrowser.load({ writeKey }, { disableClientPersistence: true })

      setAnalytics({
        loaded: true,
        track: ({
          event,
          properties,
          accountId
        }: {
          event: AnalyticsEvent
          properties?: Record<string, any>
          accountId?: string
        }) => {
          const props = { ...defaultProperties, ...properties }
          segment.track(event, props, { context: defaultContext, ...(accountId && { userId: accountId }) })
        },
        page: ({
          name,
          category,
          properties,
          accountId
        }: {
          category: string
          name: string
          properties?: Record<string, any>
          accountId?: string
        }) => {
          const props = { ...defaultProperties, ...properties }
          segment.page(category, name, props, { context: defaultContext, ...(accountId && { userId: accountId }) })
        },
        identify: ({ accountId, traits }: { accountId: string; traits?: Record<string, any> }) => {
          segment.identify(accountId, traits, { context: defaultContext })
        }
      })
    }

    if (shouldUseSegment() && shouldSample() && getSegmentWriteKey()) {
      loadAnalytics(getSegmentWriteKey()!).catch(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
}
