import styled from 'styled-components'
import { TotalImpactDetailsProps } from './total-impact-details.types'
import { ReactComponent as HeartAvatar } from '../../../assets/icons/heart-avatar.svg'

const Card = styled.div`
  width: 100%;
`

const NoImpactInfoWrapper = styled.div<{ maxWidth?: string }>`
  ${({ theme, maxWidth = '100%' }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  flex-wrap: nowrap;
  max-width: ${maxWidth};

  & > svg {
    width: ${theme.sizes[12]};
    height: ${theme.sizes[12]};
    min-width: ${theme.sizes[10]};
    border-radius: ${theme.borderRadii.medium};
    margin-right: ${theme.sizes[4]};
  }

  & > div {
    p {
      margin: 0;
      font-size:  ${theme.fontSizes.bodySmall};
      line-height: ${theme.sizes[6]};
    }

    p:first-of-type {
      font-weight: ${theme.fontWeights.medium};
    }

    p:last-of-type {
      color: ${theme.colors.gray600};
    }
  }
  `}
`

const Title = styled.div`
  ${({ theme }) => `
    font-size:  ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[6]};
  `}
`

const Value = styled.div`
  ${({ theme }) => `
    padding: ${theme.sizes[1]} 0;
    font-size: ${theme.sizes[10]};
    line-height: ${theme.sizes[12]};
    font-weight: ${theme.fontWeights.bold};
  `}
`

export const TotalImpactDetails = ({
  children,
  title,
  value,
  showNoImpactInfo,
  noImpactTitle,
  noImpactDescription,
}: TotalImpactDetailsProps) => {
  return (
    <Card>
      {!!value && !showNoImpactInfo ? (
        <>
          <Title>{title}</Title>
          <Value>{value}</Value>
          {children ? children : null}
        </>
      ) : (
        <NoImpactInfoWrapper maxWidth={noImpactDescription ? undefined : '80%'}>
          <HeartAvatar />
          <div>
            <p>{noImpactTitle}</p>
            {!!noImpactDescription && <p>{noImpactDescription}</p>}
          </div>
        </NoImpactInfoWrapper>
      )}
    </Card>
  )
}
