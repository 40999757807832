import { Styles } from '@percent/workplace-giving/theme'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const DonateShareBtn: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',

  [queryWidthLessThan('l')]: {
    flexDirection: 'row-reverse',
    position: 'fixed',
    bottom: '0',
    padding: '1rem 1.5rem',
    marginRight: '0',
    marginLeft: '0',
    marginBottom: '1rem',
    left: '0',
    right: '0',
    backgroundColor: 'white',
    boxShadow: '0px 0px 72px 0px rgba(33, 48, 53, 0.16)'
  }
}

export const MatchingInfoWrapper: Styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'start',
  backgroundColor: 'rgba(139, 61, 255, 0.15)',
  borderRadius: '4px',
  padding: '8px 16px',

  '& > p': {
    inlineSize: '100%',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.35px',
    color: 'rgb(7, 12, 24)',
    marginLeft: '8px',
    flex: '5'
  }
}

export const Multiplier: Styles = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px !important',
  height: '24px !important',
  borderRadius: '100%',
  background: 'linear-gradient(to bottom right, #8B4AFF, #FF88D1)',

  span: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    fontSize: '10px',
    fontWeight: 800,
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '100%'
  }
}

export const FundraiserDetailsWrapper: Styles = {
  gap: '20px',
  display: 'flex',
  flexDirection: 'column'
}

export const OrganiserInfo: Styles = {
  paddingRight: lemonadeTheme.sizes[4],
  color: customPalette.gray600,
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center'
}

export const CardContentWrapper: Styles = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: lemonadeTheme.sizes[2],
  marginX: lemonadeTheme.sizes[2],

  'div:first-child': {
    width: '100%'
  }
}
