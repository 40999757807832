import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const PageWrapper: Styles = {
  paddingY: `${lemonadeTheme.sizes.l}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '24px',

  [queryWidthLessThan('xl')]: {
    flexDirection: 'column-reverse'
  }
}

export const PageContentWrapper: Styles = {
  maxWidth: '60%',
  flex: 6,
  gap: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  [queryWidthLessThan('xl')]: {
    flex: 1,
    maxWidth: '100%'
  }
}
