import { Opportunity } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'

export type RegisterForOpportunityProps = {
  id: Opportunity['id']
  action: RegisterAction
  timeZone?: string
}

export type RegisterForOpportunityResponse = {
  success: true
}

export enum RegisterAction {
  register = 'register',
  unregister = 'unregister'
}
