import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom-v6'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { createEnumParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { useEffect, useState } from 'react'

import { GetAccountFundraiser } from '@percent/workplace-giving/api/fundraiser/getAccountFundraisers/getAccountFundraisers.types'
import { closeFundraiser } from '@percent/workplace-giving/api/fundraiser/closeFundraiser/closeFundraiser'
import * as Styles from './MyFundraiser.styles'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { customPalette } from '@percent/workplace-giving/theme/theme'
import { getAccountFundraisers } from '@percent/workplace-giving/api/fundraiser/getAccountFundraisers/getAccountFundraisers'
import { useQueryList } from '@percent/workplace-giving/common/hooks/useQueryList/useQueryList'
import { formatMoney } from '@percent/utility'
import { CursorPagination } from '@percent/workplace-giving/common/components/CursorPagination/CursorPagination'
import { MyFundraiserActionsMenu } from './MyFundraiserActionsMenu/MyFundraiserActionsMenu'
import { MyFundraiserSkeleton } from './MyFundraiserSkeleton'
import { CloseFundraiserModal } from './CloseFundraiserModal'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { useLogger } from '@percent/workplace-giving/common/hooks'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { CloseFundraiserSuccessModal } from './CloseFundraiserSuccessModal'
import { NoDonationView } from './NoDonationView/NoDontionView'
import { ChipTabs } from './ChipTabs/ChipTabs'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

dayjs.extend(localizedFormat)

export function MyFundraiser() {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()
  const { logError } = useLogger()
  const navigation = useNavigate()
  const { track } = useAnalytics()
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [closeModalOpen, setCloseModalOpen] = useState(false)
  const [selectedFundraiser, setSelectedFundraiser] = useState<GetAccountFundraiser>()

  const { formatDateTime } = useDateTimeFmt()

  const [query, setQuery] = useQueryParams({
    status: createEnumParam(['published', 'closed']),
    page: NumberParam,
    direction: StringParam,
    cursor: StringParam,
    pageSize: NumberParam
  })

  const activeTab = query.status ? query.status : 'published'
  const emptySearchParams = Object.values(query).every(el => el === undefined)

  useEffect(() => {
    if (emptySearchParams) {
      setQuery({ status: 'published' })
    }
  }, [emptySearchParams, navigation, setQuery])

  const {
    data,
    isLoading,
    isError,
    isRefetching,
    refetch,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage
  } = useQueryList('getAccountFundraisers', query, getAccountFundraisers, setQuery)

  const { mutateAsync, isLoading: isLoadingMutation } = useMutation({
    mutationFn: closeFundraiser,
    onSuccess: res => {
      if (res) {
        refetch()
        setCloseModalOpen(false)
        setSuccessModalOpen(true)
      } else {
        setCloseModalOpen(false)
        setErrorModalOpen(true)
      }
    },
    onError: e => {
      logError(e)
      setCloseModalOpen(false)
      setErrorModalOpen(true)
    }
  })

  const handleCloseFundraiser = async () => {
    if (selectedFundraiser) {
      await mutateAsync({ id: selectedFundraiser.id })
      setSelectedFundraiser(undefined)
    }
  }

  if (isError) {
    return (
      <Page>
        <Box width="100%">
          <Typography
            mt={5}
            textAlign="center"
            variant="body2"
            sx={{
              color: customPalette.alert400
            }}
          >
            {t('workplace_giving.errors.unexpected')}
          </Typography>
        </Box>
      </Page>
    )
  }

  if (isLoading || isRefetching) {
    return <MyFundraiserSkeleton />
  }

  return (
    <>
      <Box width="100%">
        {data?.data?.length !== 0 ? (
          <>
            <ChipTabs setQuery={setQuery} activeTab={activeTab} />
            <TableContainer sx={Styles.Table}>
              <Table>
                <TableHead>
                  <TableRow sx={Styles.TableRowHeader}>
                    <TableCell align="left">{t('workplace_giving.myFundraiser.name')}</TableCell>
                    <TableCell align="left" width="10%">
                      {t('workplace_giving.myFundraiser.progress')}
                    </TableCell>
                    <TableCell align="left" width="16%">
                      {t('workplace_giving.myFundraiser.goal')}
                    </TableCell>
                    <TableCell align="left" width="10%">
                      {t('workplace_giving.myFundraiser.startDate')}
                    </TableCell>
                    <TableCell align="left" width="10%">
                      {t('workplace_giving.myFundraiser.actions')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.map(row => (
                    <TableRow sx={Styles.TableRow} key={row.id}>
                      <TableCell scope="row">
                        <NavLink style={{ color: customPalette.primary }} to={`/fundraisers/${row.id}`}>
                          {row.page.title}
                        </NavLink>
                      </TableCell>
                      <TableCell scope="row" align="left">
                        <Typography fontSize={14}>
                          {((row.donationTotal.amount / row.campaign.goal.amount) * 100).toFixed(0)}%
                        </Typography>
                      </TableCell>
                      <TableCell scope="row" align="left">
                        <Typography fontSize={14}>
                          {t('workplace_giving.myFundraiser.totalDonated', {
                            total: formatMoney(row.donationTotal, { hideDecimalsIfZero: true, locale }),
                            goal: formatMoney(row.campaign.goal, { hideDecimalsIfZero: true, locale })
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell scope="row" align="left">
                        <Typography fontSize={14}>{formatDateTime(new Date(row.createdAt))}</Typography>
                      </TableCell>
                      <TableCell scope="row" align="left">
                        <MyFundraiserActionsMenu
                          status={row.status}
                          handleSelect={(actionKey: string) => {
                            switch (actionKey) {
                              case 'view':
                                navigation(`/fundraisers/${row.id}`)
                                break
                              case 'edit':
                                track({
                                  event: 'Donation Fundraiser Edit Start',
                                  properties: {
                                    origin: 'My Fundraisers'
                                  }
                                })
                                navigation(`/fundraise/fundraiser-wizard/${row.id}/edit`)
                                break
                              case 'close':
                                track({
                                  event: 'Donation Fundraiser Close',
                                  properties: {
                                    origin: 'My Fundraisers'
                                  }
                                })
                                setCloseModalOpen(true)
                                setSelectedFundraiser(row)
                                break
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box>
                <CursorPagination
                  nextPage={fetchNextPage}
                  previousPage={fetchPreviousPage}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  totalRecords={data.totalResults}
                />
              </Box>
            </TableContainer>
          </>
        ) : (
          <>
            <ChipTabs setQuery={setQuery} activeTab={activeTab} />
            <NoDonationView />
          </>
        )}
      </Box>
      <CloseFundraiserModal
        open={closeModalOpen}
        handleCloseFundraiser={handleCloseFundraiser}
        handleClose={() => {
          setCloseModalOpen(false)
          setSelectedFundraiser(undefined)
        }}
        isLoading={isLoadingMutation}
        fundraiserName={selectedFundraiser?.page.title}
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.myFundraiser.close.error')}
        description={t('workplace_giving.errors.api.modal.closeFundraiser')}
        open={errorModalOpen}
        onClose={() => {
          setErrorModalOpen(false)
        }}
      />
      <CloseFundraiserSuccessModal open={successModalOpen} handleClose={() => setSuccessModalOpen(false)} />
    </>
  )
}
