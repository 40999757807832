import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { useMutation } from '@percent/workplace-giving/common/hooks'
import { createOpportunity } from '@percent/workplace-giving/api/opportunity/create/create-opportunity'
import {
  CreateEventSeriesSessionParams,
  CreateOpportunityParams
} from '@percent/workplace-giving/api/opportunity/create/create-opportunity.types'
import { createEventSeriesSessions } from '@percent/workplace-giving/api/opportunity/create/create-event-series-sessions'

export type UseCreateOpportunityMutationProps = {
  onSuccess: (opportunityId: string) => void
  onCreateOpportunityError: (error: unknown) => void
  onCreateSessionsErrors: (errors: unknown[]) => void
}

export type CreateOpportunityFn = (args: {
  opportunity: CreateOpportunityParams
  sessions?: Omit<CreateEventSeriesSessionParams, 'parentId'>[]
}) => Promise<void>

export const useCreateOpportunityMutation = ({
  onSuccess,
  onCreateOpportunityError,
  onCreateSessionsErrors
}: UseCreateOpportunityMutationProps): CreateOpportunityFn => {
  const { mutateAsync: createSessionsMutateAsync } = useMutation({ mutationFn: createEventSeriesSessions })
  const { mutateAsync: createOpportunityMutateAsync } = useMutation({ mutationFn: createOpportunity })

  return async ({ opportunity, sessions }) => {
    let opportunityId: string
    try {
      const createOpportunityRes = await createOpportunityMutateAsync([opportunity])

      if (!okResponse(createOpportunityRes)) {
        return onCreateOpportunityError(createOpportunityRes)
      }

      opportunityId = createOpportunityRes.data.id
    } catch (err) {
      return onCreateOpportunityError(err)
    }

    if (!sessions?.length) {
      return onSuccess(opportunityId)
    }

    try {
      const createSessionsRes = await createSessionsMutateAsync([
        sessions.map(session => ({ ...session, parentId: opportunityId }))
      ])

      const errors = createSessionsRes.filter(res => res.status === 'rejected' || !okResponse(res.value))

      if (errors.length) {
        onCreateSessionsErrors(errors.map(res => (res.status === 'rejected' ? res.reason : res.value)))
      }

      return onSuccess(opportunityId!)
    } catch (err) {
      onCreateSessionsErrors(Array.isArray(err) ? err : [err])

      return onSuccess(opportunityId!)
    }
  }
}
