import { Box, Typography } from '@mui/material'
import { Divider } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { customPalette } from '@percent/workplace-giving/theme/theme'
import { Button, Modal } from '@percent/lemonade'

type OpportunityFormCancellationModalProps = {
  handleCloseCancellationModal: VoidFunction
  openCancellationModal: boolean
}

export function OpportunityFormCancellationModal({
  handleCloseCancellationModal,
  openCancellationModal
}: Readonly<OpportunityFormCancellationModalProps>) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal onClose={handleCloseCancellationModal} open={openCancellationModal}>
      <Box padding={3}>
        <Typography fontSize={20} fontWeight={600}>
          {t('workplace_giving.wizard.confirmCancellation')}
        </Typography>
        <Divider my={16} />
        <Typography fontSize={14} color={customPalette.neutral300}>
          {t('workplace_giving.wizard.cancellation')}
        </Typography>
        <Box mt={4} display="flex" justifyContent="end" gap={2}>
          <Button variant="secondary" onPress={handleCloseCancellationModal}>
            {t('workplace_giving.wizard.continueEditing')}
          </Button>
          <Button
            variant="primary"
            onPress={() => {
              handleCloseCancellationModal()
              navigate(-1)
            }}
          >
            {t('workplace_giving.common.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
