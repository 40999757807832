import { Box, Button, ClickAwayListener, Divider, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useNavigate } from 'react-router-dom-v6'
import { useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { scope } from '../../hoc/withPermissions'
import { usePermission } from '../../hooks/usePermission/usePermission'

import { Icon } from '@percent/lemonade'
import { useAuth } from '@percent/workplace-giving/common/hooks/useAuth/useAuth'
import { useClient } from '@percent/workplace-giving/common/hooks'
import {
  getAccountFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import * as Styles from './NavBar.styles'
import { ActionsMenu } from './ActionsMenu/ActionsMenu'
import { config } from '@percent/workplace-giving/config/config'

export const addEllipsis = (str: string, totalChar: number) => {
  return str?.length < totalChar ? str : `${str?.substring(0, totalChar)}...`
}

export function NavBar() {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigation = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'))
  const authState = useAuth()
  const account = getAccountFromAuthState(authState.state)!
  const partner = getPartnerFromAuthState(authState.state)!
  const { gsClient } = useClient()
  const queryClient = useQueryClient()
  const has = usePermission()
  const volunteeringEnabled = has(scope('volunteering'))

  const anchorEl = useRef<HTMLButtonElement | null>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  const handleLogout = async () => {
    authState.dispatch({ type: 'LOADING' })
    queryClient.removeQueries()
    await gsClient.post('/auth/logout')
    authState.dispatch({ type: 'LOGOUT' })
  }

  return (
    <Box sx={Styles.NavBarWrapper}>
      <Box sx={Styles.NavBarInnerWrapper}>
        {partner.logo ? (
          <Box sx={Styles.LogoWrapper}>
            <Link to={AppRoute.HOME}>
              <img src={partner.logo} alt={`${partner.name} logo`} />
            </Link>
          </Box>
        ) : null}

        {account ? (
          <Box sx={Styles.NavBarLinksWrapper}>
            <NavLink to={AppRoute.HOME} end>
              {t('workplace_giving.navigation.donate')}
            </NavLink>

            {volunteeringEnabled ? (
              <NavLink to={AppRoute.VOLUNTEER}>{t('workplace_giving.navigation.volunteer')}</NavLink>
            ) : null}

            <NavLink to={AppRoute.MY_IMPACT}>{t('workplace_giving.navigation.myImpact')}</NavLink>

            <ActionsMenu />

            <ClickAwayListener onClickAway={handleMenuClose}>
              <Button
                data-testid="account-menu"
                sx={Styles.MenuButton}
                type="button"
                onClick={handleMenuClick}
                disableRipple
                ref={anchorEl}
              >
                {isMobile
                  ? `${
                      addEllipsis(account?.fullName ?? '', 50) ||
                      addEllipsis(account?.preferredName ?? '', 50) ||
                      'Account'
                    }`
                  : null}
                <Icon name="dropdown-arrow-down" color="gray500" size={6} />

                <Menu
                  anchorEl={anchorEl.current}
                  open={isMenuOpen}
                  PaperProps={{
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '0.25rem',
                      boxShadow: 'rgba(33, 37, 53, 0.16) 0px 8px 32px',
                      margin: '0.25rem 0px',
                      padding: '0px 0.5rem',
                      outline: 'none',
                      zIndex: 1,
                      backgroundColor: 'white'
                    }
                  }}
                  disableScrollLock
                >
                  {has(scope('donation_link')) && (
                    <>
                      <MenuItem
                        onClick={() => navigation(`${AppRoute.MY_FUNDRAISER}`)}
                        sx={Styles.MenuItem}
                        disableRipple
                      >
                        {t('workplace_giving.navigation.myProfile')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigation(`${AppRoute.MY_FUNDRAISER}`)}
                        sx={Styles.MenuItem}
                        disableRipple
                      >
                        {t('workplace_giving.navigation.myFundraisers')}
                      </MenuItem>
                    </>
                  )}
                  {volunteeringEnabled && (
                    <MenuItem
                      onClick={() => navigation(`${AppRoute.MY_OPPORTUNITY}`)}
                      sx={Styles.MenuItem}
                      disableRipple
                    >
                      {t('workplace_giving.navigation.myOpportunities')}
                    </MenuItem>
                  )}
                  <Divider sx={Styles.Divider} />
                  <MenuItem
                    onClick={() => {
                      window.open(config.urls.helpCenterUrl, '_blank', 'noopener noreferrer')
                    }}
                    sx={Styles.MenuItem}
                    disableRipple
                  >
                    <Icon name="help-center" color="gray300" size={5} />
                    {t('workplace_giving.navigation.helpCenter')}
                  </MenuItem>
                  <Divider sx={Styles.Divider} />
                  <MenuItem onClick={handleLogout} sx={Styles.MenuItem} disableRipple>
                    {t('workplace_giving.navigation.logOut')}
                  </MenuItem>
                </Menu>
              </Button>
            </ClickAwayListener>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}
