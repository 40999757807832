import { theme as lemonadeTheme } from '@percent/lemonade'
import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const NavBarWrapper: Styles = {
  zIndex: 100,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  boxShadow: '0px 0px 72px 0px rgba(7, 12, 24, 0.08)',
  backgroundColor: lemonadeTheme.colors.white
}

export const NavBarInnerWrapper: Styles = theme => ({
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingY: '26px',

  marginX: '64px',
  [theme.breakpoints.down('md')]: {
    marginX: '24px'
  },

  '@media (min-width: 1568px)': {
    marginX: 'auto',
    maxWidth: '1440px'
  }
})

export const LogoWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '28px',
  margin: 'auto auto auto 0',
  overflowY: 'hidden',
  width: '17%',

  a: {
    display: 'inline-block',
    width: '100%',
    lineHeight: 0,

    '&:focus': {
      outline: 'none'
    }
  },

  img: {
    maxHeight: '28px',
    width: 'auto',
    maxWidth: '100%'
  },

  '@media (max-width: 900px)': {
    display: 'none'
  }
}

export const NavBarLinksWrapper: Styles = theme => ({
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: lemonadeTheme.fontSizes.bodySmall,

  '& a': {
    padding: '2px',
    marginRight: '18px',
    color: lemonadeTheme.colors.black,
    textDecoration: 'none',
    fontWeight: lemonadeTheme.fontWeights.medium,
    transition: 'color 250ms ease-in-out',
    textWrap: 'nowrap',

    [theme.breakpoints.down('md')]: {
      marginRight: '8px'
    },

    '&.active': {
      color: theme.palette.clientColor
    },

    '&:hover': {
      textDecoration: 'underline',
      background: 'none !important'
    },

    '&:focus:not(:focus-visible)': {
      outline: 'none'
    },

    '&:focus-visible': {
      outlineStyle: 'solid',
      color: theme.palette.clientColor
    }
  }
})

export const MenuButton: Styles = theme => ({
  display: 'inline-flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'nowrap',
  textWrap: 'nowrap',
  padding: '2px',
  marginLeft: '18px',
  backgroundColor: 'transparent',
  border: 'none',
  gap: '4px',
  borderRadius: 0,
  color: lemonadeTheme.colors.black,
  fontWeight: lemonadeTheme.fontWeights.medium,
  textTransform: 'none',
  transition: 'color 250ms ease-in-out',
  [theme.breakpoints.down('md')]: {
    marginLeft: '8px',
    minWidth: '24px'
  },

  '&::before': {
    position: 'absolute',
    left: '-18px',
    top: '50%',
    transform: 'translateY(-50%)',
    content: "''",
    width: '1px',
    height: '25px',
    backgroundColor: lemonadeTheme.colors.gray100,

    [theme.breakpoints.down('md')]: {
      marginLeft: '12px'
    }
  },

  '&:hover': {
    textDecoration: 'underline',
    background: 'none'
  },

  '&:focus-visible': {
    outline: '1px dotted black'
  },

  '&:focus': {
    outlineStyle: 'solid',
    outlineColor: theme.palette.clientColor
  }
})

export const MenuItem: Styles = {
  margin: `0 0 ${lemonadeTheme.sizes[1]} 0`,
  borderRadius: lemonadeTheme.borderRadii.small,
  fontSize: '0.875rem',
  fontWeight: lemonadeTheme.fontWeights.medium,

  '&:last-of-type': {
    marginBottom: 0
  },

  '& > svg': {
    marginRight: lemonadeTheme.sizes[2]
  }
}

export const Divider: Styles = {
  borderColor: customPalette.gray80,
  margin: `${lemonadeTheme.sizes[2]} 0`
}
