import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const AddToCalendarContainer: Styles = {
  '& > p': {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6],
    color: customPalette.gray600
  }
}
