import { useContext } from 'react'

import { LoggerContext } from '@percent/workplace-giving/context/logger/LoggerContext/LoggerContext'

export const useLogger = () => {
  const context = useContext(LoggerContext)

  if (context === undefined) {
    throw new Error('useLogger must be used within a LoggerContextController')
  }

  return context
}
