import { Styles } from '@percent/workplace-giving/theme'

export const IconWrapper: Styles = {
  borderRadius: '100%',
  background: 'rgba(0, 136, 80, 10%)',
  width: 80,
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
