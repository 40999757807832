import { AxiosInstance } from 'axios'

import { Permissions } from '@percent/workplace-giving/constants/permissions'

export type GetPermissionsResponse = {
  data: Permissions[]
  object: string
}

export const getPermissions = async (client: AxiosInstance) => {
  const { data } = await client.get<GetPermissionsResponse>('/iam/permissions', {
    withCredentials: true
  })

  return data.data
}
