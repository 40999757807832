import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { formatMinutesToReadable } from '@percent/workplace-giving/utils/dayjs/dayjs'
import { VolunteerTimeLogItem } from '@percent/workplace-giving/api/volunteering/getVolunteerList/getVolunteerList.types'
import { DonationCard, DonationCardLine } from '@percent/workplace-giving/common/components/Donation/Card/DonationCard'
import * as Styles from './TimeLogCard.styles'
import { Badge } from '@percent/lemonade'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'

interface TimeLogCardProps {
  timeLog: VolunteerTimeLogItem
}

export function TimeLogCard({ timeLog }: TimeLogCardProps) {
  const { t } = useTranslation()
  const { formatDateWODay } = useDateTimeFmt()

  return (
    <DonationCard
      organisation={timeLog.organisation}
      date={t('workplace_giving.myImpact.volunteer.loggedOn', { date: formatDateWODay(timeLog.createdAt) })}
    >
      <DonationCardLine
        description={t('workplace_giving.myImpact.volunteer.loggedTime')}
        value={t('workplace_giving.myImpact.volunteer.loggedTimeDetails', {
          timeAmount: formatMinutesToReadable(timeLog.minutes, t),
          date: formatDateWODay(timeLog.date)
        })}
      />
      {timeLog.opportunity && (
        <DonationCardLine
          description={t('workplace_giving.myImpact.volunteer.timeLogOpportunityName')}
          value={timeLog.opportunity.name}
        />
      )}

      {timeLog.activities && timeLog.activities.length > 0 && (
        <Box sx={Styles.CategoryBox}>
          <Typography>{t('workplace_giving.myImpact.volunteer.category')}</Typography>
          <Box sx={Styles.TagsWrapper}>
            {timeLog.activities?.map(activity => (
              <Badge key={activity.id} variant="default">
                {activity.name}
              </Badge>
            ))}
          </Box>
        </Box>
      )}
    </DonationCard>
  )
}
