import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const WalletAmountContainer: Styles = {
  zIndex: 20,
  display: 'flex',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: `${lemonadeTheme.sizes[2]} ${lemonadeTheme.sizes[3]}`,
  backgroundColor: lemonadeTheme.colors.white,
  borderRadius: lemonadeTheme.sizes[6],
  gap: lemonadeTheme.sizes[1],
  fontSize: lemonadeTheme.sizes[5],
  fontWeight: lemonadeTheme.fontWeights.semiBold,

  '& img': {
    width: lemonadeTheme.sizes[5]
  }
}

export const WalletGiftHeader: Styles = {
  overflow: 'hidden',
  position: 'relative',
  marginBottom: lemonadeTheme.sizes[6],
  height: '145px',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  background:
    'radial-gradient(95.26% 102.84% at 83.75% 35.42%, rgba(139, 61, 255, 0.10) 0%, rgba(139, 61, 255, 0.00) 100%), #FFF;',
  borderRadius: lemonadeTheme.borderRadii.large,

  '&::before': {
    content: "''",
    display: 'block',
    width: '100%',
    height: '100%',
    border: '1px solid #E9E8EB',
    borderRadius: lemonadeTheme.borderRadii.large
  }
}

export const WalletGiftHeaderStructure: Styles = {
  position: 'absolute',
  left: '100%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexFlow: 'column',
  gap: lemonadeTheme.sizes[2],

  '&:first-of-type': {
    left: 0,
    right: 'auto',
    transform: 'translate(-50%, -50%)'
  },

  '& span': {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: lemonadeTheme.sizes[4],
    height: lemonadeTheme.sizes[4],
    minWidth: lemonadeTheme.sizes[4],
    minHeight: lemonadeTheme.sizes[4],
    backgroundColor: lemonadeTheme.colors.white,
    borderRadius: '100%',
    border: '1px solid #E9E8EB'
  }
}
