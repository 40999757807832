import { Card, Typography, Box, Button } from '@mui/material'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom-v6'
import { Alpha3Code } from 'i18n-iso-countries'
import { Trans, useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'

import defaultOrgLogo from '../../../../assets/icons/placeholder-logo.svg'

import * as Styles from './DonationCard.styles'
import { Tooltip, Avatar, Icon, Text, Badge } from '@percent/lemonade'
import { DonationBadge } from '@percent/workplace-giving/common/components/DonationBadge/DonationBadge'
import { DonationBadgeVariant } from '@percent/workplace-giving/common/components/DonationBadge/DonationBadge.types'
import { TooltipTaxInformation } from '@percent/workplace-giving/common/components/TooltipTaxInformation/TooltipTaxInformation'

export type DonationCardProps = {
  badgeVariants?: DonationBadgeVariant[]
  date?: string
  organisation?: {
    name: string
    logo?: string
    hasBankDetails?: boolean
  }
  children: ReactNode
  fundraiserName?: string
  fundraiserId?: string
  resendReceiptVisible?: boolean
  resendReceiptEnabled?: boolean
  onResendReceiptClick?: () => void
  donationStatus?: 'paymentSent' | 'processing'
}

export function DonationCardLine({
  description,
  value,
  complete = true,
  orgCountryCode,
  showTaxInfo
}: {
  description: string
  complete?: boolean
  value: string
  orgCountryCode?: Alpha3Code
  showTaxInfo?: boolean
}) {
  return (
    <Box sx={complete ? Styles.PartialAmountBox : Styles.PendingPartialAmountBox}>
      <Typography sx={Styles.PartialAmountText}>{description}</Typography>
      {showTaxInfo && orgCountryCode && (
        <TooltipTaxInformation orgCountryCode={orgCountryCode} showIconOnly={showTaxInfo} />
      )}
      <Typography sx={Styles.PartialAmountText}>{value}</Typography>
    </Box>
  )
}

export function DonationCardSeparator() {
  return <Box sx={Styles.Separator} />
}

export function DonationCardSummary({
  description,
  value,
  complete = true
}: {
  description: string
  complete?: boolean
  value: string
}) {
  return (
    <Box sx={complete ? Styles.TotalAmountBox : Styles.PendingTotalAmountBox}>
      <Typography sx={Styles.TotalAmountText}>{description}</Typography>
      <Typography sx={Styles.TotalAmountText}>{value}</Typography>
    </Box>
  )
}

export function ResendReceiptButton({ enabled, onClick }: { enabled: boolean; onClick?: () => void }) {
  const iconColor = enabled ? 'gray300' : 'gray100'

  return (
    <Tooltip content={<Trans i18nKey="workplace_giving.pastDonation.card.resendReceiptTooltip" />}>
      <Button
        disableRipple
        disabled={!enabled}
        onClick={onClick}
        sx={Styles.ResendReceiptButton}
        data-testid="resendReceiptButton"
      >
        <Icon name="mail-resend" size="m" color={iconColor} />
      </Button>
    </Tooltip>
  )
}

export function DonationCard({
  badgeVariants,
  date,
  organisation,
  fundraiserName,
  fundraiserId,
  resendReceiptVisible,
  resendReceiptEnabled = true,
  onResendReceiptClick,
  donationStatus,
  children
}: DonationCardProps) {
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Card sx={Styles.Wrapper}>
      {badgeVariants?.length || date ? (
        <Box sx={Styles.Header}>
          {donationStatus && (
            <Badge variant={donationStatus === 'paymentSent' ? 'positive' : 'warning'}>
              {donationStatus !== 'paymentSent' ? (
                <Tooltip
                  content={
                    organisation?.hasBankDetails
                      ? t('workplace_giving.tooltip.bankDetails')
                      : t('workplace_giving.tooltip.noBankDetails')
                  }
                  placement={isMobile ? 'top' : 'right'}
                >
                  <Styles.DonationStatus data-testid="donation-status">
                    {t(`workplace_giving.myImpact.donationStatus.${donationStatus}`)}
                  </Styles.DonationStatus>
                </Tooltip>
              ) : (
                <span data-testid="donation-status">
                  {t(`workplace_giving.myImpact.donationStatus.${donationStatus}`)}
                </span>
              )}
            </Badge>
          )}
          {badgeVariants?.map(badgeVariant => (
            <DonationBadge variant={badgeVariant} />
          ))}
          {date && <Typography sx={Styles.HeaderTime}>{date}</Typography>}
          {resendReceiptVisible && (
            <ResendReceiptButton enabled={resendReceiptEnabled} onClick={onResendReceiptClick} />
          )}
        </Box>
      ) : undefined}
      {organisation ? (
        <Box sx={Styles.NameBox}>
          <div>
            <Typography sx={Styles.Name}>{organisation.name}</Typography>
            {fundraiserId && (
              <Link to={`/fundraisers/${fundraiserId}`}>
                <Text size="small">{fundraiserName}</Text>
              </Link>
            )}
          </div>
          <Avatar image={organisation.logo || defaultOrgLogo} alt={organisation.name} size="large" transparent />
        </Box>
      ) : null}
      <Box sx={Styles.ContentBox}>{children}</Box>
    </Card>
  )
}
