import { SplitFactory } from '@splitsoftware/splitio-react'
import { useState, useEffect, ReactNode } from 'react'

import { useAuth } from '../common/hooks/useAuth/useAuth'
import { getAccountFromAuthState } from '../context/auth/authContextController/AuthContextController'

import { environments } from '@percent/workplace-giving/constants/environments'
import { environment } from '@percent/workplace-giving/config/config'
import { getSplitKeyForUser } from '@percent/utility'

const getSplitKey = () => {
  if ([environments.PRODUCTION].includes(environment as string)) {
    return process.env.NX_SPLIT_PRODUCTION
  }

  if ([environments.SANDBOX].includes(environment as string)) {
    return process.env.NX_SPLIT_SANDBOX
  }

  if ([environments.PREPROD].includes(environment as string)) {
    return process.env.NX_SPLIT_PREPROD
  }

  if ([environments.STAGING].includes(environment as string)) {
    return process.env.NX_SPLIT_STAGING
  }

  return 'localhost'
}

/*
  Split SDK Config should reflect whats on the split.io production feature flag values,
  the above splitSdkConfig features values are set as default values for feature flags
  when the split.io has timedout.
*/

export const features = {
  onboardingCurrencyPicker: {
    key: 'wpg_onboarding_currency_picker',
    defaultValue: 'off'
  },
  volunteeringInProgress: {
    key: 'wpg_volunteering',
    defaultValue: 'off'
  },
  fundraising: {
    key: 'wpg_fundraising',
    defaultValue: 'off'
  },
  gamification: {
    key: 'wpg_gamification',
    defaultValue: 'off'
  },
  eventSessions: {
    key: 'wpg_event_sessions',
    defaultValue: 'off'
  }
}

export const featureNames = Object.keys(features) as (keyof typeof features)[]

export const featureKeys = Object.values(features).map(({ key }) => key)

export type Feature = typeof featureNames[0]
export const defaultFeatures = Object.fromEntries(featureNames.map(n => [n, false])) as Record<Feature, boolean>

export const splitSdkConfig = {
  core: {
    authorizationKey: getSplitKey(),
    key: 'key'
  },
  features: featureNames.reduce<Record<string, string>>((acc, v) => {
    acc[features[v].key] = features[v].defaultValue

    return acc
  }, {}),
  scheduler: {
    offlineRefreshRate: 15
  }
}

export function SplitSdkProvider({ children }: { children: ReactNode }) {
  const authState = useAuth()
  const user = getAccountFromAuthState(authState.state)

  const [configKey, setConfigKey] = useState(splitSdkConfig.core.key)

  useEffect(() => {
    if (user) {
      const key = getSplitKeyForUser(user)

      if (configKey !== key) {
        setConfigKey(key)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return <SplitFactory config={splitSdkConfig}>{children as any}</SplitFactory>
}
