import { Box, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import * as Styles from '../MyFundraiser.styles'

import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { Button } from '@percent/lemonade'
import { ChipTabsProps } from './ChipTabs.types'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

export function ChipTabs({ activeTab, setQuery }: ChipTabsProps) {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const { track } = useAnalytics()

  return (
    <Box my={3} display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" gap={1}>
        <Chip
          data-testId="open"
          label={t('workplace_giving.myFundraiser.open')}
          variant="outlined"
          sx={Styles.Chip}
          disabled={activeTab === 'published'}
          onClick={() => {
            setQuery({ status: 'published' }, 'push')
          }}
        />
        <Chip
          data-testId="closed"
          label={t('workplace_giving.myFundraiser.closed')}
          variant="outlined"
          sx={Styles.Chip}
          disabled={activeTab === 'closed'}
          onClick={() => setQuery({ status: 'closed' }, 'push')}
        />
      </Box>
      <Button
        size="small"
        onPress={() => {
          track({
            event: 'Donation Fundraiser Create Start',
            properties: {
              origin: 'My Fundraisers'
            }
          })
          navigation(AppRoute.FUNDRAISER_WIZARD)
        }}
      >
        {t('workplace_giving.myFundraiser.createFundraiser')}
      </Button>
    </Box>
  )
}
