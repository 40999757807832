import { ListResponse, QueryListFunction } from '../../goodstack'

import { VolunteerTimeLogItem, SearchQuery } from './getVolunteerList.types'

export const getVolunteerList: QueryListFunction<
  string,
  SearchQuery & { cursor?: string },
  ListResponse<VolunteerTimeLogItem>
> = async (client, [_, { pageSize = 10, direction = 'DESC', cursor = '' }]) => {
  const { data } = await client.get<ListResponse<VolunteerTimeLogItem>>(`/account/volunteering-time-logs`, {
    params: { pageSize, direction, ...(cursor && { cursor }) }
  })

  return data
}
