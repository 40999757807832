import { searchVerifiedOrganisations } from '@percent/workplace-giving/api/search/searchOrganisations/searchVerifiedOrganisations'
import { useInfiniteQuery } from '@percent/workplace-giving/common/hooks'

export const useOrganisationSearch = ({
  countryCode,
  query,
  localCurrencyCode
}: {
  countryCode?: string
  query?: string
  localCurrencyCode?: string
}) => {
  const { isFetching, ...normalSearch } = useInfiniteQuery(
    ['searchVerifiedOrganisations', { query, pageSize: 10, countryCode }],
    searchVerifiedOrganisations,
    {
      enabled: !!query,
      cacheTime: 0
    }
  )

  const replacedByGlobalSearch =
    !!countryCode && normalSearch.data?.pages[0]?.totalResults === 0 && !isFetching && !normalSearch.isError

  const doLocalSearch =
    !countryCode && normalSearch.data?.pages[0]?.totalResults !== 0 && !isFetching && !normalSearch.isError

  const { isFetching: isGlobalSearchFetching, ...globalSearch } = useInfiniteQuery(
    [
      'searchVerifiedOrganisations',
      { query, pageSize: 10, countryCode: replacedByGlobalSearch ? '' : localCurrencyCode }
    ],
    searchVerifiedOrganisations,
    {
      enabled: replacedByGlobalSearch || doLocalSearch,
      cacheTime: 0
    }
  )

  if (replacedByGlobalSearch) {
    return {
      ...globalSearch,
      isFetching: isFetching || isGlobalSearchFetching,
      replacedByGlobalSearch,
      hideLocalSearch: false
    }
  }

  return {
    ...normalSearch,
    isFetching: isFetching || isGlobalSearchFetching,
    replacedByGlobalSearch,
    hideLocalSearch: !!(globalSearch.data?.pages[0]?.totalResults !== 0)
  }
}
