import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Alpha3Code } from 'i18n-iso-countries'

import { DonationCard, DonationCardLine, DonationCardSeparator, DonationCardSummary } from '../Card/DonationCard'

import { Money, formatMoney } from '@percent/utility'
import { useAuth } from '@percent/workplace-giving/common/hooks/useAuth/useAuth'
import { getPartnerFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { formatRelativeTime } from '@percent/workplace-giving/utils/dayjs/dayjs'
import { DonationBadgeVariant } from '@percent/workplace-giving/common/components/DonationBadge/DonationBadge.types'
import {
  ApprovedDonationMatchRequest,
  DonationMatchRequest,
  ProcessingDonationMatchRequest,
  RejectedDonationMatchRequest,
  ValidatingDonationMatchRequest
} from '@percent/workplace-giving/api/donation-match-request/get-list/retrieve-request-list.types'
import { DonationMatchRequestStatus } from '@percent/workplace-giving/api/donation-match-request/create/create-donation-match-request.types'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'

interface MatchRequestCardProps {
  request: DonationMatchRequest
}

const matchBadgeVariant = (status: DonationMatchRequestStatus): DonationBadgeVariant[] => {
  if (status === 'processing' || status === 'validating') {
    return ['pending']
  }

  return [status as DonationBadgeVariant]
}

function RejectedDetails({ request }: { request: RejectedDonationMatchRequest }) {
  const { t } = useTranslation()

  return (
    <DonationCardLine
      description={t('workplace_giving.matchRequest.card.rejectionReason')}
      value={request.rejectedReason}
    />
  )
}

function Contribution({ amount, orgCountryCode }: { amount: Money; orgCountryCode?: Alpha3Code }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  return (
    <DonationCardLine
      description={t('workplace_giving.pastDonation.card.contribution')}
      value={formatMoney(amount, { locale })}
      orgCountryCode={orgCountryCode}
      showTaxInfo
    />
  )
}

function Matched({ matched, complete, partnerName }: { matched: Money; complete: boolean; partnerName: string }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()

  return (
    <DonationCardLine
      complete={complete}
      description={
        complete
          ? t('workplace_giving.pastDonation.card.matched', { partnerName })
          : t('workplace_giving.matchRequest.card.requested')
      }
      value={formatMoney(matched, { locale })}
    />
  )
}

function MatchedDetails({
  request,
  partnerName,
  orgCountryCode
}: {
  request: ProcessingDonationMatchRequest | ValidatingDonationMatchRequest | ApprovedDonationMatchRequest
  partnerName: string
  orgCountryCode?: Alpha3Code
}) {
  const showMatched =
    (request.status === 'approved' && request.donation?.matchedDonation) || request.status !== 'approved'
  const isMatched = request.status === 'approved' && request.donation?.matchedDonation
  const matched = isMatched
    ? { amount: request.donation!.matchedDonation!.amount, currency: request.amount.currency }
    : request.amount

  return (
    <>
      <Contribution amount={request.amount} orgCountryCode={orgCountryCode} />
      {showMatched && <Matched matched={matched} complete={request.status === 'approved'} partnerName={partnerName} />}
    </>
  )
}

function Total({ amount, complete, description }: { amount: Money; complete: boolean; description: string }) {
  const {
    i18n: { language: locale }
  } = useTranslation()

  return <DonationCardSummary complete={complete} description={description} value={formatMoney(amount, { locale })} />
}

export function MatchRequestCard({ request }: MatchRequestCardProps) {
  const { t } = useTranslation()
  const authState = useAuth()
  const partner = getPartnerFromAuthState(authState.state)
  const badgeVariant = useMemo(() => matchBadgeVariant(request.status), [request.status])

  const { formatDate } = useDateTimeFmt()
  const createdDate = formatRelativeTime(request.createdAt, t, formatDate)

  const total =
    request.status === 'approved'
      ? {
          amount: request.amount.amount + (request.donation?.matchedDonation?.amount ?? 0),
          currency: request.amount.currency
        }
      : { amount: request.amount.amount + request.amount.amount, currency: request.amount.currency }

  return (
    <DonationCard
      organisation={{
        name: request.organisation?.name ?? request.organisationDetails?.name ?? 'Missing',
        logo: request.organisation?.logo
      }}
      badgeVariants={badgeVariant}
      date={createdDate}
    >
      {request.status === 'rejected' ? (
        <>
          <RejectedDetails request={request} />
          <DonationCardSeparator />
          <Total amount={request.amount} complete description={t('workplace_giving.pastDonation.card.contribution')} />
        </>
      ) : (
        <>
          <MatchedDetails request={request} partnerName={partner!.name} />
          <DonationCardSeparator />
          <Total
            amount={total}
            complete={request.status === 'approved'}
            description={t('workplace_giving.pastDonation.card.total')}
          />
        </>
      )}
    </DonationCard>
  )
}
