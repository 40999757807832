import { useEffect, useMemo, useState } from 'react'
import { Box, Chip, ButtonBase, useMediaQuery, Typography } from '@mui/material'
import { t } from 'i18next'
import { MultiSelect } from '@mantine/core'
import { Alpha2Code } from 'i18n-iso-countries'

import { OpportunitiesListParams } from '../OpportunitiesList'

import { Button, ButtonText, FlagAvatar, Icon, Modal, Select } from '@percent/lemonade'
import {
  OpportunityLocationType,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { useWindowDimensions } from '@percent/workplace-giving/common/hooks'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import * as Styles from './FiltersModal.styles'
import { config } from '@percent/workplace-giving/config/config'
import { LocaleKey } from '@percent/workplace-giving/i18n/config'
import countries from '@percent/workplace-giving/i18n/countries'
import { WorldFlagAvatar } from '@percent/workplace-giving/common/components/WorldFlagAvatar/WorldFlagAvatar'

type FilterType = {
  type?: OpportunityType
  locationType?: OpportunityLocationType
  country?: string
  relevantSkills?: (string | null)[]
}

type FiltersModalProps = {
  open: boolean
  handleClose: VoidFunction
  query: OpportunitiesListParams['query']
  setQuery: OpportunitiesListParams['setQuery']
  clearAllActive: boolean
}

export function FiltersModal({ open, handleClose, query, setQuery, clearAllActive }: Readonly<FiltersModalProps>) {
  const { track } = useAnalytics()
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const { width: windowWidth } = useWindowDimensions()

  const [dropdownOpened, setDropdownOpened] = useState(false)

  const [selectedFilters, setSelectedFilters] = useState<FilterType>({
    type: query.type ? (query.type as OpportunityType) : undefined,
    locationType: query.locationType ? (query.locationType as OpportunityLocationType) : undefined,
    country: query.country || undefined,
    relevantSkills: query.relevantSkills || undefined
  })

  useEffect(() => {
    setSelectedFilters({
      type: query.type ? (query.type as OpportunityType) : undefined,
      locationType: query.locationType ? (query.locationType as OpportunityLocationType) : undefined,
      country: query.country || undefined,
      relevantSkills: query.relevantSkills || undefined
    })
  }, [query])

  const opportunityTypeFilters = useMemo(() => {
    return [
      {
        label: t('workplace_giving.volunteering.filter.all'),
        value: undefined
      },
      {
        label: t('workplace_giving.volunteering.project'),
        value: OpportunityType.PROJECT
      },
      {
        label: t('workplace_giving.volunteering.event'),
        value: OpportunityType.EVENT
      }
    ]
  }, [])

  const locationTypeFilters = useMemo(() => {
    return [
      {
        label: t('workplace_giving.volunteering.filter.all'),
        value: undefined
      },
      {
        label: t('workplace_giving.volunteering.filter.inPerson'),
        value: OpportunityLocationType.OFFLINE
      },
      {
        label: t('workplace_giving.volunteering.filter.virtual'),
        value: OpportunityLocationType.VIRTUAL
      }
    ]
  }, [])

  const alpha3CountryCodes = useMemo(
    () => [
      {
        value: '',
        label: t('workplace_giving.search.world'),
        prefix: <WorldFlagAvatar />
      },
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    []
  )

  const skillsOptions = useMemo(() => {
    return config.skills
      .map(skill => ({
        value: skill.id,
        label: t(`workplace_giving.volunteering.skills.${skill.id}` as LocaleKey, {
          defaultValue: skill.name
        })
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [])

  const clearFilters = () => {
    const clearedFiltersValues = {
      locationType: undefined,
      type: undefined,
      country: undefined,
      relevantSkills: []
    }
    setSelectedFilters(clearedFiltersValues)
    setQuery(clearedFiltersValues)
  }

  const resetFiltersToValuesFromQuery = () => {
    setSelectedFilters({
      type: query.type ? (query.locationType as OpportunityType) : undefined,
      locationType: query.locationType ? (query.locationType as OpportunityLocationType) : undefined,
      country: query.country || undefined,
      relevantSkills: query.relevantSkills || undefined
    })
  }

  const handleCloseWithoutSaving = () => {
    resetFiltersToValuesFromQuery()
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseWithoutSaving}
      blur
      isFullscreen={!isDesktop}
      width={isDesktop ? 552 : windowWidth - 48}
      data-testid="volunteeringFiltersModal"
    >
      <Box sx={Styles.Wrapper}>
        <Box sx={Styles.Header}>
          <Typography sx={Styles.Title}>{t('workplace_giving.common.filters')}</Typography>
          <ButtonBase
            onClick={handleCloseWithoutSaving}
            sx={Styles.CloseBtn}
            data-testid="closeVolunteeringFiltersModal"
          >
            <Icon name="close" size={4} color="gray300" />
          </ButtonBase>
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <Typography sx={Styles.SectionTitle}>{t('workplace_giving.volunteering.filters.type')}</Typography>
            <Box display="flex" gap={1}>
              {opportunityTypeFilters.map(filter => (
                <Chip
                  data-testid={`opportunityTypeFilter-${filter.value}`}
                  label={filter.label}
                  variant="outlined"
                  sx={Styles.Chip}
                  disabled={selectedFilters.type === filter.value}
                  onClick={() => {
                    setSelectedFilters({ ...selectedFilters, type: filter.value })
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Typography sx={Styles.SectionTitle}>{t('workplace_giving.volunteering.filters.location')}</Typography>
            <Box display="flex" gap={1}>
              {locationTypeFilters.map(filter => (
                <Chip
                  data-testid={`locationTypeFilter-${filter.value}`}
                  label={filter.label}
                  variant="outlined"
                  sx={Styles.Chip}
                  disabled={selectedFilters.locationType === filter.value}
                  onClick={() => {
                    setSelectedFilters({
                      ...selectedFilters,
                      locationType: filter.value,
                      country: filter.value === OpportunityLocationType.VIRTUAL ? undefined : selectedFilters.country
                    })
                  }}
                />
              ))}
            </Box>
          </Box>
          {selectedFilters.locationType === OpportunityLocationType.OFFLINE ? (
            <Box>
              <Typography sx={Styles.SectionTitle}>{t('workplace_giving.volunteering.filters.country')}</Typography>
              <Select
                placeholder={t('workplace_giving.wizard.country.placeholder') as string}
                searchable
                defaultValue={
                  alpha3CountryCodes.find(option => option.value === selectedFilters.country) || alpha3CountryCodes[0]
                }
                onChange={event => {
                  setSelectedFilters({ ...selectedFilters, country: event.value.length ? event.value : undefined })
                }}
                options={alpha3CountryCodes}
                data-testid="countyCodeSelect"
                showPrefixForSelectedOption
              />
            </Box>
          ) : null}
          <Box>
            <Typography sx={Styles.SectionTitle}>{t('workplace_giving.volunteering.filters.skills.label')}</Typography>
            <MultiSelect
              dropdownOpened={dropdownOpened}
              comboboxProps={{ withinPortal: false }}
              wrapperProps={{ 'data-testid': 'skillsSelect' }}
              style={{ width: '100%' }}
              maxDropdownHeight={150}
              placeholder={
                !selectedFilters.relevantSkills?.length
                  ? (t('workplace_giving.volunteering.filters.skills.placeholder') as string)
                  : ''
              }
              data={skillsOptions}
              value={selectedFilters.relevantSkills as string[]}
              onChange={selectedValues => {
                setSelectedFilters({ ...selectedFilters, relevantSkills: selectedValues })
              }}
              leftSectionProps={{
                onClick: () => {
                  setDropdownOpened(!dropdownOpened)
                }
              }}
              onDropdownOpen={() => setDropdownOpened(true)}
              onDropdownClose={() => setDropdownOpened(false)}
              rightSectionPointerEvents="none"
              rightSection={
                <Box sx={Styles.DropdownRightSectionWrapper}>
                  {selectedFilters.relevantSkills?.length ? (
                    <ButtonBase
                      data-testid="clearSkillsFilter"
                      disableRipple
                      onClick={() => {
                        setSelectedFilters({ ...selectedFilters, relevantSkills: [] })
                      }}
                    >
                      <Icon name="close" size={4} color="gray600" />
                    </ButtonBase>
                  ) : null}

                  <ButtonBase
                    data-testid="toggleSkillsFilter"
                    disableRipple
                    onClick={() => {
                      setDropdownOpened(!dropdownOpened)
                    }}
                  >
                    <Icon
                      name={dropdownOpened ? 'dropdown-arrow-up' : 'dropdown-arrow-down'}
                      size={8}
                      color="gray600"
                    />
                  </ButtonBase>
                </Box>
              }
            />
          </Box>
        </Box>
        <Box sx={Styles.ButtonsWrapper}>
          {clearAllActive || Object.values(selectedFilters).find(filter => filter !== undefined)?.length ? (
            <Box marginRight="auto">
              <ButtonText type="button" onPress={clearFilters} data-testid="clearFiltersBtn">
                {t('workplace_giving.common.clearAll')}
              </ButtonText>
            </Box>
          ) : null}
          <Box>
            <Button
              type="button"
              variant="secondary"
              size={!isDesktop ? 'small' : 'medium'}
              stretch={!isDesktop}
              data-testid="cancelFiltersBtn"
              onPress={handleCloseWithoutSaving}
            >
              {t('workplace_giving.common.cancel')}
            </Button>
            <Button
              type="button"
              size={!isDesktop ? 'small' : 'medium'}
              stretch={!isDesktop}
              data-testid="applyFiltersBtn"
              onPress={() => {
                setQuery({
                  ...selectedFilters
                })
                track({
                  event: 'Volunteering Discover Filters',
                  properties: {
                    filtersValue: selectedFilters
                  }
                })
                handleClose()
              }}
            >
              {t('workplace_giving.common.showResults')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
