import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useMoneyFormat } from '../../hooks/useMoneyFormat/useMoneyFormat'
import coinIcon from '../../../assets/icons/coin.svg'

import * as Styles from './WalletBalance.styles'
import { WalletAmountProps } from './WalletBalance.types'
import { Tooltip } from '@percent/lemonade'

export function WalletBalance({ balance }: WalletAmountProps) {
  const { moneyFormat } = useMoneyFormat()
  const { t } = useTranslation()

  return (
    <Tooltip content="You can use your wallet balance to make donations" placement="top-end">
      <Box sx={Styles.WalletAmountContainer}>
        <img src={coinIcon} alt="" />
        {t('workplace_giving.myImpact.donationBadge.wallet')}
        <span data-testid="wallet-balance">{moneyFormat(balance, { hideDecimalsIfZero: true })}</span>
      </Box>
    </Tooltip>
  )
}
