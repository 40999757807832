import {
  CurrencyFlag,
  CurrencyFlagBox,
  CurrencyInputWrapper,
} from './components/currency-components'
import { CurrencyField } from './components/currency-field'
import { CurrencyFieldProps } from './components/currency-field.types'

export const CurrencyInput = (props: CurrencyFieldProps) => {
  return (
    <CurrencyInputWrapper>
      <CurrencyField {...props} />
      <CurrencyFlagBox>
        <CurrencyFlag currency={props.currency} />
      </CurrencyFlagBox>
    </CurrencyInputWrapper>
  )
}
