import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import { FeaturedOpportunityCardProps } from './FeaturedOpportunityCard.types'
import { FeaturedCard } from '@percent/domain/giving'

export function FeaturedOpportunityCard({ partner, featured, opportunity }: FeaturedOpportunityCardProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <FeaturedCard
      type="featured_opportunity"
      startDate={opportunity.startDate}
      badgeText={t('workplace_giving.featured.chosenBy', {
        partnerName: partner.name
      })}
      title={opportunity.name}
      description={featured.description}
      descriptionText={t('workplace_giving.volunteering.youWillBeHelping')}
      actionButton={() => {
        navigate(`/volunteer/opportunities/${opportunity.id}`)
      }}
      buttonText={t('workplace_giving.volunteering.viewDetails')}
      organisationName={opportunity.organisation?.name}
      countryCode={featured.country ?? opportunity.organisation?.countryCode}
      imgSrc={opportunity.image}
      timeTracking={opportunity.timeTracking}
    />
  )
}
