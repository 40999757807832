import {
  createMoney,
  formatMoney,
  toMajorUnits,
  toMinorUnits,
} from '@percent/utility'
import { useEffect, useState } from 'react'
import ReactCurrencyInputField from 'react-currency-input-field'
import styled from 'styled-components'
import { CurrencyFieldProps } from './currency-field.types'

const CurrencyInputBox = styled.div<Pick<CurrencyFieldProps, 'status'>>`
  ${({ theme, status = 'default' }) =>
    `
      display: inline-flex;
      position: relative;
      width: 100%;

      input {
        box-sizing: border-box;
        padding: ${theme.sizes.xs} ${theme.sizes.s};
        width: 100%;
        line-height: 24px;
        font-size: 14px;
        outline: none;
        color: ${theme.colors.black};

        &::placeholder {
          color: ${theme.colors.gray600};
        }

        border-radius: 0.25rem;

        ${
          status === 'danger'
            ? `
          border: 0.0625rem solid ${theme.colors.alert400};

          &:hover {
            outline: ${theme.sizes[1]} solid ${theme.colors.alert100};
          }
          &:focus, &:active {
            border-color: ${theme.colors.alert400};
          }
        `
            : `
          border: 0.0625rem solid ${theme.colors.gray80};

          &:hover {
            border-color: ${theme.colors.black};
            outline: ${theme.sizes[1]} solid ${theme.colors.gray80};
          }
          &:focus, &:active {
            border-color: ${theme.colors.black};;
          }
        `
        }

        &:disabled {
          background-color: ${theme.colors.gray80};

          &:hover {
            outline: none;
            border: 0.0625rem solid ${theme.colors.gray80};
          }
        }

       }
    `}
`

export const CurrencyField = ({
  status,
  currency,
  locale,
  value,
  onValueChange,
  placeholder,
  ...InputFieldProps
}: CurrencyFieldProps) => {
  const [actualValue, setActualValue] = useState<string | number | undefined>(
    undefined
  )

  useEffect(() => {
    setActualValue(value && toMajorUnits(value.amount, value.currency))
  }, [value])

  useEffect(() => {
    if (value) {
      onValueChange?.(
        createMoney(toMajorUnits(value.amount, value.currency), currency),
        undefined
      )
    }
  }, [currency])

  return (
    <CurrencyInputBox status={status}>
      <ReactCurrencyInputField
        value={actualValue}
        allowDecimals={true}
        placeholder={
          placeholder
            ? formatMoney({ amount: placeholder.amount, currency }, { locale })
            : undefined
        }
        onValueChange={(raw, __, parsed) => {
          if (parsed?.float !== null && parsed?.float !== undefined) {
            const updated = createMoney(parsed?.float, currency)
            if (Math.abs(updated.amount) > Number.MAX_SAFE_INTEGER) return
            setActualValue(raw)
            if (updated.amount !== value?.amount) onValueChange?.(updated, raw)
            return
          }

          const asFloat = parseFloat(raw ?? '')

          if (isNaN(asFloat)) {
            setActualValue(raw)
            onValueChange?.(undefined, raw)
          } else {
            const asMinor = toMinorUnits(asFloat, currency)

            if (Math.abs(asMinor) > Number.MAX_SAFE_INTEGER) return
            setActualValue(raw)
            onValueChange?.(createMoney(asFloat, currency), raw)
          }
        }}
        disableAbbreviations
        intlConfig={{
          locale: currency === 'USD' && locale === 'en-GB' ? 'en-US' : locale,
          currency,
        }}
        {...InputFieldProps}
      />
    </CurrencyInputBox>
  )
}
