import { Box } from '@mui/material'

import { Badge } from '@percent/lemonade'
import * as Styles from './Tags.styles'

export function Tags({ tags, highlightFirstTag }: Readonly<{ tags?: string[]; highlightFirstTag?: boolean }>) {
  return (
    <Box data-testid="description-tags" sx={Styles.TagsWrapper}>
      {tags?.map((tag, i) => (
        <Badge key={tag} variant={i === 0 && highlightFirstTag ? 'informative' : 'default'}>
          {tag}
        </Badge>
      ))}
    </Box>
  )
}
