import { FocusEvent, FormEvent, useEffect, useMemo, useState } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import { Box, Typography, useMediaQuery, Button as RawButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Alpha2Code } from 'i18n-iso-countries'
import { NumberInput } from '@mantine/core'

import * as Styles from '../CreateOpportunity.styles'

import * as EventFormStyles from './SessionEvent.styles'
import { FormField, TextInput, Select, FlagAvatar, Modal, Icon, Button } from '@percent/lemonade'
import { DateTimePicker } from '@percent/workplace-giving/common/components'
import { Address, AddressLookup } from '@percent/workplace-giving/common/components/AddressLookup/AddressLookup'
import { OpportunityLocationType } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import countries from '@percent/workplace-giving/i18n/countries'
import { SessionEventModalProps } from './CreateSessionEvent'
import { useWindowDimensions } from '@percent/workplace-giving/common/hooks'

export interface SessionEventFormFields {
  startDate?: Date
  endDate?: Date
  locationType: string
  locationUrl: string
  addressLineOne: string
  addressLineTwo: string
  city: string
  zipCode: string
  country: string
  participantSpots?: number
  long: number
  lat: number
}

type SessionEventFormProps = Omit<SessionEventModalProps, 'onSubmit'> & {
  onSubmit: VoidFunction
  isLoading: boolean
  values: SessionEventFormFields
  touched: FormikTouched<SessionEventFormFields>
  errors: FormikErrors<SessionEventFormFields>
  handleChange: (e: FormEvent<HTMLInputElement>) => void
  handleBlur: <A = HTMLInputElement>(e: FocusEvent<A>) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<SessionEventFormFields>>
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<SessionEventFormFields>>
  // sessionEventData?: SessionEvent
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  isValid: boolean
  isEditFlow: boolean
  isEditMode?: boolean
}

export function SessionEventForm({
  isEditFlow,
  isEditMode,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isValid,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  validateField
}: Readonly<SessionEventFormProps>) {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const { width: windowWidth } = useWindowDimensions()

  const alpha3CountryCodes = useMemo(
    () => [
      ...Object.keys(countries.getAlpha3Codes()).map(a => ({
        value: a,
        label: countries.getName(a, 'en'),
        prefix: <FlagAvatar code={countries.alpha3ToAlpha2(a) as Alpha2Code} />
      }))
    ],
    []
  )

  const defaultCountryValue = alpha3CountryCodes.filter(a => a.value === values.country)[0]

  const eventLocationTypeOptions = useMemo(
    () => [
      {
        value: OpportunityLocationType.VIRTUAL,
        label: t('workplace_giving.wizard.opportunityType.virtual')
      },
      {
        value: OpportunityLocationType.OFFLINE,
        label: t('workplace_giving.wizard.opportunityType.inPerson')
      }
    ],
    [t]
  )

  const [addressState, setAddressState] = useState<Address>({
    addressLineOne: values.addressLineOne,
    addressLineTwo: values.addressLineTwo,
    city: values.city,
    zipCode: values.zipCode,
    country: values.country
  })

  const addressFound = useMemo(
    () => Object.values(addressState).filter(a => a !== undefined).length > 0,
    [addressState]
  )

  useEffect(() => {
    if (isOpen && !isEditMode) {
      setAddressState({
        addressLineOne: undefined,
        addressLineTwo: undefined,
        city: undefined,
        zipCode: undefined,
        country: undefined
      })
    }
  }, [isOpen, isEditMode])

  return (
    <Modal open={isOpen} onClose={onClose} isFullscreen={!isDesktop} width={isDesktop ? 504 : windowWidth}>
      <Box sx={EventFormStyles.Container}>
        <Box sx={EventFormStyles.ContentWrapper}>
          <Box sx={EventFormStyles.Header}>
            <Typography id="donation-modal-title" variant="h2" sx={EventFormStyles.Title}>
              {t(`workplace_giving.wizard.${isEditMode ? 'editSession' : 'addNewSession'}`)}
            </Typography>
            <RawButton
              sx={EventFormStyles.ExitButton}
              type="button"
              aria-label="Close"
              onClick={onClose}
              disableFocusRipple
            >
              <Icon name="close" size={4} color="gray600" />
            </RawButton>
          </Box>
          <Box component="div" sx={Styles.Form}>
            <FormField
              label={t('workplace_giving.wizard.startDate')}
              necessity="required"
              status={touched.startDate && errors.startDate ? 'danger' : 'default'}
              statusMessage={errors.startDate as string}
              data-testid="sessionStartDateField"
            >
              <DateTimePicker
                withinPopup
                fieldName="startDate"
                value={values.startDate}
                minDate={dayjs().add(1, 'minutes').toDate()}
                handleBlur={handleBlur}
                handleChange={async date => {
                  setFieldValue('startDate', date)
                  setFieldTouched('startDate')
                }}
                error={!!errors.startDate && !!touched.startDate}
              />
            </FormField>
            <FormField
              label={t('workplace_giving.wizard.endDate')}
              necessity="required"
              status={touched.endDate && errors.endDate ? 'danger' : 'default'}
              statusMessage={errors.endDate}
              data-testid="sessionEndDateField"
            >
              <DateTimePicker
                withinPopup
                fieldName="endDate"
                value={values.endDate}
                minDate={dayjs(values.startDate || dayjs())
                  .add(5, 'minute')
                  .toDate()}
                handleBlur={handleBlur}
                handleChange={async date => {
                  setFieldValue('endDate', date)
                  setFieldTouched('endDate')
                }}
                error={!!errors.endDate && !!touched.endDate}
              />
            </FormField>

            <FormField
              necessity="required"
              label={t('workplace_giving.wizard.location')}
              status={touched.locationType && errors.locationType ? 'danger' : 'default'}
              statusMessage={errors.locationType}
              data-testid="eventTypeField"
              disabled={isEditFlow && isEditMode}
            >
              <Select
                defaultValue={eventLocationTypeOptions.find(x => x.value === values.locationType)}
                status={errors.locationType ? 'danger' : 'default'}
                placeholder={t('workplace_giving.wizard.opportunityType.placeholder')}
                options={eventLocationTypeOptions}
                onChange={event => setFieldValue('locationType', event.value)}
                disabled={isEditFlow && isEditMode}
              />
            </FormField>

            {values.locationType === OpportunityLocationType.VIRTUAL && (
              <FormField
                label={t('workplace_giving.wizard.addVideoConferencing.label')}
                necessity="optional"
                status={touched.locationUrl && errors.locationUrl ? 'danger' : 'default'}
                statusMessage={errors.locationUrl}
                data-testid="locationUrlField"
              >
                <TextInput
                  name="locationUrl"
                  value={values.locationUrl}
                  placeholder={t('workplace_giving.wizard.addVideoConferencing.placeholder')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormField>
            )}
            {values.locationType === OpportunityLocationType.OFFLINE && (
              <>
                <FormField label="Lookup location">
                  <AddressLookup
                    onSelect={address => {
                      const requiredFields = ['addressLineOne', 'city', 'country', 'zipCode']
                      setAddressState(address)
                      setFieldValue('addressLineOne', address.addressLineOne ?? '')
                      setFieldValue('addressLineTwo', address.addressLineTwo ?? '')
                      setFieldValue('city', address.city ?? '')

                      if (address.country) {
                        const countryMatch = alpha3CountryCodes.find(a => a.label === address.country)

                        if (countryMatch) {
                          setFieldValue('country', countryMatch.value)
                        }
                      }

                      setFieldValue('zipCode', address.zipCode ?? '')

                      if (address.lat) {
                        setFieldValue('lat', address.lat)
                      }

                      if (address.long) {
                        setFieldValue('long', address.long)
                      }
                      requiredFields.forEach(field => setFieldTouched(field, true))
                      setTimeout(async () => {
                        await Promise.all(['addressLineOne', 'city', 'country', 'zipCode'].map(a => validateField(a)))
                      }, 1)
                    }}
                  />
                </FormField>
                {addressFound && (
                  <>
                    <FormField
                      label={t('workplace_giving.wizard.streetAddress.label')}
                      necessity="required"
                      status={touched.addressLineOne && errors.addressLineOne ? 'danger' : 'default'}
                      statusMessage={errors.addressLineOne}
                      data-testid="addressLineOneField"
                    >
                      <TextInput
                        name="addressLineOne"
                        value={values.addressLineOne}
                        placeholder={t('workplace_giving.wizard.streetAddress.placeholder')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField
                      label={t('workplace_giving.wizard.apartment.label')}
                      necessity="optional"
                      status={touched.addressLineTwo && errors.addressLineTwo ? 'danger' : 'default'}
                      statusMessage={errors.addressLineTwo}
                      data-testid="addressLineTwoField"
                    >
                      <TextInput
                        name="addressLineTwo"
                        value={values.addressLineTwo}
                        placeholder={t('workplace_giving.wizard.apartment.placeholder')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                    <Box sx={Styles.AddressLinesWrapper}>
                      <FormField
                        label={t('workplace_giving.wizard.city.label')}
                        necessity="required"
                        status={touched.city && errors.city ? 'danger' : 'default'}
                        statusMessage={errors.city}
                        data-testid="cityField"
                        disabled={!!addressState.city}
                      >
                        <TextInput
                          name="city"
                          value={values.city}
                          placeholder={t('workplace_giving.wizard.city.placeholder')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </FormField>
                      <FormField
                        label={t('workplace_giving.wizard.zipOrPostalCode.label')}
                        necessity="required"
                        status={touched.zipCode && errors.zipCode ? 'danger' : 'default'}
                        statusMessage={errors.zipCode}
                        data-testid="zipCodeField"
                        disabled={!!addressState.zipCode}
                      >
                        <TextInput
                          name="zipCode"
                          value={values.zipCode}
                          placeholder={t('workplace_giving.wizard.zipOrPostalCode.placeholder')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </FormField>
                    </Box>
                    <FormField
                      label={t('workplace_giving.wizard.country.label')}
                      necessity="required"
                      status={touched.country && errors.country ? 'danger' : 'default'}
                      statusMessage={errors.country}
                      data-testid="countryField"
                      disabled={!!addressState.country}
                    >
                      <Select
                        placeholder={t('workplace_giving.wizard.country.placeholder')}
                        status={errors.country ? 'danger' : 'default'}
                        options={alpha3CountryCodes}
                        defaultValue={defaultCountryValue}
                        onChange={event => {
                          setFieldValue('country', event.value)
                        }}
                        searchable
                      />
                    </FormField>
                  </>
                )}
              </>
            )}
            <FormField
              label={t('workplace_giving.wizard.maxAttendees.label')}
              necessity="optional"
              status={touched.participantSpots && errors.participantSpots ? 'danger' : 'default'}
              statusMessage={errors.participantSpots}
              data-testid="sessionParticipantSpotsField"
            >
              <NumberInput
                style={{ width: '100%' }}
                name="participantSpots"
                min={1}
                step={1}
                hideControls
                placeholder={t('workplace_giving.wizard.maxAttendees.placeholder')}
                value={values.participantSpots}
                onBlur={handleBlur}
                onChange={value => {
                  setFieldValue('participantSpots', value)
                }}
                error={!!errors.participantSpots && !!touched.participantSpots}
              />
            </FormField>

            <Box sx={EventFormStyles.SubmitButtonWrapper}>
              <Button
                variant="secondary"
                disabled={isLoading}
                data-testid="cancelAddingSeriesEvent"
                size="medium"
                type="button"
                onPress={() => onClose()}
              >
                {t('workplace_giving.common.cancel')}
              </Button>
              <Button
                disabled={!isValid || isLoading}
                loading={isLoading}
                data-testid="confirmAddingOrChangingSeriesEvent"
                size="medium"
                type="button"
                onPress={() => onSubmit()}
              >
                {t(isEditFlow ? 'workplace_giving.common.publish' : 'workplace_giving.common.save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
