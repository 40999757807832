import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { dayjs } from '../../../utils/dayjs/dayjs'
import { useAuth } from '../useAuth/useAuth'

import { getTimeZoneFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'

const date = new Date()
const getTimeZoneName = ({ timeZone, locale }: { timeZone: string; locale: string }) =>
  new Intl.DateTimeFormat(locale ?? 'en-US', {
    timeZone,
    timeZoneName: 'short'
  })
    .format(date)
    .split(' ')[1] ?? ''

export const useDateTimeFmt = () => {
  const auth = useAuth()
  const timeZone = getTimeZoneFromAuthState(auth.state)
  const {
    i18n: { language: locale }
  } = useTranslation()

  const formatTime = useCallback(
    (d: Date) => {
      return `${dayjs(d)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LT')} ${getTimeZoneName({ timeZone: timeZone ?? 'UTC', locale })}`
    },
    [timeZone, locale]
  )

  const formatDateWODay = useCallback(
    (d: Date) => {
      return dayjs(d)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LL')
    },
    [timeZone, locale]
  )
  const formatDate = useCallback(
    (d: Date) => {
      return dayjs(d)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LLLL')
        .replace(
          dayjs(d)
            .tz(timeZone ?? undefined)
            .locale(locale)
            .format('LT'),
          ''
        )
    },
    [timeZone, locale]
  )
  const formatDateTime = useCallback(
    (d: Date) => {
      return `${dayjs(d)
        .tz(timeZone ?? undefined)
        .format('LLLL')} ${getTimeZoneName({ timeZone: timeZone ?? 'UTC', locale })}`
    },
    [timeZone, locale]
  )

  const formatDurationDateTime = useCallback(
    (startDate: Date, endDate?: Date) => {
      if (!endDate) {
        return formatTime(startDate)
      }

      const startTime = dayjs(startDate)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LT')

      if (dayjs(startDate).isSame(endDate, 'day')) {
        const endTime = formatTime(endDate)

        return `${startTime} - ${endTime}`
      }

      return `${startTime} - ${dayjs(endDate)
        .tz(timeZone ?? undefined)
        .format('DD MMMM')}, ${formatTime(endDate)}`
    },
    [formatTime, locale, timeZone]
  )

  return {
    formatTime,
    formatDate,
    formatDateTime,
    formatDateWODay,
    formatDurationDateTime
  }
}

export type DateTimeFmtMethods = ReturnType<typeof useDateTimeFmt>
