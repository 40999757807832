import { FundraiserDonationList } from '../getFundraiser/getFundraiser.types'
import { ListResponse } from '../../goodstack'

import { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { Donation } from 'libs/domain/fundraiser/src/components/recent-donations-list/recent-donations-list.types'

export const getFundraiserDonationList: InfiniteQueryFn<
  [string, FundraiserDonationList],
  string,
  ListResponse<Donation>
> = async (client, [_, { pageSize = 10, direction = 'DESC', id }], cursor) =>
  (
    await (cursor
      ? client.get<ListResponse<Donation>>(cursor)
      : client.get<ListResponse<Donation>>(`/fundraisers/${id}/donations`, {
          params: {
            pageSize,
            direction
          }
        }))
  ).data
