import { Axios } from 'axios'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'
import { Opportunity } from '../../search/searchOpportunities/searchOpportunities.types'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CreateProjectParams } from './create-opportunity.types'

export const createProject: MutationFn<[CreateProjectParams], GsResponse<Opportunity>> = async (
  client: Axios,
  [params]
) => {
  const serialized = {
    type: params.type,
    name: params.name,
    description: params.description,
    organisationId: params.organisationId,
    location: JSON.stringify(params.location),
    startDate: params.startDate?.toUTCString(),
    endDate: params.endDate?.toUTCString(),
    participantSpots: params.participantSpots,
    skills: params.skills.length ? JSON.stringify(params.skills) : '[]',
    activities: params.activities.length ? JSON.stringify(params.activities) : '[]',
    image: params.image
  }

  const data = Object.entries(serialized).reduce<FormData>((acc, [key, value]) => {
    if (typeof value === 'string') {
      if (value.length) acc.append(key, value)
    } else if (typeof value === 'number') acc.append(key, value.toString())
    else if (value !== undefined && value !== null) acc.append(key, value)

    return acc
  }, new FormData())

  const requestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true,
    validateStatus: (_: number) => true
  }

  const response = await client.post<RawResponse<Opportunity>>('/opportunities', data, requestConfig)

  return toGsResponse(response.status, response.data)
}
