import { customPalette } from '@percent/workplace-giving/theme/theme'
import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Wrapper: Styles = {
  paddingY: '24px',
  background:
    ' radial-gradient(43.49% 51.92% at 88.61% 88.8%, rgba(1, 158, 255, 0.10) 0%, rgba(1, 158, 255, 0.00) 100%), radial-gradient(87.11% 94.04% at 83.75% 31.88%, rgba(139, 61, 255, 0.10) 0%, rgba(139, 61, 255, 0.00) 100%), #FFF',
  borderBottom: `1px solid ${customPalette.gray90}`,
  overflow: 'hidden',
  borderRadius: '16px 16px 0 0'
}

export const TextLine: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: '24px'
}
