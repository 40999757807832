import { GsResponse, toGsResponse } from '../../goodstack'

import {
  CancelOpportunityProps,
  CancelOpportunityResponse
} from '@percent/workplace-giving/api/opportunity/cancel/cancel-opportunity.types'
import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'

export const cancelOpportunity: MutationFn<CancelOpportunityProps, GsResponse<CancelOpportunityResponse>> = async (
  client,
  { id }
) => {
  return client
    .post(
      `/opportunities/${id}/cancel`,
      {},
      {
        withCredentials: true,
        validateStatus: (_: number) => true
      }
    )
    .then(res => toGsResponse(res.status, res.data))
}
