import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Table: Styles = {
  border: `1px solid ${customPalette.gray80}`,
  borderRadius: '4px'
}

export const TableRow: Styles = {
  th: {
    borderBottom: `1px solid ${customPalette.gray80}`
  }
}

export const TableRowHeader: Styles = {
  th: {
    borderBottom: `1px solid ${customPalette.gray80}`
  }
}

export const TableFooter: Styles = {
  borderTop: `1px solid ${customPalette.gray80}`
}

export const Chip: Styles = {
  color: customPalette.gray600,

  '&.Mui-disabled': {
    opacity: 1,
    fontWeight: 500,
    color: customPalette.black,
    backgroundColor: customPalette.gray70
  }
}

export const LocationLabelWrapper: Styles = {
  maxHeight: '24px !important',
  width: 'fit-content',
  padding: '4px 10px',
  backgroundColor: customPalette.gray90,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',

  svg: {
    height: '20px',
    width: '20px'
  }
}
