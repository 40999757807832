import { Button, Tooltip } from '@percent/lemonade'
import { CopyContentButtonProps } from './copy-content-button.types'
import { useCallback } from 'react'

export const CopyContentButton = ({
  content,
  children = 'Copy',
  onPress,
  tooltipLabel = 'Copied!',
  ...props
}: CopyContentButtonProps) => {
  const copyToClipboard = useCallback<
    Required<CopyContentButtonProps>['onPress']
  >(
    async (event) => {
      try {
        await navigator.clipboard.writeText(content)
        onPress?.(event)
      } catch (e) {
        console.error(e)
      }
    },
    [content]
  )

  return (
    <Tooltip content={tooltipLabel} trigger="click" autoClose={3000}>
      <Button variant="secondary" onPress={copyToClipboard} {...props}>
        {children}
      </Button>
    </Tooltip>
  )
}
