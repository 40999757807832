import { Organisation } from '../../search/searchOrganisations/searchOrganisations.types'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

export const getOrganisation: QueryFn<[string, string], Organisation> = async (client, [_, id]) => {
  const { data } = await client.get<{ data: Organisation }>(`/organisations/${id}`, {
    withCredentials: true
  })

  return data.data
}

export const getOrganisations: QueryFn<[string, string[]], Organisation[]> = async (client, [_, organisationsIds]) =>
  Promise.all(organisationsIds.map(async id => getOrganisation(client, [`getOrganisation`, id])))
