import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'
import { hexToRgba } from '@percent/workplace-giving/utils/hexToRgba'
import { customBreakpoints } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const Wrapper =
  (scrolled: boolean, greetingHeight: number): Styles =>
  theme => ({
    boxSizing: 'border-box',
    position: 'fixed',
    zIndex: 5,
    top: scrolled ? `${80 - greetingHeight - 52}px` : '80px',
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '52px',
    paddingBottom: scrolled ? '22px' : '60px',
    background: `radial-gradient(87.11% 94.04% at 83.75% 31.88%, ${hexToRgba(
      theme.palette.clientColor,
      '0.1'
    )} 0%, ${hexToRgba(theme.palette.clientColor, '0')} 100%), #F8F6F2`,
    transition: 'padding 0.4s linear, top 0.4s linear',

    [theme.breakpoints.down('md')]: {
      top: scrolled ? `${80 - greetingHeight - 76}px` : '80px',
      paddingTop: '28px',
      paddingBottom: scrolled ? '22px' : '76px'
    }
  })

export const ContentWrapper: Styles = theme => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  paddingX: '64px',
  maxWidth: 'calc(1440px + 128px)',
  marginX: 'auto',

  [theme.breakpoints.down('md')]: {
    paddingX: '24px'
  }
})

export const Greeting = (scrolled: boolean): Styles => ({
  whiteSpace: 'pre-wrap',
  color: customPalette.black,
  transform: scrolled ? `translateY(-100%)` : 'translateY(0)',
  opacity: scrolled ? 0 : 1,
  transition: 'transform 0.4s linear, opacity 0.4s linear'
})

export const SearchBoxWrapper: Styles = theme => ({
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  width: '60%',

  '& > button': {
    marginLeft: '8px'
  },

  [theme.breakpoints.between('md', customBreakpoints.xl)]: {
    width: '100%'
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    width: '100%'
  }
})

export const SearchInput: Styles = theme => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    marginTop: '8px'
  }
})

export const SearchSelect =
  (scrolled: boolean): Styles =>
  theme => ({
    width: 200,
    marginLeft: 1,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
      transform: scrolled ? `translateY(-100%)` : 'translateY(0)',
      opacity: scrolled ? 0 : 1,
      transition: 'transform 0.4s linear, opacity 0.4s linear'
    }
  })
