import { theme as lemonadeTheme } from '@percent/lemonade'
import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const PlusButton: Styles = {
  display: 'inline-flex',
  backgroundColor: customPalette.primary,
  width: lemonadeTheme.sizes[8],
  height: lemonadeTheme.sizes[8],
  minWidth: lemonadeTheme.sizes[8],
  minHeight: lemonadeTheme.sizes[8],
  borderRadius: '100%',
  marginRight: '18px',

  '&:hover, &:active': {
    backgroundColor: customPalette.primary600
  }
}
