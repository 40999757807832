import { Organisation } from '../searchOrganisations/searchOrganisations.types'

export enum OpportunityStatus {
  ACTIVE = 'active',
  CLOSED = 'closed',
  CANCELLED = 'cancelled'
}

export enum OpportunityType {
  PROJECT = 'project',
  EVENT = 'event',
  EVENT_SERIES = 'event_series',
  EVENT_SERIES_SESSION = 'event_series_session'
}

export enum OpportunityLocationType {
  VIRTUAL = 'virtual',
  OFFLINE = 'in-person'
}

export type SearchOpportunitiesQuery = {
  type?: string | null
  locationType?: string | null
  country?: string | null
  city?: string | null
  relevantSkills?: (string | null)[] | null
  query?: string | null
  pageSize?: number
  direction?: 'ASC' | 'DESC'
}

export type OnlineLocation = {
  type: OpportunityLocationType.VIRTUAL
  link: string | null
}

export type OfflineLocation = {
  type: OpportunityLocationType.OFFLINE
  addressLineOne: string
  addressLineTwo?: string
  city: string
  zipCode: string
  country: string
  long: number
  lat: number
}

export type OpportunityLocationDetails = OnlineLocation | OfflineLocation

export type OpportunityTimeTracking = 'automatic' | 'manual'

export type ItineraryItem = {
  id: number
  durationMinutes: number
  title: string
  description: string | null
}

export type ChildSessionEvent = {
  id: string
  startDate: Date
  endDate: Date
  location: OpportunityLocationDetails
  participantSpots?: number
}

export type Opportunity = {
  id: string
  status: OpportunityStatus
  name: string
  description: string
  image: string
  location: OpportunityLocationDetails
  type: OpportunityType
  startDate?: string
  endDate?: string
  organisation?: Organisation
  participants: {
    spots?: number
    count: number
  }
  organiser?: {
    id: string
    fullName: string
    email: string
    image: string | null
  }
  skills?: {
    id: string
    name: string
  }[]
  activities?: {
    id: string
    name: string
  }[]
  createdAt: string
  participation: {
    status: 'participating' | 'awaiting_approval' | 'not_participating' | 'registered'
  }
  participationStatus: 'participating' | 'awaiting_approval' | 'not_participating' | 'registered'
  spots?: number | null
  participantsCount?: number | null
  timeTracking: OpportunityTimeTracking
  itinerary: ItineraryItem[] | null
  external: {
    provider: string
  } | null
  isSeries?: boolean
  parentId: string | null
}
