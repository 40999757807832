import { QueryListFunction } from '@percent/workplace-giving/api/goodstack'
import { GetAccountFundraisersQuery, GetAccountFundraisersResponse } from './getAccountFundraisers.types'

export const getAccountFundraisers: QueryListFunction<
  string,
  GetAccountFundraisersQuery,
  GetAccountFundraisersResponse
> = async (client, [_, query]) => {
  const res = await client.get<GetAccountFundraisersResponse>(`/account/fundraisers`, {
    params: {
      status: query?.status,
      direction: query?.direction,
      pageSize: query?.pageSize,
      ...(query?.cursor && { cursor: query.cursor })
    },
    withCredentials: true
  })

  return res.data
}
