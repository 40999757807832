import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'
import { FormBasicStyles } from '@percent/workplace-giving/app/Volunteer/OpportunityForm/CreateOpportunity.styles'

export const Wrapper: Styles = {
  ...FormBasicStyles,
  display: 'flex',
  flexFlow: 'column',
  gap: lemonadeTheme.sizes[4],
  boxSizing: 'border-box',
  overflowY: 'auto',
  height: '100vh',
  padding: `${lemonadeTheme.sizes[4]} ${lemonadeTheme.sizes[6]} ${lemonadeTheme.sizes[6]}`,

  '.mantine-Input-input': {
    paddingLeft: lemonadeTheme.sizes[4],
    paddingRight: lemonadeTheme.sizes[16]
  },

  '@media (min-width: 768px)': {
    height: 'auto',
    maxHeight: '92vh'
  }
}

export const Header: Styles = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: lemonadeTheme.sizes[3],
  paddingBottom: lemonadeTheme.sizes[4],

  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    background: lemonadeTheme.colors.gray80,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto'
  }
}

export const Title: Styles = {
  fontSize: lemonadeTheme.sizes[5],
  lineHeight: lemonadeTheme.sizes[6],
  fontWeight: lemonadeTheme.fontWeights.semiBold
}

export const CloseBtn: Styles = {
  '&:focus': {
    outline: `1px solid ${customPalette.primary400}`
  }
}

export const SectionTitle: Styles = {
  marginBottom: lemonadeTheme.sizes[2],
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  fontWeight: lemonadeTheme.fontWeights.medium,
  lineHeight: lemonadeTheme.sizes[6]
}

export const Chip: Styles = {
  color: customPalette.gray600,

  '&.Mui-disabled': {
    opacity: 1,
    fontWeight: 500,
    color: customPalette.black,
    backgroundColor: customPalette.gray70
  }
}

export const ButtonsWrapper: Styles = {
  display: 'flex',
  flexFlow: 'column',
  flexWrap: 'nowrap',
  gap: lemonadeTheme.sizes[4],
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'auto',

  '& > div': {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: lemonadeTheme.sizes[4],
    width: '100%',

    '@media (min-width: 450px)': {
      width: 'auto'
    }
  },

  '@media (min-width: 450px)': {
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}

export const DropdownRightSectionWrapper: Styles = {
  display: 'flex',
  gap: lemonadeTheme.sizes[1],

  '& > button': {
    minWidth: lemonadeTheme.sizes[6],
    cursor: 'pointer',
    pointerEvents: 'all'
  }
}
