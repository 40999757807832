import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Alpha3Code } from 'i18n-iso-countries'

import { useAuth, useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { OrganisationCard } from '@percent/domain/giving'
import { theme } from '@percent/workplace-giving/theme/theme'
import { SearchResultCardSkeleton } from '@percent/workplace-giving/common/components/SearchResultCardSkeleton/SearchResultCardSkeleton'
import { formatMoney } from '@percent/utility'
import { ReactComponent as ColleaguesIcon } from '@percent/workplace-giving/assets/icons/colleagues.svg'
import * as Styles from './SupportedByColleagues.styles'
import { useDonationForm } from '@percent/workplace-giving/common/hooks/useDonationForm/useDonationForm'
import { getTrending } from '@percent/workplace-giving/api/trending/trending'
import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { TooltipTaxInformation } from '@percent/workplace-giving/common/components/TooltipTaxInformation/TooltipTaxInformation'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

export function SupportedByColleagues() {
  const { t, i18n } = useTranslation()
  const { logError } = useLogger()
  const { track } = useAnalytics()

  const { state } = useAuth()
  const partner = getPartnerFromAuthState(state)!
  const accountConfig = getAccountConfigFromAuthState(state)

  const { handleDonate, DonationFormWrapper } = useDonationForm()

  const summaryDate = dayjs()
  const startOfYear = summaryDate.startOf('year').toISOString()
  const endOfYear = summaryDate.endOf('year').toISOString()

  const trendingParams = {
    startDate: startOfYear,
    endDate: endOfYear,
    currencyCode: accountConfig?.currency ?? partner.currencyCode
  }

  const {
    data: trending,
    isLoading,
    error
  } = useQuery(['getTrending', trendingParams], getTrending, {
    staleTime: 1000 * 60
  })

  if (error) {
    logError(error)

    return null
  }

  if (!isLoading && !trending?.length) {
    return null
  }

  return (
    <Box data-testid="supported-by-colleagues">
      <Typography sx={Styles.Title}>{t('workplace_giving.supportedByColleagues.title')}</Typography>
      {isLoading ? (
        <Box display="flex" flexDirection="column" gap={3} data-testid="skeleton-loader">
          <SearchResultCardSkeleton supportedByColleaguesVariant />
          <SearchResultCardSkeleton supportedByColleaguesVariant />
          <SearchResultCardSkeleton supportedByColleaguesVariant />
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={3}>
            {trending?.map(({ organisation }) => {
              const supportedByColleaguesInfo = trending.find(
                item => item.organisationId === organisation.id
              )?.totalDonated

              return (
                <OrganisationCard
                  key={organisation.id}
                  id={organisation.id}
                  title={organisation.displayName ?? organisation.name}
                  countryCode={organisation.countryCode}
                  iconUrl={organisation.logo ?? undefined}
                  description={organisation.description}
                  website={organisation.website}
                  onDonateClicked={() => {
                    track({
                      event: 'Donation Organisation Donation Start',
                      properties: {
                        origin: 'Supported by Colleagues',
                        organisationCountryCode: organisation.countryCode
                      }
                    })
                    handleDonate(organisation.id)
                  }}
                  buttonText={t('workplace_giving.common.donate')}
                  moreText={t('workplace_giving.common.more')}
                  lessText={t('workplace_giving.common.less')}
                  registryId={
                    organisation?.registryId
                      ? t('workplace_giving.common.registryId', { registryId: organisation?.registryId })
                      : undefined
                  }
                  cardFooterArea={
                    supportedByColleaguesInfo && (
                      <Box sx={Styles.SupportedByColleaguesInfo}>
                        <ColleaguesIcon />
                        <Trans
                          i18n={i18n}
                          i18nKey="workplace_giving.supportedByColleagues.colleaguesDonated"
                          components={{
                            1: <span data-testid="donated-amount" />
                          }}
                          tOptions={{
                            amount: formatMoney(supportedByColleaguesInfo, {
                              locale: i18n.language,
                              hideDecimalsIfZero: true
                            }),
                            year: summaryDate.year()
                          }}
                        />
                      </Box>
                    )
                  }
                  cardButtonInfo={<TooltipTaxInformation orgCountryCode={organisation.countryCode as Alpha3Code} />}
                />
              )
            })}
          </Box>

          <DonationFormWrapper
            params={{
              primaryColor: theme.palette.clientColor
            }}
          />
        </>
      )}
    </Box>
  )
}
