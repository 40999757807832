import { RawResponse, isValidResponse } from '../../goodstack'

import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { ConvertCurrencyParams, ConvertCurrencyResponse } from './convert.types'

export const convertCurrency: QueryFn<[string, ConvertCurrencyParams], ConvertCurrencyResponse | undefined> = async (
  client,
  [_, params]
) => {
  const response = await client.get<RawResponse<ConvertCurrencyResponse>>('/exchange-rates/convert', {
    params,
    validateStatus: status => [200, 404].includes(status)
  })

  if (isValidResponse(response.status, response.data)) return response.data.data

  return undefined
}
