import { ListResponse, QueryListFunction } from '../../goodstack'
import { retrieveDonationList } from '../../donation/retrieve-donations/retrieve-donation-list'

import { ApiDonationMatchRequest, DonationMatchRequest, SearchQuery } from './retrieve-request-list.types'
import { CurrencyCode } from '@percent/utility'

const notNull = <T>(v: T | undefined | null): v is T => v !== undefined && v !== null

export const retrieveRequestList: QueryListFunction<
  string,
  SearchQuery & { currencyCode: CurrencyCode; cursor?: string },
  ListResponse<DonationMatchRequest>
> = async (client, [_, { currencyCode, pageSize = 10, direction = 'DESC', cursor = '' }]) => {
  const { data } = await client.get<ListResponse<ApiDonationMatchRequest>>(`/donation-match-requests`, {
    params: { currencyCode, pageSize, direction, ...(cursor && { cursor }), expand: 'organisation' }
  })

  if (data.totalResults === 0) return data

  const donationIds = data.data.map(mr => (mr.status === 'approved' ? mr.donationId : undefined)).filter(notNull)

  if (donationIds.length > 0) {
    const { data: donations } = await retrieveDonationList(client, [
      '',
      { currencyCode, donationIds, pageSize: donationIds.length }
    ])
    const donationById = Object.fromEntries(donations.map(d => [d.id, d]))

    return {
      ...data,
      data: data.data.map(mr => ({
        ...mr,
        ...(mr.status === 'approved' && mr.donationId ? { donation: donationById[mr.donationId] } : {})
      }))
    }
  }

  return data
}
