import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'
import { ReactNode } from 'react'

import * as OnboardingPopupStyles from '../OnboardingPopup/OnboardingPopup.styles'

import * as Styles from './DonationWalletGiftPopup.styles'
import { Button, Modal } from '@percent/lemonade'
import { getAccountFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth, useWindowDimensions } from '@percent/workplace-giving/common/hooks'
import { formatMoney, Money } from '@percent/utility'
import coinIcon from '@percent/workplace-giving/assets/icons/coin.svg'
import { ConfettiAnimation } from './ConfettiAnimation'

type DonationWalletGiftPopupProps = {
  open: boolean
  handleClose: VoidFunction
  wallet: Money
}

function HeaderWrapperWithBackground({ children }: { children: ReactNode }) {
  return (
    <Box sx={Styles.WalletGiftHeader}>
      <Box sx={Styles.WalletGiftHeaderStructure}>
        <span />
        <span />
        <span />
        <span />
        <span />
      </Box>
      <Box sx={Styles.WalletGiftHeaderStructure}>
        <span />
        <span />
        <span />
        <span />
        <span />
      </Box>
      {children}
    </Box>
  )
}

export function DonationWalletGiftPopup({ open, handleClose, wallet }: Readonly<DonationWalletGiftPopupProps>) {
  const { t, i18n } = useTranslation()
  const { state } = useAuth()
  const user = getAccountFromAuthState(state)
  const isDesktop = useMediaQuery('(min-width: 600px)')
  const { width: windowWidth } = useWindowDimensions()

  const formattedMoney = formatMoney(wallet, { locale: i18n.language, hideDecimalsIfZero: true })

  return (
    <Modal open={open} blur width={isDesktop ? 552 : windowWidth - 48} data-testid="donationWalletGiftPopup">
      <Box sx={OnboardingPopupStyles.Container}>
        <Box sx={OnboardingPopupStyles.ContentWrapper} textAlign="center">
          <HeaderWrapperWithBackground>
            <ConfettiAnimation />
            <Box sx={Styles.WalletAmountContainer}>
              <img src={coinIcon} alt="" />
              {formattedMoney}
            </Box>
          </HeaderWrapperWithBackground>
          <Typography sx={OnboardingPopupStyles.Title} mb={1}>
            {t('workplace_giving.donationWalletGiftPopup.title', {
              name: user?.preferredName || user?.fullName || undefined
            })}
          </Typography>
          <Typography sx={OnboardingPopupStyles.Description} mb={3}>
            {t('workplace_giving.donationWalletGiftPopup.description', {
              money: formattedMoney
            })}
          </Typography>
          <Button data-testid="claimGiftBtn" variant="primary" type="button" stretch onPress={handleClose}>
            {t('workplace_giving.donationWalletGiftPopup.button')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
