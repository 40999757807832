import { Box } from '@mui/material'

import { Icon } from '@percent/lemonade'
import * as Styles from './SuccessIcon.styles'

export function SuccessIcon() {
  return (
    <Box sx={Styles.IconWrapper}>
      <Icon name="check" size="xl" color="success400" />
    </Box>
  )
}
