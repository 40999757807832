import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom-v6'

import * as Styles from '../FundraiserWizard.styles'

import { FullPageFormLayout, Loader } from '@percent/lemonade'
import { WizardHeader } from '@percent/workplace-giving/common/components/WizardHeader/WizardHeader'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import {
  getAccountConfigFromAuthState,
  getAccountFromAuthState,
  getCountryCodeFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth, useCurrencyListGroup, useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { FILE_FORMATS, FILE_SIZE_HUMAN, FILE_SIZE_LIMIT_2MB } from '@percent/workplace-giving/constants/files'
import { FundraiserModal } from '@percent/workplace-giving/common/components/FundraiserModal/FundraiserModal'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { getFundraiserDetails } from '@percent/workplace-giving/api/fundraiser/getFundraiserDetails/getFundraiserDetails'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { editFundraiser } from '@percent/workplace-giving/api/fundraiser/editFundraiser/editFundraiser'
import { EditFundraiserWizardForm } from './EditFundraiserWizardForm'
import { getChangedValues } from '@percent/utility'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

export function EditFundraiserWizard() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useAuth()
  const { track } = useAnalytics()
  const partner = getPartnerFromAuthState(state)!
  const userAcc = getAccountFromAuthState(state)!
  const accountConfig = getAccountConfigFromAuthState(state)
  const defaultCountry = getCountryCodeFromAuthState(state)!
  const currency = accountConfig?.currency ?? partner.currencyCode
  const [coverImage, setCoverImage] = useState<File | undefined>(undefined)
  const [coverImageError, setCoverImageError] = useState<string | undefined>(undefined)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [openDetailsFetchingError, setOpenDetailsFetchingError] = useState<boolean>(false)
  const [fundraiserId, setFundraiserId] = useState('')
  const currencyListGroup = useCurrencyListGroup()
  const { fundraiserId: fundId } = useParams()
  const isEditForm = !!fundId
  const { logError } = useLogger()

  const {
    data: fundraiserData,
    isLoading,
    isError,
    error
  } = useQuery([`getOpportunityDetails`, fundId as string], getFundraiserDetails, {
    enabled: isEditForm
  })

  useEffect(() => {
    if (!isLoading && fundraiserData && fundraiserData?.organiser?.id !== userAcc.id) {
      navigate(-1)
    }
  }, [navigate, fundraiserData, userAcc, isLoading])

  const { mutateAsync } = useMutation({
    mutationFn: editFundraiser,
    onSuccess: res => {
      if (okResponse(res)) {
        setOpenSuccessModal(true)
        setFundraiserId(res.data.id)
      }
    },
    onError: () => {
      setOpenErrorModal(true)
    }
  })

  const handleCoverImageChange = (file: File) => {
    setCoverImageError(undefined)

    if (!/(png|jpe?g)/i.exec(file.type))
      setCoverImageError(t('workplace_giving.validation.invalidFileType', { fileFormats: FILE_FORMATS }))

    if (file.size > FILE_SIZE_LIMIT_2MB)
      setCoverImageError(t('workplace_giving.validation.fileTooLarge', { fileSize: FILE_SIZE_HUMAN }))

    setCoverImage(file)
    setFieldValue('image', file)
  }

  const initialValues = {
    title: fundraiserData?.title ?? '',
    story: fundraiserData?.story ?? '',
    money: {
      amount: fundraiserData?.campaign?.goal?.amount ?? undefined,
      currency: fundraiserData?.campaign?.goal?.currency ?? undefined
    },
    organisationCountry: defaultCountry,
    organisationId: fundraiserData?.organisation?.id ?? '',
    image: fundraiserData?.imageUrl ?? ''
  }

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.object().shape({
        title: Yup.string()
          .trim()
          .min(1, t('workplace_giving.validation.requiredField'))
          .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))
          .required(t('workplace_giving.validation.requiredField')),
        story: Yup.string()
          .trim()
          .min(1, t('workplace_giving.validation.requiredField'))
          .max(5000, t('workplace_giving.validation.maxCharacters', { max: 5000 }))
          .required(t('workplace_giving.validation.requiredField')),
        money: Yup.object().shape({
          amount: Yup.number()
            .required(t('workplace_giving.validation.requiredField'))
            .positive(t('workplace_giving.validation.positive'))
        }),
        organisationCountry: Yup.string().required(t('workplace_giving.validation.requiredField')),
        organisationId: Yup.string().required(t('workplace_giving.validation.requiredField'))
      }),
    onSubmit: async data => {
      const { story, title, organisationCountry } = data

      try {
        await mutateAsync({
          id: fundId,
          page: getChangedValues({ story, title }, initialValues) as { title: string; story: string },
          image: coverImage!
        })

        track({
          event: 'Donation Fundraiser Edit Completed',
          properties: {
            kind: 'campaign',
            currencyCode: currency,
            organisationCountryCode: organisationCountry
          }
        })
      } catch (e) {
        logError(e)
      }
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  useEffect(() => {
    if (fundraiserData) {
      const fields = ['image', 'title', 'story', 'money', 'organisationId'] as const
      fields.forEach(field => {
        if (field === 'money') {
          setFieldValue(field, fundraiserData.campaign.goal)
        } else if (field === 'organisationId') {
          setFieldValue(field, fundraiserData.organisation.id)
        } else if (field === 'image') {
          setFieldValue(field, fundraiserData.imageUrl)
        } else {
          setFieldValue(field, fundraiserData[field])
        }
      })
    }
  }, [fundraiserData, setFieldValue])

  const formValid = isValid && dirty && !isSubmitting

  useEffect(() => {
    if (isError && error) {
      logError(error)
      setOpenDetailsFetchingError(true)
    }
  }, [error, isError, logError])

  useEffect(() => {
    if (touched.image && !coverImage) {
      if (!isEditForm) {
        setCoverImageError(t('workplace_giving.validation.requiredField'))
      }
    }
  }, [coverImage, t, isEditForm, touched.image])

  if (!!fundId && isLoading) {
    return <Loader />
  }

  return (
    <FullPageFormLayout
      actionsHeader={
        <WizardHeader
          title={t('workplace_giving.fundraiser.editFundraiser')}
          onCancel={() => {
            navigate(-1)
          }}
          onPublish={handleSubmit}
          isPublishDisabled={!formValid}
          isLoading={isSubmitting}
          publishBtnCopy={t('workplace_giving.common.saveChanges')}
          toolTipCopy={t('workplace_giving.fundraiser.tooltip.edit')}
        />
      }
    >
      <Typography sx={Styles.Title}>{t('workplace_giving.fundraiser.editPage')}</Typography>
      <EditFundraiserWizardForm
        handleCoverImageChange={handleCoverImageChange}
        coverImageError={coverImageError}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        currency={currency}
        currencies={currencyListGroup}
        coverImage={fundraiserData?.imageUrl}
        fundraiserData={fundraiserData}
      />
      <FundraiserModal
        type="edit"
        handleClose={() => {
          setOpenSuccessModal(false)
          navigate(`/fundraisers/${fundraiserId}`)
        }}
        openModal={openSuccessModal}
      />
      <FundraiserModal
        type="edit_error"
        handleClose={() => {
          setOpenErrorModal(false)
        }}
        openModal={openErrorModal}
      />
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.somethingWentWrong')}
        open={openDetailsFetchingError}
        onClose={() => setOpenDetailsFetchingError(false)}
      />
    </FullPageFormLayout>
  )
}
