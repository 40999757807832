import { useTranslation } from 'react-i18next'

import { AcknowledgeModal } from '@percent/lemonade'

export type RequestSubmitSuccessProps = {
  handleClose: () => void
}

export function RequestSubmitSuccess({ handleClose }: RequestSubmitSuccessProps) {
  const { t } = useTranslation()

  return (
    <AcknowledgeModal
      title={t('workplace_giving.donationMatchRequest.success.title')}
      description={t('workplace_giving.donationMatchRequest.success.description')}
      buttonText={t('workplace_giving.donationMatchRequest.success.button')}
      result="positive"
      handleClose={handleClose}
    />
  )
}
