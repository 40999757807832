import {
  GetOpportunityParticipantsQuery,
  GetOpportunityParticipantsResponse
} from '@percent/workplace-giving/api/opportunity/getOpportunityParticipants/getOpportunityParticipants.types'
import { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'

export const getOpportunityParticipants: InfiniteQueryFn<
  [string, string | undefined, GetOpportunityParticipantsQuery],
  string,
  GetOpportunityParticipantsResponse
> = async (client, [_, id, { direction, pageSize = 25 }], cursor) =>
  (
    await (cursor
      ? client.get<GetOpportunityParticipantsResponse>(cursor, {
          withCredentials: true
        })
      : client.get<GetOpportunityParticipantsResponse>(`/opportunities/${id}/participants`, {
          params: { pageSize, direction },
          withCredentials: true
        }))
  ).data
