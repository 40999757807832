import dayjs from 'dayjs'

export const getStartsIn = (startDate: Date) => {
  if (dayjs(startDate).diff(new Date(), 'days') >= 1) {
    return {
      type: 'days' as const,
      count: dayjs(startDate).diff(new Date(), 'days')
    }
  }

  if (dayjs(startDate).diff(new Date(), 'hours') >= 1) {
    return {
      type: 'hours' as const,
      count: dayjs(startDate).diff(new Date(), 'hours')
    }
  }

  if (dayjs(startDate).diff(new Date(), 'minutes') >= 1) {
    return {
      type: 'minutes' as const,
      count: dayjs(startDate).diff(new Date(), 'minutes')
    }
  }

  return {
    type: 'started' as const
  }
}
