import { Avatar, Text } from '@percent/lemonade'
import styled from 'styled-components'
import { AttendeesListProps } from './attendees-list.types'

const StyledListItem = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${theme.sizes[3]} 0;
    border-bottom: 1px solid ${theme.colors.gray90};

    & > span {
      margin-left: ${theme.sizes[2]};
    }
  `}
`

export const AttendeesList = ({ data }: AttendeesListProps) => (
  <div>
    {data?.pages?.map((attendees) =>
      attendees.map((attendee) => (
        <StyledListItem key={attendee.id}>
          <Avatar
            key={attendee.id}
            image={attendee.image}
            size="medium"
            alt={attendee.fullName}
          />
          <Text>{attendee.fullName}</Text>
        </StyledListItem>
      ))
    )}
  </div>
)
