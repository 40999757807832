/* eslint-disable no-underscore-dangle */
import {
  QueryKey,
  useQuery as useReactQuery,
  useInfiniteQuery as useReactInfiniteQuery,
  UseQueryResult,
  UseInfiniteQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import { useMemo } from 'react'

import { useClient } from '../useClient/useClient'

import { InfiniteQueryFn, QueryFn, UseInfiniteQueryOptions, UseQueryOptions } from './useQuery.types'
import { ListResponse } from '@percent/workplace-giving/api/goodstack'

const shouldRetryQuery = (error: unknown): boolean => {
  if (axios.isAxiosError(error)) {
    return !!error.response?.status && error.response.status >= 500
  }

  return false
}

export const useQuery = <
  TQueryKey extends QueryKey = unknown[],
  TParams = unknown,
  TError = unknown,
  TResponse = unknown
>(
  queryKey: TQueryKey,
  query: QueryFn<TQueryKey, TResponse>,
  options?: UseQueryOptions<TQueryKey[1], TParams, TError, TResponse>
): UseQueryResult<TResponse, TError> => {
  const { gsClient } = useClient()

  const response = useReactQuery<TQueryKey[1], TError, TResponse, TQueryKey>(
    queryKey,
    queryFn => query(gsClient, queryFn.queryKey),
    {
      enabled: options?.enabled,
      refetchOnWindowFocus: !!options?.refetchOnWindowFocus,
      staleTime: options?.staleTime,
      retry: (_, error) => shouldRetryQuery(error)
    }
  )

  const refetch = useMemo(
    () => (options?.enabled === false ? async () => response : response.refetch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options?.enabled]
  )

  return { ...response, refetch }
}

export const useInfiniteQuery = <
  TResponse extends ListResponse<unknown>,
  TQueryKey extends QueryKey = unknown[],
  TParams = unknown,
  TError = unknown
>(
  queryKey: TQueryKey,
  query: InfiniteQueryFn<TQueryKey, string, TResponse>,
  options?: UseInfiniteQueryOptions<TQueryKey[1], TParams, TError, TResponse>
): UseInfiniteQueryResult<TResponse, TError> => {
  const { gsClient } = useClient()

  return useReactInfiniteQuery<TResponse, TError, TResponse, TQueryKey>(
    queryKey,
    queryFn => query(gsClient, queryFn.queryKey, queryFn.pageParam),
    {
      enabled: options?.enabled,
      refetchOnWindowFocus: !!options?.refetchOnWindowFocus,
      retry: (_, error) => shouldRetryQuery(error),
      getNextPageParam: lastPage => lastPage._links?.next,
      cacheTime: options?.cacheTime
    }
  )
}
