import { CoreListResponse, RawValidResponse } from '../goodstack'
import { AccountTerms } from '../auth/getAccountTerms/getAccountTerms'
import { AccountConfig } from '../account/accountConfig/accountConfig'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { OnboardingProps, OnboardingResponse } from './onboarding.types'
import { Account } from '@percent/workplace-giving/context/auth/authContext/AuthContext.types'

export const onboarding: MutationFn<OnboardingProps, OnboardingResponse> = async (
  client,
  { fullName, preferredName, currency, terms }
) => {
  if (currency) {
    const [
      {
        data: { data: account }
      },
      {
        data: { data: allTerms }
      },
      {
        data: { data: accConfig }
      }
    ] = await Promise.all([
      client.patch<RawValidResponse<Account>>(`/account`, { fullName, preferredName }),
      client.post<CoreListResponse<AccountTerms>>(`/account/terms/accept`, { terms }),
      client.patch<RawValidResponse<AccountConfig>>(`/account/giving/config`, { currency })
    ])

    return {
      account,
      terms: allTerms.map(t => t.key),
      accConfig
    }
  }

  const [
    {
      data: { data: account }
    },
    {
      data: { data: allTerms }
    }
  ] = await Promise.all([
    client.patch<RawValidResponse<Account>>(`/account`, { fullName, preferredName }),
    client.post<CoreListResponse<AccountTerms>>(`/account/terms/accept`, { terms })
  ])

  return {
    account,
    terms: allTerms.map(t => t.key)
  }
}
