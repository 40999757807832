/* eslint-disable no-underscore-dangle */
import { InfiniteData } from '@tanstack/react-query'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'

import { FeaturedCards } from '../Featured/FeaturedCards'

import { useInfiniteQuery, useLogger } from '@percent/workplace-giving/common/hooks'
import { OpportunitiesList } from './OpportunitiesList/OpportunitiesList'
import { searchOpportunities } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities'
import { Opportunity } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { PageContent } from '@percent/workplace-giving/common/components/Layout/PageContent'
import { VolunteeringSidePanel } from './VolunteeringSidePanel/VolunteeringSidePanel'

export function Volunteer() {
  const { logError } = useLogger()
  const [query, setQuery] = useQueryParams({
    type: StringParam,
    locationType: StringParam,
    city: StringParam,
    country: StringParam,
    relevantSkills: ArrayParam,
    query: StringParam
  })

  const { data, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage, isError, error } = useInfiniteQuery(
    ['searchOpportunities', { ...query, pageSize: 10 }],
    searchOpportunities,
    { staleTime: 1000 * 20 }
  )

  if (isError && error) {
    logError(error)
  }

  const mappedData: InfiniteData<Opportunity[]> | undefined = data && {
    ...data,
    pages: data.pages.map(page => page.data)
  }
  const totalResults = data && data.pages.length > 0 ? data.pages[0].totalResults : 0

  return (
    <Page testId="volunteering-page-wrapper">
      <PageContent>
        <FeaturedCards mode="volunteering" />
        {!isError && (
          <OpportunitiesList
            totalResults={totalResults}
            isLoadingMore={isFetching || isFetchingNextPage}
            data={mappedData}
            hasMore={hasNextPage}
            loadMore={() => fetchNextPage()}
            query={query}
            setQuery={setQuery}
          />
        )}
      </PageContent>
      <VolunteeringSidePanel />
    </Page>
  )
}
