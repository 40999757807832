import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import {
  CreateFundraiserDonationSessionProps,
  CreateFundraiserDonationSessionResponse
} from '@percent/workplace-giving/api/donation/create-fundraiser-donation-session/create-fundraiser-donation-session.types'

export const createFundraiserDonationSession: MutationFn<
  CreateFundraiserDonationSessionProps,
  CreateFundraiserDonationSessionResponse
> = async (client, { currency, donationLinkReference, language }) => {
  const res = await client.post<CreateFundraiserDonationSessionResponse>(
    `/donation-links/${donationLinkReference}/donation-sessions`,
    {
      currency,
      language
    },
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'pbp-donation-link-reference': donationLinkReference
      }
    }
  )

  return res.data
}
