import { useMutation as useReactQueryMutation } from '@tanstack/react-query'

import { useClient } from '../useClient/useClient'

import { UseMutationProps } from './useMutation.types'

export const useMutation = <TData = unknown, TError = unknown, TVariables extends object = {}, TContext = unknown>({
  mutationFn,
  onSuccess,
  onError
}: UseMutationProps<TVariables, TData, TError>) => {
  const { gsClient } = useClient()

  return useReactQueryMutation<TData, TError, TVariables, TContext>({
    mutationFn: variables => mutationFn(gsClient, variables),
    onSuccess,
    onError
  })
}
