import { Dialog } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import * as Styles from './DonationForm.styles'
import { useLogger, useWindowDimensions } from '@percent/workplace-giving/common/hooks'
import { DonationFormSkeletonLoader } from './DonationFormSkeletonLoader/DonationFormSkeletonLoader'
import { config, environment } from '@percent/workplace-giving/config/config'

export type DonationFormProps = Readonly<{
  url: string
  onClose: () => void
  params?: {
    locale?: string
    primaryColor?: string
  }
  open: boolean
  loading: boolean
}>

const isValidHexColor = (hexColor: string) => /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/i.test(hexColor)

export function DonationForm({
  url,
  params: { locale, primaryColor } = {},
  onClose,
  open,
  loading
}: DonationFormProps) {
  const { width } = useWindowDimensions()
  const { logError } = useLogger()
  const [showLoader, setShowLoader] = useState(true)
  const [listenerExists, setListenerExists] = useState(false)

  const handleOnClose = useCallback(() => {
    try {
      if (window.parent) {
        window.parent.postMessage(
          {
            message: 'donationWidgetClosed'
          },
          '*'
        )
      }
    } catch (e) {
      logError(e)
    }

    onClose()
  }, [logError, onClose])

  const handleCloseDialog = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason && reason === 'backdropClick') {
      return
    }

    handleOnClose()
  }

  const allowedMessageOrigin = config.allowedMessageOrigins[environment as string]

  useEffect(() => {
    if (loading) {
      setShowLoader(true)
    }
  }, [loading])

  useEffect(() => {
    if (!listenerExists) {
      setListenerExists(true)
      window.addEventListener('message', event => {
        if (!allowedMessageOrigin?.some(origin => ['*', event.origin].includes(origin))) return
        try {
          const eventData = event.data as {
            message: string
            data: {
              amount: number
              currency: string
            }
          }

          if (eventData.message === 'donationFormLoaded') {
            setShowLoader(false)
          }

          if (eventData.message === 'closeDonationWidget') {
            handleOnClose()
          }
        } catch (e) {
          logError(e)
        }
      })
    }
  }, [allowedMessageOrigin, listenerExists, logError, handleOnClose])

  const queryParameters = {
    ...(locale && { locale }),
    ...(primaryColor && isValidHexColor(primaryColor) && { primaryColor }),
    showFooter: 'false',
    showTermsWhenAccount: 'false',
    displayMode: 'widget'
  }
  const searchParams = new URLSearchParams(queryParameters)
  const searchParamsString = searchParams.toString()

  const src = `${url}${searchParamsString ? `&${searchParamsString}` : ''}`

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullScreen={width < 450}>
      <div style={{ position: 'absolute', top: '16px', right: '16px', zIndex: 2 }}>
        <button type="button" aria-label="Close" onClick={handleOnClose} style={Styles.CloseButton}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#6A6D74">
            <path
              d="M9.02123 8.08358C9.01088 8.07326 9.00267 8.061 8.99707 8.0475C8.99147 8.034 8.98859 8.01953 8.98859 8.00492C8.98859 7.99031 8.99147 7.97584 8.99707 7.96234C9.00267 7.94884 9.01088 7.93658 9.02123 7.92626L13.1378 3.81015C13.2628 3.68498 13.3329 3.51528 13.3328 3.33839C13.3327 3.16149 13.2623 2.99189 13.1371 2.8669C13.0119 2.7419 12.8422 2.67175 12.6653 2.67188C12.4885 2.672 12.3189 2.74239 12.1939 2.86756L8.07775 6.98189C8.06743 6.99224 8.05517 7.00045 8.04167 7.00605C8.02817 7.01165 8.0137 7.01453 7.99909 7.01453C7.98448 7.01453 7.97001 7.01165 7.95651 7.00605C7.94301 7.00045 7.93075 6.99224 7.92043 6.98189L3.80432 2.86756C3.74243 2.80564 3.66895 2.75652 3.58807 2.723C3.5072 2.68947 3.42051 2.67221 3.33296 2.67219C3.15615 2.67215 2.98657 2.74235 2.86151 2.86734C2.73646 2.99234 2.66618 3.16189 2.66614 3.3387C2.6661 3.51551 2.73629 3.6851 2.86129 3.81015L6.97695 7.92626C6.9873 7.93658 6.99551 7.94884 7.00111 7.96234C7.00671 7.97584 7.00959 7.99031 7.00959 8.00492C7.00959 8.01953 7.00671 8.034 7.00111 8.0475C6.99551 8.061 6.9873 8.07326 6.97695 8.08358L2.86129 12.2001C2.7994 12.2621 2.75031 12.3356 2.71682 12.4165C2.68334 12.4973 2.66612 12.584 2.66614 12.6716C2.66618 12.8484 2.73646 13.018 2.86151 13.1429C2.92343 13.2048 2.99694 13.2539 3.07783 13.2874C3.15872 13.3209 3.24542 13.3381 3.33296 13.3381C3.50977 13.3381 3.67933 13.2678 3.80432 13.1427L7.92043 9.02617C7.93075 9.01582 7.94301 9.00762 7.95651 9.00201C7.97001 8.99641 7.98448 8.99353 7.99909 8.99353C8.0137 8.99353 8.02817 8.99641 8.04167 9.00201C8.05517 9.00762 8.06743 9.01582 8.07775 9.02617L12.1939 13.1427C12.3189 13.2678 12.4884 13.3381 12.6652 13.3381C12.842 13.3381 13.0116 13.2679 13.1367 13.1429C13.2617 13.018 13.332 12.8484 13.332 12.6716C13.3321 12.4948 13.2619 12.3252 13.1369 12.2001L9.02123 8.08358Z"
              fill="#6A6D74"
            />
          </svg>
        </button>
      </div>
      {showLoader ? (
        <div style={Styles.Container({ width })}>
          <DonationFormSkeletonLoader />
        </div>
      ) : null}
      {!loading ? (
        <iframe
          title="donation-form"
          style={{
            border: 'none'
          }}
          src={src}
          allow="payment *"
          width={width < 450 ? '100%' : '400px'}
          height="700px"
        />
      ) : null}
    </Dialog>
  )
}
