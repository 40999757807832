import { Avatar } from '@percent/lemonade'
import { createShortLink } from '@percent/utility'
import { OrganizerInfoProps } from './organizer-info.types'
import styled from 'styled-components'

const StyledOrganizerInfoWrapper = styled.div<{ topBorder: boolean }>`
  ${({ theme, topBorder }) => `
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin-top: ${theme.sizes.m};
      margin-bottom: ${theme.sizes.m};
      ${
        topBorder
          ? `padding-top: ${theme.sizes.m}; border-top: 1px solid ${theme.colors.gray80};`
          : ''
      }
    `}
`

const StyledOrganizerAvatarWrapper = styled.div`
  display: flex;
`

const StyledOrganizerInfoDetails = styled.div`
  ${({ theme }) => `
      color: ${theme.colors.gray300};
      font-size: ${theme.fontSizes.bodySmall};
      font-weight: ${theme.fontWeights.regular};
      line-height: ${theme.fontSizes.body};
      margin-left: 10px;

      p {
        margin: 0 0 8px 0;
      }

      a {
        color: ${theme.colors.gray600};
        text-decoration: underline;
        text-decoration-color: ${theme.colors.gray600};
      }
    `}
`

export function OrganizerInfo({
  logo,
  name,
  website,
  description,
  topBorder = false,
}: OrganizerInfoProps) {
  return (
    <StyledOrganizerInfoWrapper topBorder={topBorder}>
      <StyledOrganizerAvatarWrapper>
        <Avatar image={logo} alt={`${name} logo`} />
      </StyledOrganizerAvatarWrapper>
      <StyledOrganizerInfoDetails>
        <p>{description ? description : `Donations will go to ${name}`}</p>
        {website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {createShortLink(website)}
          </a>
        )}
      </StyledOrganizerInfoDetails>
    </StyledOrganizerInfoWrapper>
  )
}
