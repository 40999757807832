import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { PartnerConfig } from './config.types'

export const getConfig =
  <K, T>(key: K): QueryFn<[string], T | undefined> =>
  async client => {
    const { data, status } = await client.get<{ data: PartnerConfig<K, T> }>(`/partner/giving/config/${key}`, {
      validateStatus: code => [200, 404].includes(code)
    })

    return status === 200 ? data.data.value : undefined
  }
