import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Button, ButtonText, Tooltip } from '@percent/lemonade'
import * as Styles from './WizardHeader.styles'

interface WizardHeaderProps {
  title: string
  onCancel: VoidFunction
  onPublish: VoidFunction
  isPublishDisabled: boolean
  isLoading: boolean
  publishBtnCopy?: string
  toolTipCopy?: string
}

export function WizardHeader({
  title,
  onCancel,
  onPublish,
  isPublishDisabled,
  isLoading,
  publishBtnCopy,
  toolTipCopy
}: Readonly<WizardHeaderProps>) {
  const { t } = useTranslation()

  return (
    <Box sx={Styles.Wrapper}>
      <Typography sx={Styles.Title}>{title}</Typography>
      <Box sx={Styles.ButtonsWrapper}>
        <ButtonText onPress={onCancel} data-testid="cancelBtn">
          {t('workplace_giving.common.cancel')}
        </ButtonText>
        {isPublishDisabled && !isLoading ? (
          <Tooltip content={toolTipCopy || t('workplace_giving.validation.formError')}>
            <div data-testid="wrapper-disabled-confirm-button">
              <Button
                size="small"
                onPress={onPublish}
                disabled={isPublishDisabled}
                isDisabled={isPublishDisabled}
                loading={isLoading}
                data-testid="publishBtn"
              >
                {publishBtnCopy || t('workplace_giving.common.publish')}
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            size="small"
            onPress={onPublish}
            disabled={isPublishDisabled}
            isDisabled={isPublishDisabled}
            loading={isLoading}
            data-testid="publishBtn"
          >
            {publishBtnCopy || t('workplace_giving.common.publish')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
