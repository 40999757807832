import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { Typography } from '@mui/material'

import { LogVolunteeringActivityParams } from '@percent/workplace-giving/api/volunteering/logVolunteeringActivity/logVolunteeringActivity.types'
import { FullPageFormLayout } from '@percent/lemonade'
import { WizardHeader } from '@percent/workplace-giving/common/components/WizardHeader/WizardHeader'
import { useAuth, useLogger } from '@percent/workplace-giving/common/hooks'
import * as Styles from './LogVolunteeringActivity.styles'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { LogVolunteeringActivityForm } from './LogVolunteeringActivityForm'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { logVolunteeringActivity } from '@percent/workplace-giving/api/volunteering/logVolunteeringActivity/logVolunteeringActivity'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { UnexpectedErrorModal } from '@percent/workplace-giving/common/components/UnexpectedErrorModal/UnexpectedErrorModal'
import { LogVolunteeringActivityCancellationModal } from './LogVolunteeringActivityCancellationModal'
import { LogVolunteeringActivitySuccessModal } from './LogVolunteeringActivitySuccessModal'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

export type LogVolunteeringFormType = Omit<LogVolunteeringActivityParams, 'timeZone'> & {
  hours: number
  organisationCountry: string
  platform: 'on-platform' | 'off-platform'
}

export function LogVolunteeringActivity() {
  const { track } = useAnalytics()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logError } = useLogger()
  const authState = useAuth()
  const defaultCountry = getCountryCodeFromAuthState(authState.state)!
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [openCancellationModal, setOpenCancellationModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)

  const { mutateAsync } = useMutation({
    mutationFn: logVolunteeringActivity,
    onSuccess: res => {
      if (okResponse(res)) {
        track({
          event: 'Volunteering Time Logging Completed',
          properties: {
            logVolunteeringFormType: values.platform,
            loggedTimeMinutes: values.minutes
          }
        })

        return setOpenSuccessModal(true)
      }

      return setOpenErrorModal(true)
    },
    onError: error => {
      setOpenErrorModal(true)
      logError(error)
    }
  })

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty
  } = useFormik({
    initialValues: {
      date: undefined,
      minutes: undefined,
      hours: undefined,
      organisationCountry: defaultCountry || '',
      organisationId: '',
      activities: [],
      platform: 'off-platform' as 'on-platform' | 'off-platform',
      opportunityId: ''
    },
    enableReinitialize: true,
    validationSchema: () =>
      Yup.object().shape({
        date: Yup.date().nullable().required(t('workplace_giving.validation.requiredField')),
        hours: Yup.number().nullable().required(t('workplace_giving.validation.requiredField')),
        minutes: Yup.number().nullable().required(t('workplace_giving.validation.requiredField')),
        platform: Yup.string().oneOf(['on-platform', 'off-platform']),
        opportunityId: Yup.string().when('platform', {
          is: 'on-platform',
          then: Yup.string().required(t('workplace_giving.validation.requiredField')),
          otherwise: Yup.string().optional()
        }),
        organisationCountry: Yup.string(),
        organisationId: Yup.string(),
        activities: Yup.array().of(Yup.string())
      }),
    onSubmit: data => {
      const { date, minutes, hours, platform } = data as unknown as LogVolunteeringFormType

      const baseProps = {
        date,
        minutes: (hours || 0) * 60 + (minutes || 0)
      }

      const props =
        platform === 'off-platform'
          ? {
              ...baseProps,
              platform,
              organisationId: data.organisationId.length ? data.organisationId : undefined,
              activities: data.activities
            }
          : {
              ...baseProps,
              platform,
              opportunityId: data.opportunityId
            }

      return mutateAsync([props])
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  const formValid = isValid && dirty && !isSubmitting

  return (
    <FullPageFormLayout
      actionsHeader={
        <WizardHeader
          title={t('workplace_giving.volunteering.activityForm.logYourActivity')}
          onCancel={() => {
            if (dirty) {
              setOpenCancellationModal(true)
            } else {
              navigate(-1)
            }
          }}
          onPublish={handleSubmit}
          isPublishDisabled={!formValid}
          isLoading={isSubmitting}
          publishBtnCopy={t('workplace_giving.volunteering.activityForm.logTime')}
        />
      }
    >
      <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.activityForm.title')}</Typography>
      <Typography sx={Styles.Description}>{t('workplace_giving.volunteering.activityForm.description')}</Typography>

      <LogVolunteeringActivityForm
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />

      <LogVolunteeringActivityCancellationModal
        open={openCancellationModal}
        onClose={() => setOpenCancellationModal(false)}
      />
      <LogVolunteeringActivitySuccessModal open={openSuccessModal} onClose={() => setOpenSuccessModal(false)} />
      <UnexpectedErrorModal
        message={t('workplace_giving.errors.api.modal.volunteeringTime.title')}
        description={t('workplace_giving.errors.api.modal.volunteeringTime.description')}
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
      />
    </FullPageFormLayout>
  )
}
