import { Styles } from '@percent/workplace-giving/theme'

export const IconWrapper: Styles = {
  borderRadius: '100%',
  background: '#F5F5F6',
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FE2C55'
}
