import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Alpha3Code } from 'i18n-iso-countries'

import { useAnalytics } from '../../hooks/useAnalytics/useAnalytics'

import { FeaturedCharityCardProps } from './FeaturedCharityCard.types'
import { FeaturedCard } from '@percent/domain/giving'
import { useDonationForm } from '@percent/workplace-giving/common/hooks/useDonationForm/useDonationForm'
import { TooltipTaxInformation } from '@percent/workplace-giving/common/components/TooltipTaxInformation/TooltipTaxInformation'

export function FeaturedCharityCard({ partner, featured, organisation }: FeaturedCharityCardProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { track } = useAnalytics()

  const { handleDonate, DonationFormWrapper } = useDonationForm()

  const handleDonateButton = async () => {
    await handleDonate(organisation.id)
  }

  return (
    <>
      <FeaturedCard
        type="featured_organisation"
        badgeText={t('workplace_giving.featured.chosenBy', {
          partnerName: partner.name
        })}
        title={featured.title}
        description={featured.description}
        descriptionText={t('workplace_giving.featured.donationsWillGoTo')}
        actionButton={() => {
          track({
            event: 'Donation Organisation Donation Start',
            properties: {
              origin: 'Featured Organisation',
              organisationCountryCode: organisation.countryCode
            }
          })
          handleDonateButton()
        }}
        buttonText={t('workplace_giving.common.donate')}
        organisationName={organisation?.displayName || organisation?.name}
        countryCode={featured.country ?? organisation?.countryCode}
        imgSrc={featured.image}
        cardButtonInfo={<TooltipTaxInformation orgCountryCode={organisation.countryCode as Alpha3Code} />}
      />
      <DonationFormWrapper
        params={{
          primaryColor: theme.palette.clientColor
        }}
      />
    </>
  )
}
