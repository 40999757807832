import { FlagAvatar, Select } from '@percent/lemonade'
import { CurrencyGroup, CurrencySelectorProps } from './currency-selector.types'
import { SelectProps } from 'libs/shared/ui-lemonade/src/components/select'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { CurrencyCode, currencyMap } from '@percent/utility'
import { Alpha2Code } from 'i18n-iso-countries'
import styled from 'styled-components'

const isGroup = (a: CurrencyGroup | CurrencyCode): a is CurrencyGroup =>
  typeof a === 'object'

const isNotGroup = (a: CurrencyCode | CurrencyGroup): a is CurrencyCode =>
  typeof a !== 'object'

const Wrapper = styled.div`
  ${({ theme }) => `
  margin-right: ${theme.sizes.xxs};

  button > div {
    border: none;
    outline: none;

    &:hover, &:focus, &:active {
      border: none;
      outline: none;
    }
  }

  #select-list {
    min-width: 250px;
    right: 0;
    left: unset;
  }
`}
`

export const CurrencySelector = ({
  defaultCurrency,
  placeholder,
  onValueChange,
  currencies,
  ...props
}: CurrencySelectorProps) => {
  const asOption = (c: CurrencyCode): SelectOption => ({
    value: c,
    label: c,
    description: currencyMap[c].name,
    prefix: <FlagAvatar code={c.substring(0, 2) as Alpha2Code} />,
  })

  const options: SelectProps['options'] = isGroup(currencies[0])
    ? currencies.filter(isGroup).map((c: CurrencyGroup) => ({
        title: c.title,
        options: c.currencies.map(asOption),
      }))
    : currencies.filter(isNotGroup).map(asOption)

  const defaultValue = asOption(defaultCurrency)

  return (
    <Wrapper>
      <Select
        searchable
        searchInDropDown
        showPrefixForSelectedOption
        placeholder={placeholder ?? 'Find a currency'}
        options={options}
        defaultValue={defaultValue}
        onChange={(v) => onValueChange?.(v.value as CurrencyCode)}
        {...props}
      />
    </Wrapper>
  )
}
