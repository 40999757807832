import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export function useInfiniteScroll(loadMore?: () => void) {
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView && loadMore) {
      loadMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return {
    ref
  }
}
