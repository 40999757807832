import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Wrapper: Styles = {
  '& > div': {
    marginY: lemonadeTheme.sizes[4]
  },

  '& > p:last-of-type': {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    lineHeight: lemonadeTheme.sizes[6],
    color: customPalette.gray600
  }
}

export const Title: Styles = {
  marginBottom: lemonadeTheme.sizes[4],
  fontSize: lemonadeTheme.sizes[6],
  lineHeight: lemonadeTheme.sizes[8],
  fontWeight: lemonadeTheme.fontWeights.semiBold
}
