import { Box, ButtonBase, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Icon } from '@percent/lemonade'
import * as Styles from './FiltersButton.styles'

type FiltersButtonProps = {
  onClick: VoidFunction
  activeFiltersAmount?: number
  testId?: string
}

export function FiltersButton({ onClick, activeFiltersAmount, testId }: Readonly<FiltersButtonProps>) {
  const { t } = useTranslation()

  return (
    <ButtonBase type="button" onClick={onClick} sx={Styles.Button} data-testid={testId}>
      <Icon name="filters" size={6} color="gray600" />
      <Typography sx={Styles.BtnText} component="span">
        {t('workplace_giving.common.filters')}
      </Typography>
      {activeFiltersAmount ? (
        <Box component="span" sx={Styles.FiltersCounter}>
          {activeFiltersAmount}
        </Box>
      ) : null}
    </ButtonBase>
  )
}
