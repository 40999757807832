import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ClickAwayListener, Menu, MenuItem, Divider, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom-v6'

import * as NavStyles from '../NavBar.styles'

import { usePermission } from '@percent/workplace-giving/common/hooks/usePermission/usePermission'
import { Icon } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import * as Styles from './ActionsMenu.styles'
import { useMatchRequestForm } from '@percent/workplace-giving/common/hooks/useMatchRequestForm/useMatchRequestForm'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { scope } from '@percent/workplace-giving/common/hoc/withPermissions'

export function ActionsMenu() {
  const { track } = useAnalytics()
  const { dispatch } = useMatchRequestForm()
  const navigation = useNavigate()
  const { t } = useTranslation()
  const has = usePermission()

  const anchorEl = useRef<HTMLButtonElement | null>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <Button
        data-testid="actions-menu"
        type="button"
        onClick={handleMenuClick}
        disableRipple
        ref={anchorEl}
        sx={Styles.PlusButton}
      >
        <Icon name="add" color="white" size={6} />
        <Menu
          anchorEl={anchorEl.current}
          open={isMenuOpen}
          PaperProps={{
            style: {
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '0.25rem',
              boxShadow: 'rgba(33, 37, 53, 0.16) 0px 8px 32px',
              margin: '0.25rem 0px',
              padding: '0px 0.5rem',
              outline: 'none',
              zIndex: 1,
              backgroundColor: 'white'
            }
          }}
          disableScrollLock
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => {
              track({
                event: 'Donation Match Request Start',
                properties: {
                  origin: 'Main header'
                }
              })
              dispatch({ type: 'OPEN' })
            }}
            sx={NavStyles.MenuItem}
            disableRipple
          >
            {t('workplace_giving.navigation.submitReceipt')}
          </MenuItem>

          {has(scope('donation_link')) && (
            <MenuItem
              onClick={() => {
                track({
                  event: 'Donation Fundraiser Create Start',
                  properties: {
                    origin: 'Main header'
                  }
                })
                navigation(`${AppRoute.FUNDRAISER_WIZARD}`)
              }}
              sx={NavStyles.MenuItem}
              disableRipple
            >
              {t('workplace_giving.navigation.createFundraiser')}
            </MenuItem>
          )}

          {has(scope('volunteering')) && (
            <Box>
              <Divider sx={NavStyles.Divider} />
              <MenuItem
                onClick={() => {
                  track({
                    event: 'Volunteering Time Logging Start',
                    properties: {
                      origin: 'Main header'
                    }
                  })
                  navigation(`${AppRoute.LOG_VOLUNTEERING_ACTIVITY}`)
                }}
                sx={NavStyles.MenuItem}
                disableRipple
              >
                {t('workplace_giving.navigation.logTime')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  track({
                    event: 'Volunteering Opportunity Create Start',
                    properties: {
                      origin: 'Main header'
                    }
                  })
                  navigation(`${AppRoute.CREATE_OPPORTUNITY}`)
                }}
                sx={NavStyles.MenuItem}
                disableRipple
              >
                {t('workplace_giving.navigation.createOpportunity')}
              </MenuItem>
            </Box>
          )}
        </Menu>
      </Button>
    </ClickAwayListener>
  )
}
