import { ReactNode, useRef } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useResizeObserver } from 'usehooks-ts'

import * as Styles from './SidePanelContainer.styles'

export type SidePanelProps = {
  offset?: number
  children: ReactNode
}

export function SidePanelContainer({ offset, children }: SidePanelProps) {
  const ref = useRef<HTMLDivElement>(null)
  const { height } = useResizeObserver({ ref })
  // 122 = default top offset
  const screenMinHeight = 122 + (height ?? 0)
  const isHighScreen = useMediaQuery(`(min-height: ${screenMinHeight}px)`)

  return (
    <Box sx={Styles.Wrapper(offset)}>
      {!isHighScreen && <Box sx={Styles.Filler(height)} />}
      <Box sx={Styles.StickyContainer(screenMinHeight)} ref={ref}>
        {children}
      </Box>
    </Box>
  )
}
