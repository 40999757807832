import { google } from 'calendar-link'
import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  Opportunity,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { ButtonText } from '@percent/lemonade'
import { AddToCalendarContainer } from './AddToCalendar.styles'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

export const formatOpportunityDescription = (opportunity: Pick<Opportunity, 'location' | 'description'>) => {
  const locationText =
    opportunity.location.type === OpportunityLocationType.OFFLINE
      ? `Location: ${opportunity.location.addressLineOne}, ${opportunity.location.city}, ${opportunity.location.country} ${opportunity.location.zipCode}`
      : opportunity.location.type === OpportunityLocationType.VIRTUAL && opportunity.location.link
      ? `Video conference link: <a href="${opportunity.location.link}">${opportunity.location.link}</a>`
      : undefined
  const calendarDescription = `
${locationText ?? ''}${
    locationText
      ? `

`
      : ''
  }Event page: <a href="${window.location.href}">${window.location.href}</a>

${opportunity.description}`

  return calendarDescription
}

export function AddToCalendar({ opportunity }: { opportunity: Opportunity }) {
  const { t } = useTranslation()
  const { track } = useAnalytics()

  const links = useMemo(() => {
    const location =
      opportunity.location.type === OpportunityLocationType.OFFLINE
        ? `${opportunity.location.addressLineOne}, ${
            opportunity.location.addressLineTwo ? `${opportunity.location.addressLineTwo}, ` : ''
          }${opportunity.location.city}, ${opportunity.location.country} ${opportunity.location.zipCode}`
        : null
    const event = {
      title: opportunity.name,
      description: formatOpportunityDescription(opportunity),
      start: opportunity.startDate,
      end: opportunity.endDate,
      ...(location && { location })
    }

    const googleUrl = google(event)

    return {
      google: googleUrl
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.id])

  return (
    <Box sx={AddToCalendarContainer} display="flex" alignItems="center">
      <Typography mr={0.5}>{t('workplace_giving.volunteering.calendar.addTo')}</Typography>
      <ButtonText
        onPress={() => {
          track({
            event: 'Volunteering Opportunity Add to Calendar',
            properties: {
              opportunityType: opportunity.type,
              opportunityLocationType: opportunity.location.type
            }
          })
          window.open(links.google, '_blank', 'noopener noreferrer')
        }}
      >
        {t('workplace_giving.volunteering.calendar.google')}
      </ButtonText>
    </Box>
  )
}
