import { FormikErrors } from 'formik'
import dayjs from 'dayjs'

import { OpportunityFormFields } from './OpportunityForm'
import { LocaleKey } from '@percent/workplace-giving/i18n/config'
import {
  OpportunityLocationType,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { config } from '@percent/workplace-giving/config/config'

type ValidateOpportunityFormProps = {
  values: OpportunityFormFields
  t: (key: LocaleKey, props?: any) => string
  minAttendees?: number | null
}

const DEFAULT_MAX_STRING_LENGTH = 255

const checkIfDateIsInTheFuture = (date: Date) => dayjs(date).isAfter(dayjs())

const checkIfEndDateIsAfterStart = (start: Date, end: Date) =>
  dayjs(end).isAfter(dayjs(start).add(5, 'minutes'), 'milliseconds')

const isValidURL = (link: string) => {
  let finalLink = link

  if (!/^(?:ht)tps?:\/\//.test(finalLink)) {
    finalLink = `http://${finalLink}`
  }

  const rUrl =
    // eslint-disable-next-line no-useless-escape
    /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

  const pattern = new RegExp(rUrl)

  return !!pattern.test(finalLink)
}

const validateRequiredAndMaxLength = (
  fieldValue: string | undefined,
  fieldName: keyof OpportunityFormFields,
  maxLength: number,
  t: ValidateOpportunityFormProps['t']
): Partial<FormikErrors<OpportunityFormFields>> => {
  const errors: Partial<FormikErrors<OpportunityFormFields>> = {}

  if (!fieldValue || fieldValue.trim().length === 0) {
    errors[fieldName] = t('workplace_giving.validation.requiredField')
  } else if (fieldValue.trim().length > maxLength) {
    errors[fieldName] = t('workplace_giving.validation.maxCharacters', {
      max: maxLength
    })
  }

  return errors
}

export const validateOpportunityForm = ({ values, t, minAttendees }: ValidateOpportunityFormProps) => {
  let errors: FormikErrors<OpportunityFormFields> = {}

  const {
    type,
    isSeries,
    name,
    description,
    startDate,
    endDate,
    locationType,
    locationUrl,
    participantSpots,
    addressLineOne,
    addressLineTwo,
    city,
    country,
    zipCode,
    childEvents
  } = values

  errors = {
    ...errors,
    ...validateRequiredAndMaxLength(name, 'name', DEFAULT_MAX_STRING_LENGTH, t),
    ...validateRequiredAndMaxLength(description, 'description', 5000, t)
  }

  if (type === OpportunityType.PROJECT) {
    if (startDate && !checkIfDateIsInTheFuture(startDate)) {
      errors.startDate = t('workplace_giving.validation.dateInTheFuture')
    }
  }

  if (type === OpportunityType.EVENT && !isSeries) {
    if (!startDate) {
      errors.startDate = t('workplace_giving.validation.requiredField')
    }

    if (startDate && !checkIfDateIsInTheFuture(startDate)) {
      errors.startDate = t('workplace_giving.validation.dateInTheFuture')
    }

    if (!endDate) {
      errors.endDate = t('workplace_giving.validation.requiredField')
    }

    if (endDate && startDate && !checkIfEndDateIsAfterStart(startDate, endDate)) {
      errors.endDate = t('workplace_giving.validation.endDateAfterStartDate')
    }
  }

  if (type === OpportunityType.PROJECT) {
    if (endDate && startDate && !checkIfEndDateIsAfterStart(startDate, endDate)) {
      errors.endDate = t('workplace_giving.validation.endDateAfterStartDate')
    }
  }

  if (!isSeries && !locationType.length) {
    errors.locationType = t('workplace_giving.validation.requiredField')
  }

  if (!isSeries && locationType === OpportunityLocationType.VIRTUAL) {
    if (locationUrl.trim().length > 2048) {
      errors.locationUrl = t('workplace_giving.validation.maxCharacters', { max: 2048 })
    }

    if (locationUrl.trim() && !isValidURL(locationUrl.trim())) {
      errors.locationUrl = t('workplace_giving.validation.url')
    }
  }

  if (locationType === OpportunityLocationType.OFFLINE) {
    errors = {
      ...errors,
      ...validateRequiredAndMaxLength(addressLineOne, 'addressLineOne', DEFAULT_MAX_STRING_LENGTH, t),
      ...validateRequiredAndMaxLength(city, 'city', DEFAULT_MAX_STRING_LENGTH, t),
      ...validateRequiredAndMaxLength(zipCode, 'zipCode', DEFAULT_MAX_STRING_LENGTH, t),
      ...validateRequiredAndMaxLength(country, 'country', DEFAULT_MAX_STRING_LENGTH, t)
    }

    if (addressLineTwo.trim().length > DEFAULT_MAX_STRING_LENGTH) {
      errors.addressLineTwo = t('workplace_giving.validation.maxCharacters', {
        max: DEFAULT_MAX_STRING_LENGTH
      })
    }
  }

  if (minAttendees && participantSpots && participantSpots < minAttendees) {
    errors.participantSpots = t('workplace_giving.volunteering.opportunityEdit.spotsGreaterThanParticipants', {
      count: minAttendees
    })
  }

  if (participantSpots && participantSpots >= config.maxParticipantSpots) {
    errors.participantSpots = t('workplace_giving.validation.maxAmount', { max: config.maxParticipantSpots })
  }

  if (type === OpportunityType.EVENT && isSeries) {
    if (!childEvents.length) {
      errors.childEvents = t('workplace_giving.validation.sessionRequired')
    }
  }

  return errors
}
