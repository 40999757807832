import { Box, Skeleton } from '@mui/material'

import * as Styles from './DonationFormSkeletonLoader.styles'

export function DonationFormSkeletonLoader() {
  return (
    <Box sx={Styles.LoaderWrapper} data-testid="donationFormLoader">
      <Box sx={Styles.LoaderContentWrapper}>
        <Skeleton variant="rectangular" width={176} height={4} style={{ borderRadius: '4px' }} />
        <Box sx={Styles.LoaderLogoBox}>
          <Skeleton sx={Styles.Logo} width={52} height={52} />
          <Box sx={Styles.LoaderTitleBox}>
            <Skeleton height={24} width={105} />
            <Skeleton height={24} width={65} />
          </Box>
        </Box>
        <Box flexGrow={1} width="100%">
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            style={{ borderRadius: '6px', marginBottom: '16px' }}
          />
          <Box sx={Styles.PresetValuesSkeleton}>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </Box>
          <Box sx={Styles.FlexColumn}>
            <Skeleton variant="rectangular" sx={Styles.InputSkeleton} />
          </Box>
          <Box sx={Styles.FlexRow}>
            <Skeleton
              variant="rectangular"
              width={16}
              height={16}
              style={{ marginRight: '12px', borderRadius: '100%' }}
            />
            <Skeleton variant="rectangular" width="92%" height={64} style={{ marginLeft: 'auto', marginRight: 0 }} />
          </Box>
        </Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={48}
          style={{ marginTop: '32px', marginBottom: '36px', borderRadius: '8px' }}
        />
      </Box>
    </Box>
  )
}
