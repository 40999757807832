import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const LocationLabelWrapper: Styles = {
  maxHeight: '24px !important',
  width: 'fit-content',
  padding: '4px 10px',
  backgroundColor: customPalette.gray90,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',

  svg: {
    height: '20px',
    width: '20px'
  }
}

export const Name: Styles = {
  fontWeight: lemonadeTheme.fontWeights.semiBold,
  fontSize: lemonadeTheme.sizes[10],
  lineHeight: lemonadeTheme.sizes[12]
}

export const DetailsText: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[6],
  color: customPalette.gray600
}

export const Role: Styles = {
  ...DetailsText,
  fontWeight: lemonadeTheme.fontWeights.medium
}
