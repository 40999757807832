export function WorldFlagAvatar() {
  return (
    <svg width="26" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2656_9489)">
        <mask id="mask0_2656_9489" maskUnits="userSpaceOnUse" x="1" y="1" width="28" height="20">
          <rect x="1" y="1" width="28" height="20" fill="white" />
        </mask>
        <g mask="url(#mask0_2656_9489)">
          <rect x="-1" y="1" width="32" height="20" fill="#5196ED" />
          <g clipPath="url(#clip1_2656_9489)">
            <path
              d="M21 11C21 9.81331 20.6481 8.65328 19.9888 7.66658C19.3295 6.67989 18.3925 5.91085 17.2961 5.45673C16.1997 5.0026 14.9933 4.88378 13.8295 5.11529C12.6656 5.3468 11.5965 5.91825 10.7574 6.75736C9.91825 7.59648 9.3468 8.66557 9.11529 9.82946C8.88378 10.9933 9.0026 12.1997 9.45673 13.2961C9.91085 14.3925 10.6799 15.3295 11.6666 15.9888C12.6533 16.6481 13.8133 17 15 17H15.06C16.635 16.9987 18.1451 16.3724 19.2588 15.2588C20.3724 14.1451 20.9987 12.635 21 11.06V11ZM20 11C19.9991 11.4444 19.9385 11.8867 19.82 12.315C19.8113 12.3495 19.7951 12.3817 19.7726 12.4093C19.7502 12.437 19.722 12.4594 19.69 12.475C19.6323 12.5077 19.5642 12.5167 19.5 12.5C19.1904 12.3667 18.9263 12.146 18.74 11.865L17.63 10.2C17.5157 10.0291 17.3609 9.88902 17.1795 9.79221C16.9981 9.6954 16.7956 9.64483 16.59 9.645H16.375C16.0103 9.645 15.6606 9.50014 15.4027 9.24227C15.1449 8.98441 15 8.63468 15 8.27C15 7.90533 15.1449 7.55559 15.4027 7.29773C15.6606 7.03987 16.0103 6.895 16.375 6.895H17.76C17.8119 6.89392 17.8628 6.90971 17.905 6.94C18.5517 7.40161 19.079 8.01068 19.4433 8.71673C19.8077 9.42278 19.9985 10.2055 20 11ZM10.09 10.08C10.0996 10.0222 10.1295 9.96969 10.1745 9.93208C10.2194 9.89447 10.2764 9.87422 10.335 9.875H12.5C12.7288 9.87202 12.9558 9.91471 13.1679 10.0006C13.3799 10.0864 13.5727 10.2137 13.735 10.375L14.3 10.95C14.6155 11.2657 14.7985 11.69 14.8115 12.1361C14.8245 12.5822 14.6666 13.0165 14.37 13.35L13.69 14.115C13.4866 14.3436 13.3744 14.639 13.375 14.945V15.36C13.3754 15.4019 13.365 15.4432 13.3449 15.48C13.3248 15.5168 13.2955 15.5478 13.26 15.57C13.2236 15.5885 13.1833 15.5981 13.1425 15.5981C13.1017 15.5981 13.0614 15.5885 13.025 15.57C12.1306 15.1855 11.368 14.5482 10.8306 13.7364C10.2932 12.9246 10.0046 11.9735 10 11C9.99924 10.6911 10.0294 10.3829 10.09 10.08Z"
              fill="#F7FCFF"
            />
          </g>
        </g>
      </g>
      <rect x="0.5" y="0.5" width="29" height="21" rx="1.5" stroke="#F1F0F2" />
      <defs>
        <clipPath id="clip0_2656_9489">
          <rect x="1" y="1" width="28" height="20" rx="1" fill="white" />
        </clipPath>
        <clipPath id="clip1_2656_9489">
          <rect width="12" height="12" fill="white" transform="translate(9 5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
