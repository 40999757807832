import Image from 'next/image'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import {
  RecentDonationsListProps,
  RecentDonationProps,
} from './recent-donations-list.types'
import { Spacer, Spinner, Text } from '@percent/lemonade'
import styled from 'styled-components'
import HeartImage from '../../assets/images/heart.svg'

dayjs.extend(relativeTime)

const StyledRecentDonationsTitle = styled.p`
  ${({ theme }) => `
      margin: 0;
      font-size: ${theme.fontSizes.bodyLarge};
      font-weight: 600;
      line-height: 32px;
      color: ${theme.colors.black};
    `}
`

const StyledRecentDonationsList = styled.ul`
  ${() => `
      margin: 0 0 16px;
      padding: 0;
      list-style-type: none;
    `}
`

const StyledRecentDonationsListItem = styled.li`
  ${({ theme }) => `
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: top;
      flex-wrap: wrap;
      padding: 16px 0;
      border-bottom: 1px solid ${theme.colors.gray80};
      color: ${theme.colors.gray900};
      font-size: ${theme.fontSizes.body};
      font-weight: 400;
      gap: 8px;

      span:last-of-type {
        flex-grow: 1;
        text-align: right;
      }
    `}
`

const StyledRecentDonationsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledRecentDonationsTextLine = styled.div`
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-start;
  gap: 8px;
`

const StyledLoadMoreButton = styled.button`
  ${({ theme }) => `
      margin-right: 20px;
      padding: 6px 0;
      border: none;
      background: transparent;
      cursor: pointer;
      text-decoration: underline;
      font-size: ${theme.fontSizes.bodySmall};
      line-height: 20px;
      letter-spacing: 0.5px;
      color: ${theme.colors.black};

      &:disabled {
        cursor: default;
        color: ${theme.colors.gray500};
      }
    `}
`

const StyledRecentDonationsError = styled.p`
  ${({ theme }) => `
      color: ${theme.colors.gray500};
  `}
`

const StyledShowMore = styled.div`
  display: flex;
  align-items: center;
`

const StyledRecentDonations = styled.div`
  @media only screen and (max-width: 768px) {
    margin-bottom: 48px;
  }
`

export function RecentDonation({
  donation,
  name,
  matchedOriginal,
  matchedBy,
  totalAmount,
}: RecentDonationProps) {
  return (
    <StyledRecentDonationsListItem key={donation.id}>
      <Image priority src={HeartImage} height={24} width={24} alt="Percent" />
      <StyledRecentDonationsInfo>
        <StyledRecentDonationsTextLine>
          <Text>{name}</Text>
          <Text>•</Text>
          <Text>{dayjs(donation.createdAt).fromNow()}</Text>
        </StyledRecentDonationsTextLine>
        {donation.matchedDonation && (
          <StyledRecentDonationsTextLine>
            <Text size="small">{matchedOriginal}</Text>
            <Text size="small">•</Text>
            <Text size="small">{matchedBy}</Text>
          </StyledRecentDonationsTextLine>
        )}
      </StyledRecentDonationsInfo>
      <Text fontWeight="medium">{totalAmount}</Text>
    </StyledRecentDonationsListItem>
  )
}

export function RecentDonationsList({
  loadMore,
  loading,
  errorText,
  showLoadMore,
  children,
  seeMoreText,
  totalDonationText,
  noDonationText,
}: RecentDonationsListProps) {
  if (errorText) {
    return (
      <div>
        <StyledRecentDonationsError>{errorText}</StyledRecentDonationsError>
      </div>
    )
  }

  return (
    <StyledRecentDonations>
      <StyledRecentDonationsTitle>
        {totalDonationText}
      </StyledRecentDonationsTitle>
      <Spacer size={4} axis="vertical" />
      {noDonationText ? (
        <Text>{noDonationText}</Text>
      ) : (
        <>
          <StyledRecentDonationsList aria-label="recent donations">
            {children}
          </StyledRecentDonationsList>
          <StyledShowMore>
            {showLoadMore && (
              <StyledLoadMoreButton
                disabled={loading}
                type="button"
                onClick={loadMore}
              >
                {seeMoreText}
              </StyledLoadMoreButton>
            )}
            {loading && <Spinner size="m" />}
          </StyledShowMore>
        </>
      )}
    </StyledRecentDonations>
  )
}
