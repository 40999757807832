import { Axios } from 'axios'
import { omit } from 'lodash'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'
import { Opportunity } from '../../search/searchOpportunities/searchOpportunities.types'

import { UpdateOpportunityParams } from './edit-opportunity.types'
import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'

export const editOpportunityDetails: MutationFn<[UpdateOpportunityParams], GsResponse<Opportunity>> = async (
  client: Axios,
  [params]
) => {
  const serialized = {
    name: params.name,
    description: params.description,
    location: params.location ? JSON.stringify(omit(params.location, ['type'])) : undefined,
    startDate: params.startDate ? params.startDate.toUTCString() : '',
    endDate: params.endDate ? params.endDate.toUTCString() : '',
    activities: params.activities ? JSON.stringify(params.activities) : undefined,
    skills: params.activities ? JSON.stringify(params.skills) : undefined,
    participantSpots: params.participantSpots,
    itinerary: params.itinerary ? JSON.stringify(params.itinerary) : undefined,
    image: params.image
  }

  const data = Object.entries(serialized).reduce<FormData>((acc, [key, value]) => {
    if (typeof value === 'string' || typeof value === 'number') acc.append(key, value.toString())
    else if (value !== undefined && value !== null) acc.append(key, value)

    return acc
  }, new FormData())

  const requestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true,
    validateStatus: (_: number) => true
  }

  const response = await client.patch<RawResponse<Opportunity>>(`/opportunities/${params.id}`, data, requestConfig)

  return toGsResponse(response.status, response.data)
}
