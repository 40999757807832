import { Axios } from 'axios'

import { config } from '@percent/workplace-giving/config/config'

const TIMEOUT = 2 * 1000

export type GetGeolocationDataResponse = {
  time_zone?: {
    name: string
  }
  country_code3: string
}

export const getGeolocationData = async (client: Axios) =>
  client
    .get<GetGeolocationDataResponse>(`https://api.ipgeolocation.io/ipgeo?apiKey=${config.keys.geolocation}`, {
      timeout: TIMEOUT,
      validateStatus: null
    })
    .then(r =>
      r.status === 200
        ? {
            countryCode: r.data.country_code3,
            timeZone: r.data.time_zone?.name
          }
        : undefined
    )
    .catch(() => undefined)
