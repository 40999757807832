import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

import { Money, formatMoney } from '@percent/utility'

type MoneyOptions = Omit<Intl.NumberFormatOptions, 'currency' | 'currencySign'> & {
  locale?: string
  forceLocale?: boolean
  hideDecimalsIfZero?: boolean
}

export const useMoneyFormat = () => {
  const {
    i18n: { language }
  } = useTranslation()

  const moneyFormat = useCallback(
    (
      amount: Money,
      { locale = language, forceLocale = false, hideDecimalsIfZero = false, ...options }: MoneyOptions = {
        locale: language,
        forceLocale: false
      }
    ): string => formatMoney(amount, { locale, forceLocale, hideDecimalsIfZero, ...options }),
    [language]
  )

  return {
    moneyFormat
  }
}
