import { CSSProperties } from 'react'

export const CloseButton: CSSProperties = {
  display: 'flex',
  width: '28px',
  height: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: '#F0F0F1',
  borderRadius: '4px',
  border: 'none',
  cursor: 'pointer',
  padding: '0px'
}

export const Container = ({ width }: { width: number }): CSSProperties => ({
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  width: width < 450 ? '100%' : '400px',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: 'white'
})
