import { SearchResponse } from '../../goodstack'

import { InfiniteQueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { Opportunity, SearchOpportunitiesQuery } from './searchOpportunities.types'

export const searchOpportunities: InfiniteQueryFn<
  [string, SearchOpportunitiesQuery],
  string,
  SearchResponse<Opportunity>
> = async (
  client,
  [_, { type, locationType, relevantSkills, country, city, query, direction = 'ASC', pageSize = 25 }],
  cursor
) =>
  (
    await (cursor
      ? client.get<SearchResponse<Opportunity>>(cursor, {
          withCredentials: true
        })
      : client.get<SearchResponse<Opportunity>>(`/opportunities-search`, {
          params: { pageSize, direction, query, relevantSkills, country, city, locationType, type },
          withCredentials: true
        }))
  ).data
