import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { FundraiserDetails } from './getFundraiserDetails.types'

export const getFundraiserDetails: QueryFn<[string, string], FundraiserDetails> = async (client, [_, id]) => {
  const { data } = await client.get<{ data: FundraiserDetails }>(`/fundraisers/${id}/details`, {
    withCredentials: true
  })

  return data.data
}
