import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AddToCalendar } from '../OpportunityDetailsSidebar/AddToCalendar/AddToCalendar'
import { CancelAttendance } from '../OpportunityDetailsSidebar/OpportunityDetailsSidebar.styles'

import {
  Opportunity,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { SessionEventDetails } from '@percent/workplace-giving/common/components/SessionEventDetails/SessionEventDetails'
import * as Styles from './OpportunitySessions.styles'
import { Badge, Button, ButtonText } from '@percent/lemonade'
import { RegisterAction } from '@percent/workplace-giving/api/opportunity/registerForOpportunity/registerForOpportunity.types'
import { dayjs } from '@percent/workplace-giving/utils/dayjs/dayjs'

type SessionItemProps = {
  opportunity: Opportunity
  handleRegisterAction: (action: RegisterAction, opportunity: Opportunity) => void
  isLoading: boolean
}

export function SessionItem({ opportunity, handleRegisterAction, isLoading }: Readonly<SessionItemProps>) {
  const { t } = useTranslation()
  const { id, startDate, endDate, location, participation, participants, participationStatus } = opportunity

  const spotsLeft = participants?.spots ? participants.spots - participants.count : undefined
  const eventJoined = participationStatus === 'registered' || participation?.status === 'participating'
  const sessionEnded = endDate ? dayjs(endDate) <= dayjs(new Date()) : false

  return (
    <Box sx={Styles.ItemWrapper} data-testid="opportunity-session-item">
      <SessionEventDetails id={id} startDate={new Date(startDate!)} location={location} endDate={new Date(endDate!)}>
        <Box sx={Styles.AdditionalInfo}>
          {spotsLeft !== undefined ? (
            <Badge variant={spotsLeft ? 'informative' : 'default'} icon={spotsLeft ? 'clock' : 'check'}>
              {spotsLeft
                ? t('workplace_giving.volunteering.spotsRemaining', { count: spotsLeft })
                : t('workplace_giving.volunteering.fullyBooked')}
            </Badge>
          ) : null}

          {eventJoined ? (
            <Box>
              <AddToCalendar opportunity={opportunity} />
              <Box sx={CancelAttendance}>
                <Typography mr={0.5}> {t('workplace_giving.volunteering.cantMakeIt')}</Typography>
                <ButtonText
                  disabled={isLoading}
                  onPress={() => handleRegisterAction(RegisterAction.unregister, opportunity)}
                >
                  {t('workplace_giving.volunteering.cancelYourAttendance')}
                </ButtonText>
              </Box>

              {location.type === OpportunityLocationType.VIRTUAL && location.link ? (
                <Button
                  data-testid="join-virtual-session-btn"
                  size="small"
                  onPress={() => {
                    window.open(location.link!, '_blank', 'noopener noreferrer')
                  }}
                >
                  {t('workplace_giving.volunteering.join')}
                </Button>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </SessionEventDetails>
      <Box sx={Styles.ActionsWrapper}>
        {eventJoined ? (
          <Badge variant="positive" icon="approve">
            {t('workplace_giving.volunteering.alert.youAreAttending')}
          </Badge>
        ) : null}
        {!eventJoined && !sessionEnded && (spotsLeft === undefined || !!spotsLeft) ? (
          <Button
            data-testid="register-for-session-btn"
            size="small"
            variant="secondary"
            loading={isLoading}
            onPress={() => handleRegisterAction(RegisterAction.register, opportunity)}
          >
            {t('workplace_giving.volunteering.join')}
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}
