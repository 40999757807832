import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { Alpha3Code } from 'i18n-iso-countries'

import { DonationCard, DonationCardLine, DonationCardSeparator, DonationCardSummary } from '../Card/DonationCard'

import { resendReceipt } from '@percent/workplace-giving/api/donation/resend-receipt/resend-receipt'
import { useQuery, useAuth, useMutation } from '@percent/workplace-giving/common/hooks'
import { formatMoney } from '@percent/utility'
import { Donation } from '@percent/workplace-giving/api/donation/retrieve-donations/retrieve-donation-list.types'
import { getPartnerFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { formatRelativeTime } from '@percent/workplace-giving/utils/dayjs/dayjs'
import { DonationBadgeVariant } from '@percent/workplace-giving/common/components/DonationBadge/DonationBadge.types'
import { getFundraiser } from '@percent/workplace-giving/api/fundraiser/getFundraiser/getFundraiser'
import { IllustratedMessage, useToast } from '@percent/lemonade'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'

interface DonationCardProps {
  donation: Donation
}

const matchBadgeVariants = (donation: Donation): DonationBadgeVariant[] => {
  const badgeVariants: DonationBadgeVariant[] = []

  if (donation.fundraiserId) {
    badgeVariants.push('fundraiser')
  }

  if (donation.type === 'wallet') {
    badgeVariants.push('wallet')
  }

  if (donation.matchRequestId) {
    badgeVariants.push('external')
  }

  if (donation.subscriptionId) {
    badgeVariants.push('recurring')
  }

  return badgeVariants
}

export function PastDonationCard({ donation }: DonationCardProps) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()
  const [resendReceiptEnabled, setResendReceiptEnabled] = useState(true)
  const authState = useAuth()
  const partner = getPartnerFromAuthState(authState.state)
  const badgeVariant = useMemo(() => matchBadgeVariants(donation), [donation])
  const { addToast } = useToast()

  const { formatDate } = useDateTimeFmt()
  const createdDate = formatRelativeTime(donation.createdAt, t, formatDate)

  const donationMatchedAmount = donation.matchedDonation ? donation.matchedDonation.amount : 0

  const donationAmount = formatMoney({ amount: donation.amount, currency: donation.currency }, { locale })

  const walletAmount = donation.type === 'wallet' ? donationAmount : undefined

  const contribution = walletAmount ? undefined : donationAmount

  const total = formatMoney(
    {
      amount: donation.amount + donationMatchedAmount,
      currency: donation.currency
    },
    { locale }
  )

  const { data: fundraiserData, error } = useQuery(['getFundraiser', donation.fundraiserId], getFundraiser, {
    enabled: !!donation.fundraiserId
  })

  const { mutateAsync } = useMutation({
    mutationFn: resendReceipt,
    onSuccess: () => {
      addToast(t('workplace_giving.pastDonation.resendReceipt.success'), 'success')
      setTimeout(() => setResendReceiptEnabled(true), 4000)
    },
    onError: () => {
      addToast(t('workplace_giving.pastDonation.resendReceipt.error'), 'error')
      setTimeout(() => setResendReceiptEnabled(true), 4000)
    }
  })

  const onResendReceiptClick = () => {
    setResendReceiptEnabled(false)
    mutateAsync({ donationId: donation.id })
  }

  if (error) {
    return (
      <Box marginTop="24px" data-testid="past-donation-list-error">
        <IllustratedMessage
          illustration="general-error"
          title={t('workplace_giving.errors.somethingWentWrong')}
          description={t('workplace_giving.errors.tryAgainLater')}
        />
      </Box>
    )
  }

  const donationStatus =
    donation.status === 'DISBURSED' && (!donation.matchedDonation || donation.matchedDonation.status === 'DISBURSED')
      ? 'paymentSent'
      : 'processing'

  return (
    <DonationCard
      organisation={donation.organisation}
      fundraiserName={fundraiserData?.page.title}
      badgeVariants={badgeVariant}
      date={createdDate}
      fundraiserId={fundraiserData?.id}
      resendReceiptVisible={!donation.matchRequestId}
      resendReceiptEnabled={resendReceiptEnabled}
      onResendReceiptClick={onResendReceiptClick}
      donationStatus={donationStatus}
    >
      {contribution && (
        <DonationCardLine
          description={t('workplace_giving.pastDonation.card.contribution')}
          value={contribution}
          orgCountryCode={donation.organisation.countryCode as Alpha3Code}
          showTaxInfo={!!(contribution && donation.type === 'hosted')}
        />
      )}
      {walletAmount && (
        <DonationCardLine description={t('workplace_giving.pastDonation.card.wallet_balance')} value={walletAmount} />
      )}
      {donation.matchedDonation && (
        <DonationCardLine
          description={t('workplace_giving.pastDonation.card.matched', { partnerName: partner?.name })}
          value={formatMoney(
            { amount: donation.matchedDonation.amount, currency: donation.matchedDonation.currency },
            { locale }
          )}
        />
      )}
      <DonationCardSeparator />
      <DonationCardSummary description={t('workplace_giving.pastDonation.card.total')} value={total} />
    </DonationCard>
  )
}
