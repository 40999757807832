import styled from 'styled-components'
import { OrganisationNameAndFlagProps } from './organisation-name-and-flag.types'
import { FlagAvatar, Text } from '@percent/lemonade'
import { Alpha2Code, Alpha3Code, toAlpha2 } from 'i18n-iso-countries'

const StyledNameAndFlag = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  & > p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
`

export const OrganisationNameAndFlag = ({
  name,
  countryCode,
}: OrganisationNameAndFlagProps) => {
  return (
    <StyledNameAndFlag>
      <Text as="p" size="small" dataTestId="organisation-name">
        {name}
      </Text>
      <FlagAvatar code={toAlpha2(countryCode as Alpha3Code) as Alpha2Code} />
    </StyledNameAndFlag>
  )
}
