import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { SSOLoginProps, SSOLoginResponse } from './ssoLogin.types'
import { config } from '@percent/workplace-giving/config/config'

type APIResponse = {
  data: {
    loginUrl: string
  }
  object: string
}

export const ssoLogin: MutationFn<SSOLoginProps, SSOLoginResponse> = async (client, { email, redirectUrl }) => {
  try {
    const res = await client.post<APIResponse>(
      `/auth/sso/login`,
      {
        email,
        redirectUrl: redirectUrl ?? config.urls.appUrl
      },
      {
        withCredentials: false
      }
    )

    return {
      result: 'success',
      data: res.data.data
    }
  } catch (error) {
    if (error?.response?.data?.error?.code === 'sso/partner_not_configured') {
      return {
        result: 'error',
        code: 'sso/partner_not_configured'
      }
    }
    throw error
  }
}
