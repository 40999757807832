import { AxiosInstance } from 'axios'

import { Partner } from '@percent/workplace-giving/context/auth/authContext/AuthContext.types'

export type GetAccountPartnerResponse = {
  data: Partner
  object: string
}

export const getAccountPartner = async (client: AxiosInstance) => {
  const { data } = await client.get<GetAccountPartnerResponse>('/account/partners', {
    withCredentials: true
  })

  return data.data
}
