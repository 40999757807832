import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { SplitFactory } from '@splitsoftware/splitio-react'

import '@percent/workplace-giving/utils/dayjs/dayjs'
import { sendToVercelAnalytics, reportWebVitals } from '@percent/utility'
import { splitSdkConfig } from '@percent/workplace-giving/providers/SplitSdkProvider'
import { App } from '@percent/workplace-giving/app/app-entry'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <StrictMode>
    <SplitFactory config={splitSdkConfig}>
      <App />
    </SplitFactory>
  </StrictMode>
)

reportWebVitals(sendToVercelAnalytics)
