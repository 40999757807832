/* eslint-disable no-underscore-dangle */

import { useQuery } from '../useQuery/useQuery'
import { UseQueryOptions } from '../useQuery/useQuery.types'

import { ListResponse, QueryListFunction } from '@percent/workplace-giving/api/goodstack'

export const getQueryParamsFromUrl = <
  A = {
    [k: string]: string
  }
>(
  apiURL: string
): A => {
  const url = new URL(apiURL)
  const searchParams = new URLSearchParams(url.search)

  return Object.fromEntries(searchParams) as unknown as A
}

export const useQueryList = <
  TParams extends { [key: string]: any },
  Key extends string = string,
  TError = unknown,
  TResponse extends ListResponse<unknown> = ListResponse<unknown>
>(
  key: Key,
  params: TParams,
  query: QueryListFunction<Key, TParams, TResponse>,
  setParams: (params: TParams) => void,
  options?: UseQueryOptions<TParams, TParams, TError, TResponse>
) => {
  const result = useQuery([key, params], query, options)

  const nextPageParams = result?.data?._links?.next ? getQueryParamsFromUrl(result.data._links.next) : undefined
  const prevPageParams = result?.data?._links?.prev ? getQueryParamsFromUrl(result.data._links.prev) : undefined
  const fetchNextPage = () => nextPageParams && setParams(nextPageParams as TParams)
  const fetchPreviousPage = () => prevPageParams && setParams(prevPageParams as TParams)

  return {
    hasNextPage: !!result.data?._links?.next,
    hasPreviousPage: !!result.data?._links?.prev,
    fetchNextPage,
    fetchPreviousPage,
    ...result
  }
}
