import { Box } from '@mui/material'

import { TabProps } from './Tab.types'

export function Tab({ children, value, index }: TabProps) {
  if (value === index) {
    return <Box>{children}</Box>
  }

  return null
}
