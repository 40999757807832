import { Axios } from 'axios'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'
import { Opportunity } from '../../search/searchOpportunities/searchOpportunities.types'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CreateEventSeriesSessionParams } from './create-opportunity.types'

export const createEventSeriesSession: MutationFn<[CreateEventSeriesSessionParams], GsResponse<Opportunity>> = async (
  client: Axios,
  [params]
) => {
  const serialized = {
    type: params.type,
    name: params.name,
    parentId: params.parentId,
    location: JSON.stringify(params.location),
    startDate: params.startDate.toUTCString(),
    endDate: params.endDate.toUTCString(),
    participantSpots: params.participantSpots,
    itinerary: params.itinerary?.length ? JSON.stringify(params.itinerary) : undefined
  }

  const data = Object.entries(serialized).reduce<FormData>((acc, [key, value]) => {
    if (value !== null && value !== undefined) acc.append(key, value.toString())

    return acc
  }, new FormData())

  const requestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true,
    validateStatus: (_: number) => true
  }

  const response = await client.post<RawResponse<Opportunity>>('/opportunities', data, requestConfig)

  return toGsResponse(response.status, response.data)
}
