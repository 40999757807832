import { Axios } from 'axios'

import { GsResponse, RawResponse, toGsResponse } from '../../goodstack'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CreateDonationMatchRequest, DonationMatchRequest } from './create-donation-match-request.types'

export const createDonationMatchRequest: MutationFn<
  CreateDonationMatchRequest,
  GsResponse<DonationMatchRequest>
> = async (client: Axios, params: CreateDonationMatchRequest) => {
  const data = new FormData()

  if (params.organisationId) data.append('organisationId', params.organisationId)

  if (params.organisationDetails) data.append('organisationDetails', JSON.stringify(params.organisationDetails))
  data.append('amount', `${params.amount}`)
  data.append('currency', params.currency)
  data.append('receipt', params.receipt)

  if (params.externalMatchingUrl) data.append('externalMatchingUrl', params.externalMatchingUrl)

  const response = await client.post<RawResponse<DonationMatchRequest>>('/donation-match-requests', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true,
    validateStatus: (_: number) => true
  })

  return toGsResponse(response.status, response.data)
}
