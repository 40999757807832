import { Box, Skeleton } from '@mui/material'

import * as Styles from './OnboardingPopup.styles'

export function OnboardingPopupSkeleton({ withPreferredNameField }: { withPreferredNameField: boolean }) {
  return (
    <Box data-testid="onboardingSkeleton">
      <Box sx={Styles.Header}>
        <Box width="100%">
          <Skeleton variant="rectangular" height={24} width="94%" />
          <Skeleton
            variant="rectangular"
            height={16}
            width="65%"
            style={{
              marginTop: 8
            }}
          />
        </Box>
        <Skeleton
          variant="circular"
          height={72}
          width={72}
          style={{
            minWidth: 72,
            marginLeft: 8
          }}
        />
      </Box>
      <Box sx={Styles.Form}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" height={21} width="30%" />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={21} width="70%" />
        </Box>
        {withPreferredNameField ? (
          <Box display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="rectangular" height={21} width="50%" />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={21} width="60%" />
          </Box>
        ) : null}
        <Box display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" height={21} width="60%" />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={42} />
        </Box>
        <Box sx={Styles.Spacer} />
        <Skeleton variant="rectangular" height={48} />
        <Box display="flex" flexDirection="row" gap={1.5}>
          <Skeleton variant="rectangular" height={20} width={20} />
          <Skeleton variant="rectangular" height={40} width="calc(100% - 20px)" />
        </Box>
        <Box sx={Styles.ButtonsWrapper}>
          <Skeleton variant="rectangular" height={40} style={{ borderRadius: '0.25rem' }} />
          <Skeleton variant="rectangular" height={40} style={{ borderRadius: '0.25rem' }} />
        </Box>
      </Box>
    </Box>
  )
}
