import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const Container: Styles = {
  maxWidth: '100%',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: lemonadeTheme.colors.white,
  overflow: 'hidden',

  '@media (min-width: 768px)': {
    borderRadius: '8px'
  },

  '& > *': {
    fontFamily: 'Inter, sans-serif !important'
  }
}

export const ContentWrapper: Styles = {
  boxSizing: 'border-box',
  padding: '24px',
  overflowY: 'auto',
  height: '100vh',

  '@media (min-width: 768px)': {
    height: 'auto',
    maxHeight: '92vh',
    padding: '16px 20px'
  }
}

export const Header: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid',
  borderColor: lemonadeTheme.colors.gray80,
  paddingBottom: '15px',
  marginBottom: lemonadeTheme.sizes[4]
}

export const Title: Styles = {
  fontSize: lemonadeTheme.fontSizes.h6,
  color: lemonadeTheme.colors.black,
  fontWeight: '600'
}

export const ExitButton: Styles = {
  display: 'flex',
  minWidth: '28px',
  height: '28px',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  padding: 0,

  '&:hover': {
    backgroundColor: `${customPalette.primary100} !important`
  },

  '&:focus': {
    outline: `1px solid ${customPalette.primary}`
  }
}

export const SubmitButtonWrapper: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap-reverse',
  gap: 2,
  marginTop: '16px',

  [queryWidthLessThan('xs')]: {
    gap: 1
  }
}

export const ItemWrapper = (inactive: boolean): Styles => ({
  opacity: inactive ? 0.6 : 1,
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  gap: lemonadeTheme.sizes[4],
  padding: lemonadeTheme.sizes[4],
  borderRadius: lemonadeTheme.borderRadii.large,
  border: `2px solid ${customPalette.info30}`,
  boxShadow: 'none'
})

export const CalendarWrapper: Styles = {
  width: lemonadeTheme.sizes[10],
  minWidth: lemonadeTheme.sizes[10],
  height: lemonadeTheme.sizes[10],
  borderRadius: lemonadeTheme.borderRadii.medium
}

export const Calendar: Styles = {
  ...CalendarWrapper,
  display: 'flex',
  flexFlow: 'column',
  flexWrap: 'nowrap',
  overflow: 'hidden',

  '& > span': {
    width: '100%',
    textAlign: 'center',
    fontWeight: lemonadeTheme.fontWeights.bold,
    textTransform: 'uppercase',

    '&:first-of-type': {
      height: lemonadeTheme.sizes[4],
      fontSize: '10px',
      lineHeight: lemonadeTheme.sizes[4],
      color: customPalette.gray600,
      backgroundColor: customPalette.gray90
    },

    '&:last-of-type': {
      height: lemonadeTheme.sizes[6],
      fontSize: '13px',
      lineHeight: lemonadeTheme.sizes[6],
      backgroundColor: customPalette.gray70
    }
  }
}

export const DetailsWrapper: Styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: lemonadeTheme.sizes[2],
    marginBottom: lemonadeTheme.sizes[2],

    '&:last-of-type': {
      marginBottom: 0
    }
  }
}
