import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { CurrencyCode, Money } from '@percent/utility'

type DonationSummaryParams = {
  currencyCode: CurrencyCode
}

export type DonationSummaryResponse = { totalDonated: Money; donated: Money; matched: Money }

export const donationSummary: QueryFn<[string, DonationSummaryParams], DonationSummaryResponse> = async (
  client,
  [_, params]
) => {
  const response = await client.get<{ data: DonationSummaryResponse }>('/account/donations/donation-summary', {
    params
  })

  return response.data.data
}
