import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import {
  Opportunity,
  OpportunityLocationType,
  OpportunityType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { Description } from '@percent/workplace-giving/common/components'

export function OpportunityDescription({
  image,
  type,
  name,
  description,
  location,
  startDate,
  itinerary,
  isSeries
}: Readonly<Opportunity>) {
  const { t } = useTranslation()
  const tags = useMemo(() => {
    return [
      type === OpportunityType.PROJECT
        ? t('workplace_giving.volunteering.project')
        : t('workplace_giving.volunteering.event'),
      ...(!isSeries
        ? [
            t(
              `workplace_giving.volunteering.filter.${
                location.type === OpportunityLocationType.OFFLINE ? 'inPerson' : 'virtual'
              }`
            )
          ]
        : [])
    ]
  }, [isSeries, location.type, t, type])

  return (
    <Description
      image={image}
      name={name}
      story={description}
      tags={tags}
      location={location}
      startDate={startDate}
      itinerary={itinerary}
    />
  )
}
