import { useTranslation } from 'react-i18next'

import { DonationBadgeVariant } from '@percent/workplace-giving/common/components/DonationBadge/DonationBadge.types'
import { Badge, BadgeVariant, IconRegistry } from '@percent/lemonade'

interface DonationBadgeProps {
  variant: DonationBadgeVariant
  label?: string
}

const variantMap: Record<DonationBadgeVariant, BadgeVariant> = {
  pending: 'informative',
  approved: 'positive',
  rejected: 'critical',
  recurring: 'default',
  external: 'default',
  fundraiser: 'default',
  wallet: 'default'
}

const iconMap: Record<DonationBadgeVariant, keyof typeof IconRegistry> = {
  pending: 'clock',
  approved: 'check-badge',
  rejected: 'reject',
  recurring: 'recurring',
  external: 'expand',
  fundraiser: 'love-it-circle',
  wallet: 'coin'
}

export function DonationBadge({ variant, label }: DonationBadgeProps) {
  const { t } = useTranslation()

  return (
    <Badge variant={variantMap[variant]} icon={iconMap[variant]}>
      {label ?? t(`workplace_giving.myImpact.donationBadge.${variant}`)}
    </Badge>
  )
}
