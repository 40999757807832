import { useAuth } from '../useAuth/useAuth'
import { useQuery } from '../useQuery/useQuery'

import { getAccountConfigFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { getPartnerCurrencies } from '@percent/workplace-giving/api/currency/supported-currencies/supported-currencies'
import { getCurrencies } from '@percent/workplace-giving/api/currency/currencies'
import { CurrencyCode } from '@percent/utility'

export const useCurrencyListGroup = () => {
  const { state } = useAuth()
  const accountConfig = getAccountConfigFromAuthState(state)
  const accountCurrencyCode = accountConfig?.currency

  const { data: currencyInfo, isLoading: currencyInfoLoading } = useQuery(['getCurrencies'], getCurrencies)

  const { data: partnerCurrencyInfo, isLoading: partnerCurrencyInfoLoading } = useQuery(
    ['getPartnerCurrencies'],
    getPartnerCurrencies
  )

  if (currencyInfoLoading || partnerCurrencyInfoLoading) {
    return []
  }

  const partnerCurrencyList =
    (Array.isArray(partnerCurrencyInfo) &&
      partnerCurrencyInfo?.length !== 0 &&
      (partnerCurrencyInfo?.filter(f => f !== accountCurrencyCode).sort() as CurrencyCode[])) ||
    []

  return [
    {
      title: 'your currency',
      currencies: [accountCurrencyCode!]
    },
    {
      title: 'suggested',
      currencies: partnerCurrencyList
    },
    {
      title: 'all',
      currencies: currencyInfo
        ?.filter(f => !partnerCurrencyList.some(v => f.code === v))
        .filter(f => f.code !== accountCurrencyCode)
        .filter(f => f.supportedByStripe === true)
        .map(m => m.code)
        .sort() as CurrencyCode[]
    }
  ].filter(f => f.currencies.length !== 0)
}
