import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Title: Styles = {
  marginBottom: lemonadeTheme.sizes.s,
  color: customPalette.gray200,
  fontSize: lemonadeTheme.fontSizes.h5,
  fontWeight: lemonadeTheme.fontWeights.semiBold,
  lineHeight: lemonadeTheme.sizes[8]
}

export const SupportedByColleaguesInfo: Styles = {
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'nowrap',
  marginTop: lemonadeTheme.sizes.m,
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes.m,
  color: customPalette.gray600,

  span: {
    marginX: lemonadeTheme.sizes.xxs,
    fontWeight: lemonadeTheme.fontWeights.semiBold
  },

  '& > svg': {
    width: lemonadeTheme.sizes.m,
    height: lemonadeTheme.sizes.m,
    marginRight: lemonadeTheme.sizes.xs
  }
}
