import { Typography } from '@mui/material'

import { LayoutProps } from './Layout.types'
import * as Styles from './Layout.styles'

export function Layout({ children }: LayoutProps) {
  return (
    <Typography component="main" sx={Styles.MainWrapper}>
      {children}
    </Typography>
  )
}
