import { GsResponse, toGsResponse } from '../../goodstack'

import {
  RegisterForOpportunityProps,
  RegisterForOpportunityResponse
} from '@percent/workplace-giving/api/opportunity/registerForOpportunity/registerForOpportunity.types'
import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'

export const registerForOpportunity: MutationFn<
  RegisterForOpportunityProps,
  GsResponse<RegisterForOpportunityResponse>
> = async (client, { id, action, timeZone }) => {
  return client
    .post(
      `/opportunities/${id}/${action}`,
      {
        timeZone
      },
      {
        withCredentials: true,
        validateStatus: (_: number) => true
      }
    )
    .then(res => toGsResponse(res.status, res.data))
}
