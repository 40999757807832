import { Styles } from '@percent/workplace-giving/theme'
import { customPalette } from '@percent/workplace-giving/theme/theme'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Wrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  backgroundColor: customPalette.gray70,

  [queryWidthLessThan('l')]: {
    backgroundColor: 'white'
  }
}

export const Card: Styles = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '456px',
  width: '100%',
  padding: '16px 56px 32px',
  borderRadius: '8px',

  img: {
    margin: '30px 0',
    maxHeight: '28px',
    height: '200%',
    width: 'auto'
  },

  textAlign: 'left',

  [queryWidthLessThan('l')]: {
    padding: `${lemonadeTheme.sizes.xl} ${lemonadeTheme.sizes.m}`
  }
}

export const Title: Styles = {
  margin: '12px 0 36px',
  [queryWidthLessThan('l')]: {
    fontSize: lemonadeTheme.sizes[10],
    width: '100%',
    lineHeight: '45px'
  }
}

export const Description: Styles = {
  margin: '0 0 26px',
  textAlign: 'center',
  [queryWidthLessThan('l')]: {
    width: '100%',
    textAlign: 'left'
  }
}

export const ContentWrapper: Styles = {
  width: '100%',
  button: {
    width: ' 100%',
    margin: '40px 0'
  }
}

export const Form: Styles = {
  '& > div': {
    marginBottom: '16px'
  }
}

export const LinksWrapper = ({ centered }: { centered?: boolean }): Styles => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: centered ? 'center' : 'space-between',
  alignItems: 'center',

  a: {
    color: customPalette.primary,
    transition: 'color 250ms',
    textDecoration: 'none',
    paddingBottom: '3px',
    borderBottom: '1px solid',
    borderColor: customPalette.primary,

    '&:hover': {
      color: customPalette.primary600,
      borderColor: customPalette.primary600
    },

    '&:first-of-type': {
      color: centered ? customPalette.primary : customPalette.gray600,
      borderColor: centered ? customPalette.primary : customPalette.gray600
    }
  },

  [queryWidthLessThan('l')]: {
    flexDirection: 'column',
    gap: lemonadeTheme.sizes[4]
  }
})

export const ErrorMessage: Styles = {
  textAlign: 'center',
  color: customPalette.alert400
}
