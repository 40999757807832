import { QueryFn } from '@percent/workplace-giving/common/hooks/useQuery/useQuery.types'
import { FundraiserTotals } from './getFundraiserTotals.types'

export const getFundraiserTotals: QueryFn<[string, string], FundraiserTotals> = async (client, [_, id]) => {
  const { data } = await client.get<{ data: FundraiserTotals }>(`/fundraisers/${id}/totals`, {
    withCredentials: true
  })

  return data.data
}
