import { Styles } from '@percent/workplace-giving/theme'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Table: Styles = {
  border: `1px solid ${customPalette.gray80}`,
  borderRadius: lemonadeTheme.borderRadii.medium
}

export const TableRow: Styles = {
  th: {
    borderBottom: `1px solid ${customPalette.gray80}`
  }
}

export const TableRowHeader: Styles = {
  th: {
    borderBottom: `1px solid ${customPalette.gray80}`
  }
}

export const TableFooter: Styles = {
  borderTop: `1px solid ${customPalette.gray80}`
}

export const Chip: Styles = {
  color: customPalette.gray600,

  '&.Mui-disabled': {
    opacity: 1,
    fontWeight: lemonadeTheme.fontWeights.medium,
    color: customPalette.black,
    backgroundColor: customPalette.gray70
  }
}

export const LocationLabelWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  padding: '2px 10px',
  backgroundColor: customPalette.gray90,
  borderRadius: lemonadeTheme.borderRadii.medium,

  svg: {
    height: lemonadeTheme.sizes[5],
    width: lemonadeTheme.sizes[5],
    minWidth: lemonadeTheme.sizes[5],
    marginRight: lemonadeTheme.sizes[1]
  }
}
