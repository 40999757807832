import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom-v6'

import { FullPageFormLayout } from '@percent/lemonade'
import { WizardHeader } from '@percent/workplace-giving/common/components/WizardHeader/WizardHeader'
import * as Styles from './FundraiserWizard.styles'
import { FundraiserWizardForm } from './FundraiserWizardForm'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { createFundraiser } from '@percent/workplace-giving/api/fundraiser/createFundraiser/createFundraiser'
import {
  getAccountConfigFromAuthState,
  getCountryCodeFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth, useCurrencyListGroup, useLogger } from '@percent/workplace-giving/common/hooks'
import { FILE_FORMATS, FILE_SIZE_HUMAN, FILE_SIZE_LIMIT_2MB } from '@percent/workplace-giving/constants/files'
import { FundraiserModal } from '@percent/workplace-giving/common/components/FundraiserModal/FundraiserModal'
import { okResponse } from '@percent/workplace-giving/api/goodstack'
import { Money } from '@percent/utility'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'

export function FundraiserWizard() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useAuth()
  const { logError } = useLogger()
  const { track } = useAnalytics()
  const partner = getPartnerFromAuthState(state)!
  const accountConfig = getAccountConfigFromAuthState(state)
  const defaultCountry = getCountryCodeFromAuthState(state)!
  const currency = accountConfig?.currency ?? partner.currencyCode
  const [coverImage, setCoverImage] = useState<File | undefined>(undefined)
  const [coverImageError, setCoverImageError] = useState<string | undefined>(undefined)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [openCancellationModal, setOpenCancellationModal] = useState(false)
  const [fundraiserId, setFundraiserId] = useState('')
  const currencyListGroup = useCurrencyListGroup()

  const { mutateAsync } = useMutation({
    mutationFn: createFundraiser,
    onSuccess: res => {
      if (okResponse(res)) {
        setOpenSuccessModal(true)
        setFundraiserId(res.data.id)
      }
    },
    onError: () => {
      setOpenErrorModal(true)
    }
  })

  const handleCoverImageChange = (file: File) => {
    setCoverImageError(undefined)

    if (!/(png|jpe?g)/i.exec(file.type))
      setCoverImageError(t('workplace_giving.validation.invalidFileType', { fileFormats: FILE_FORMATS }))

    if (file.size > FILE_SIZE_LIMIT_2MB)
      setCoverImageError(t('workplace_giving.validation.fileTooLarge', { fileSize: FILE_SIZE_HUMAN }))

    setCoverImage(file)
  }

  const {
    isValid,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty
  } = useFormik({
    initialValues: {
      title: '',
      story: '',
      money: {
        amount: undefined,
        currency: undefined
      },
      organisationCountry: defaultCountry,
      organisationId: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        title: Yup.string()
          .trim()
          .min(1, t('workplace_giving.validation.requiredField'))
          .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))
          .required(t('workplace_giving.validation.requiredField')),
        story: Yup.string()
          .trim()
          .min(1, t('workplace_giving.validation.requiredField'))
          .max(5000, t('workplace_giving.validation.maxCharacters', { max: 5000 }))
          .required(t('workplace_giving.validation.requiredField')),
        money: Yup.object().shape({
          amount: Yup.number()
            .required(t('workplace_giving.validation.requiredField'))
            .positive(t('workplace_giving.validation.positive'))
        }),
        organisationCountry: Yup.string().required(t('workplace_giving.validation.requiredField')),
        organisationId: Yup.string().required(t('workplace_giving.validation.requiredField'))
      }),
    onSubmit: async data => {
      const { story, title, money, ...formData } = data

      try {
        await mutateAsync({
          ...formData,
          page: {
            title,
            story
          },
          campaign: {
            goal: money as unknown as Money
          },
          kind: 'campaign',
          status: 'published',
          image: coverImage!
        })

        track({
          event: 'Donation Fundraiser Create Completed',
          properties: {
            kind: 'campaign',
            currencyCode: money.currency,
            organisationCountryCode: formData.organisationCountry
          }
        })
      } catch (e) {
        logError(e)
      }
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  const formValid = isValid && dirty && !!coverImage && !coverImageError && !isSubmitting

  return (
    <FullPageFormLayout
      actionsHeader={
        <WizardHeader
          title={t('workplace_giving.fundraiser.createEvent')}
          onCancel={() => {
            if (dirty || !!coverImage) {
              setOpenCancellationModal(true)
            } else {
              navigate(-1)
            }
          }}
          onPublish={handleSubmit}
          isPublishDisabled={!formValid}
          isLoading={isSubmitting}
        />
      }
    >
      <Typography sx={Styles.Title}>{t('workplace_giving.fundraiser.buildPage')}</Typography>
      <FundraiserWizardForm
        handleCoverImageChange={handleCoverImageChange}
        coverImageError={coverImageError}
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        currency={currency}
        currencies={currencyListGroup}
      />
      <FundraiserModal
        type="published"
        handleClose={() => {
          setOpenSuccessModal(false)
          navigate(`/fundraisers/${fundraiserId}`)
        }}
        openModal={openSuccessModal}
      />
      <FundraiserModal
        type="error"
        handleClose={() => {
          setOpenErrorModal(false)
        }}
        openModal={openErrorModal}
      />
      <FundraiserModal
        type="cancel"
        handleClose={() => setOpenCancellationModal(false)}
        handleSubmit={() => {
          setOpenCancellationModal(false)
          navigate(-1)
        }}
        openModal={openCancellationModal}
      />
    </FullPageFormLayout>
  )
}
