import { Badge, Card, Text, Tooltip, theme } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  OpportunityCardProps,
  StyledHeaderProps,
} from './opportunity-card.types'
import { Link } from 'react-router-dom-v6'

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`
const StyledHeader = styled.div<StyledHeaderProps>`
  position: relative;
  box-sizing: border-box;
  min-height: 130px;
  padding: 44.3% 0 0 0;
  background-color: ${theme.colors.gray70};
  ${({ $backgroundImage }) => `background-image: url(${$backgroundImage});`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
const StyledTagsWrapper = styled.div`
  position: absolute;
  top: ${theme.sizes[4]};
  left: 0;
  right: 0;
  padding: 0 ${theme.sizes[4]};
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${theme.sizes[1]};
  flex-wrap: wrap;
`
const StyledContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: space-between;
  height: 100%;
  padding: ${theme.sizes[6]};
`

const StyledInnerContentWrapper = styled.div`
  margin-top: ${theme.sizes[4]};
  margin-bottom: auto;
`
const StyledFooterWrapper = styled.div`
  margin-top: ${theme.sizes[2]};
  padding-top: ${theme.sizes[4]};
  border-top: 1px solid ${theme.colors.gray100};
`

export const OpportunityCard = ({
  tags,
  image,
  title,
  content,
  footer,
  onClick,
  to,
}: OpportunityCardProps) => {
  const { t } = useTranslation()

  const limitedTags = tags && tags.length > 4 ? tags?.slice(0, 3) : tags
  const nMoreTags = tags && tags.length > 4 ? tags.length - 3 : null
  return (
    <Card>
      <Link
        to={to}
        style={{
          width: '100%',
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex',
        }}
      >
        <StyledWrapper>
          <StyledHeader $backgroundImage={image}>
            <StyledTagsWrapper>
              {limitedTags?.map(({ tag, badgeVariant }, i) => (
                <Badge key={tag} variant={badgeVariant}>
                  {tag}
                </Badge>
              ))}
              {nMoreTags ? (
                <Badge variant="default">
                  {t('workplace_giving.card.andNMore', { n: nMoreTags })}
                </Badge>
              ) : null}
            </StyledTagsWrapper>
          </StyledHeader>
          <StyledContentWrapper>
            <Tooltip content={title} placement="top">
              <div>
                <Text
                  as="p"
                  size="large"
                  fontWeight="semiBold"
                  overflowEllipsis
                >
                  {title}
                </Text>
              </div>
            </Tooltip>
            <StyledInnerContentWrapper>{content}</StyledInnerContentWrapper>
            {footer ? (
              <StyledFooterWrapper>{footer}</StyledFooterWrapper>
            ) : null}
          </StyledContentWrapper>
        </StyledWrapper>
      </Link>
    </Card>
  )
}
