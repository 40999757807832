import { Styles } from '@percent/workplace-giving/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'
import { customPalette } from '@percent/workplace-giving/theme/theme'

export const Card = (active: boolean): Styles => ({
  cursor: 'pointer',
  padding: lemonadeTheme.sizes[6],
  borderRadius: lemonadeTheme.borderRadii.large,
  border: `2px solid ${active ? customPalette.primary400 : customPalette.info30}`,
  boxShadow: 'none',
  transition: 'border 0.3s',

  '&:hover': {
    borderColor: customPalette.primary400
  }
})

export const Title = (withMarginTop: boolean): Styles => ({
  marginTop: withMarginTop ? lemonadeTheme.sizes[4] : 0,
  fontSize: lemonadeTheme.fontSizes.body,
  fontWeight: lemonadeTheme.fontWeights.medium,
  lineHeight: lemonadeTheme.sizes[6]
})

export const Description: Styles = {
  fontSize: lemonadeTheme.fontSizes.bodySmall,
  lineHeight: lemonadeTheme.sizes[5],
  color: customPalette.gray600
}
