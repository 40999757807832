/* eslint-disable no-underscore-dangle */
import { useSearchParams } from 'react-router-dom-v6'
import { InfiniteData } from '@tanstack/react-query'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useMemo, useState } from 'react'
import throttle from 'lodash/throttle'

import { SearchResults } from '../Search/SearchResults/SearchResults'
import { HomePageSearch } from '../Search/HomePageSearch/HomePageSearch'
import { FeaturedCards } from '../Featured/FeaturedCards'

import { useAuth } from '@percent/workplace-giving/common/hooks'
import { SidePanel } from '@percent/workplace-giving/app/SidePanel/SidePanel'
import { VerifiedOrganisationSearchResult } from '@percent/workplace-giving/api/search/searchOrganisations/searchVerifiedOrganisations.types'
import countries from '@percent/workplace-giving/i18n/countries'
import { getCountryCodeFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { SupportedByColleagues } from './SupportedByColleagues/SupportedByColleagues'
import * as Styles from './Home.styles'
import { customBreakpoints } from '@percent/workplace-giving/utils/queryWidthLessThan'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { useOrganisationSearch } from '@percent/workplace-giving/common/hooks/useOrganisationSearch/useOrganisationSearch'

export function Home() {
  const authState = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('query') ?? undefined
  const [scrolled, setScrolled] = useState(false)
  const theme = useTheme()
  const isSmallDesktopScreen = useMediaQuery(theme.breakpoints.down(customBreakpoints.xl))
  const [searchElemHeight, setSearchElemHeight] = useState(0)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [searchParams])

  const scrolledRef = useRef(scrolled)
  const setScrolledValue = (data: boolean) => {
    scrolledRef.current = data
    setScrolled(data)
  }

  const handleScroll = throttle(() => {
    const scrolledFromTop = window.scrollY

    if (scrolledRef.current && scrolledFromTop > 0) {
      setScrolledValue(true)
    } else if (!scrolledRef.current && scrolledFromTop > 0) {
      setScrolledValue(true)
    } else {
      setScrolledValue(false)
    }
  }, 20)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const countryCode = useMemo(() => {
    const code = searchParams.get('country')

    if ((code && countries.isValid(code)) || code === '') {
      return code
    }

    const codeFromAuthState = getCountryCodeFromAuthState(authState.state)

    if (codeFromAuthState && countries.isValid(codeFromAuthState)) {
      return codeFromAuthState
    }

    return ''
  }, [authState.state, searchParams])

  const { data, isFetching, fetchNextPage, hasNextPage, isError, replacedByGlobalSearch, hideLocalSearch } =
    useOrganisationSearch({
      query,
      countryCode,
      localCurrencyCode: getCountryCodeFromAuthState(authState.state)!
    })

  const mappedData: InfiniteData<VerifiedOrganisationSearchResult[]> | undefined = data && {
    ...data,
    pages: data.pages.map(page => page.data)
  }
  const totalResults = data && data.pages.length > 0 ? data.pages[0].totalResults : 0

  const isHomePage = !mappedData && !isFetching
  const isSearch = !!mappedData || isFetching
  const hideSidePanel = (totalResults || totalResults === 0) && isSmallDesktopScreen

  return (
    <Box sx={Styles.PageContainer(searchElemHeight)}>
      <HomePageSearch
        search={query}
        countryCode={countryCode}
        scrolled={scrolled}
        onSearch={search => {
          setSearchParams(search)
        }}
        isError={isError}
        setSearchElemHeight={setSearchElemHeight}
      />
      <Page testId="home-page-wrapper">
        {isSearch && (
          <>
            <Box sx={Styles.RecommendedWrapper}>
              <SearchResults
                searchTerm={query!}
                totalResults={totalResults}
                isLoading={isFetching}
                data={mappedData}
                hasMore={hasNextPage}
                loadMore={() => fetchNextPage()}
                countryCode={countryCode}
                setSearchParams={setSearchParams}
                replacedByGlobalSearch={replacedByGlobalSearch}
                userCountryCode={getCountryCodeFromAuthState(authState.state)}
                hideLocalSearch={hideLocalSearch}
              />
            </Box>
            {!hideSidePanel && <SidePanel home />}
          </>
        )}
        {isHomePage && (
          <>
            <Box sx={Styles.RecommendedWrapper} display="flex" flexDirection="column" gap={5}>
              <FeaturedCards />
              <SupportedByColleagues />
            </Box>
            <SidePanel home />
          </>
        )}
      </Page>
    </Box>
  )
}
