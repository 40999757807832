import { useTranslation } from 'react-i18next'

import { ActionModal, Modal } from '@percent/lemonade'

type RemoveSessionEventModalProps = {
  isOpen: boolean
  handleClose: VoidFunction
  handleSubmit: VoidFunction
  isEditFlow: boolean
}

export function RemoveSessionEventModal({
  isOpen,
  handleClose,
  handleSubmit,
  isEditFlow
}: RemoveSessionEventModalProps) {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ActionModal
        title={t(
          isEditFlow ? 'workplace_giving.cancelOpportunity.title' : 'workplace_giving.wizard.removeSession.title'
        )}
        primaryButtonText={t(
          isEditFlow ? 'workplace_giving.volunteering.cancelSession' : 'workplace_giving.common.remove'
        )}
        secondaryButtonText={t('workplace_giving.common.cancel')}
        handleClose={handleClose}
        handleSubmit={() => {
          handleSubmit()
          handleClose()
        }}
      >
        {t(`workplace_giving.wizard.${isEditFlow ? 'cancelSession' : 'removeSession'}.description`)}
      </ActionModal>
    </Modal>
  )
}
