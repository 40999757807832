import React from 'react'
import { t } from 'i18next'
import { Box, Skeleton, useMediaQuery } from '@mui/material'
import { InfiniteData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom-v6'

import * as Styles from './Fundraiser.styles'
import { useInfiniteQuery, useLogger, useQuery } from '@percent/workplace-giving/common/hooks'
import { getFundraiserDetails } from '@percent/workplace-giving/api/fundraiser/getFundraiserDetails/getFundraiserDetails'
import { IllustratedMessage } from '@percent/lemonade'
import { PageContent } from '@percent/workplace-giving/common/components/Layout/PageContent'
import { Page } from '@percent/workplace-giving/common/components/Layout/Page'
import { Donation } from 'libs/domain/fundraiser/src/components/recent-donations-list/recent-donations-list.types'
import { getFundraiserTotals } from '@percent/workplace-giving/api/fundraiser/getFundraiserTotals/getFundraiserTotals'
import { theme } from '@percent/workplace-giving/theme/theme'
import { customBreakpoints } from '@percent/workplace-giving/utils/queryWidthLessThan'
import { getFundraiserDonationList } from '@percent/workplace-giving/api/fundraiser/getFundraiserDonationList/getFundraiserDonationList'
import { SidePanelContainer } from '@percent/workplace-giving/common/components/Layout/SidePanelContainer/SidePanelContainer'
import { SidePanelWrapper } from '@percent/workplace-giving/common/components/Layout/SidePanelWrapper/SidePanelWrapper'
import { DetailsAndDonationListSkeleton } from '@percent/workplace-giving/common/components/FundraiserSkeleton/DetailsAndDonationListSkeleton'
import { SidePanelSkeleton } from '@percent/workplace-giving/common/components/FundraiserSkeleton/SidePanelSkeleton'
import { FundraiserPage } from './FundraiserPage/FundraiserPage'

// [ tech debt] refactor fundraiser page
export function Fundraiser() {
  const { logError } = useLogger()
  const { fundraiserSlug } = useParams()

  const {
    data: fundraiserDetailsData,
    isLoading,
    error
  } = useQuery(['getFundraiserDetails', fundraiserSlug!], getFundraiserDetails, {
    enabled: !!fundraiserSlug
  })

  const {
    data: fundraiserTotalsData,
    isLoading: fundraiserTotalsDataLoading,
    error: fundraiserTotalsDataError,
    refetch: refetchFundraiserTotalsData
  } = useQuery(['getFundraiserTotal', fundraiserSlug!], getFundraiserTotals)
  const isMobile = useMediaQuery(theme.breakpoints.down(customBreakpoints.xl))

  const {
    data: recentDonationsPageData,
    isLoading: dataLoading,
    error: dataError,
    hasNextPage,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    refetch: refetchRecentDonationPageData
  } = useInfiniteQuery(['getFundraiserDonations', { id: fundraiserSlug! }], getFundraiserDonationList)

  const mappedData: InfiniteData<Donation[]> | undefined = recentDonationsPageData && {
    ...recentDonationsPageData,
    pages: recentDonationsPageData.pages?.map(page => page.data)
  }

  if (isLoading || dataLoading || fundraiserTotalsDataLoading) {
    return !isMobile ? (
      <Page>
        <PageContent>
          <Box display="flex" flexDirection="column" gap={2} data-testid="fundraiser-page-skeleton-loader">
            <Skeleton variant="rectangular" width="100%" height={250} />
            <DetailsAndDonationListSkeleton />
          </Box>
        </PageContent>
        <SidePanelContainer>
          <SidePanelWrapper>
            <SidePanelSkeleton />
          </SidePanelWrapper>
        </SidePanelContainer>
      </Page>
    ) : (
      <Page>
        <Box sx={Styles.FundraiserMobilePage}>
          <Skeleton variant="rectangular" width="100%" height={250} />
          <SidePanelSkeleton isMobile />
          <DetailsAndDonationListSkeleton />
        </Box>
      </Page>
    )
  }

  if (error || isError || fundraiserTotalsDataError) {
    logError(error || dataError || fundraiserTotalsDataError)

    return (
      <IllustratedMessage
        title={t('workplace_giving.errors.somethingWentWrong')}
        description={t('workplace_giving.errors.tryAgainLater')}
        illustration="general-error"
      />
    )
  }

  return (
    <Page>
      {fundraiserDetailsData && mappedData && fundraiserTotalsData && (
        <FundraiserPage
          fundraiserDetailsData={fundraiserDetailsData}
          fundraiserTotalsData={fundraiserTotalsData}
          mappedData={mappedData}
          hasNextPage={!!hasNextPage}
          fetchNextPage={fetchNextPage}
          dataError={!!dataError}
          dataLoading={dataLoading || isFetchingNextPage}
          isMobile={isMobile}
          refetchFundraiserTotalsData={refetchFundraiserTotalsData}
          refetchRecentDonationPageData={refetchRecentDonationPageData}
        />
      )}
    </Page>
  )
}
