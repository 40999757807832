import { useCallback, useMemo, useState } from 'react'

import { DonationForm, DonationFormProps } from '@percent/workplace-giving/common/components'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { createDonationSession } from '@percent/workplace-giving/api/donation/create-donation-session/create-donation-session'

export const useDonationForm = () => {
  const { state } = useAuth()
  const partner = getPartnerFromAuthState(state)!
  const accountConfig = getAccountConfigFromAuthState(state)

  const [open, setIsOpen] = useState(false)
  const [url, setUrl] = useState('')

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createDonationSession
  })

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setUrl('')
  }, [])

  const handleDonate = useCallback(
    async (organisationId: string) => {
      if (accountConfig?.currency || partner.currencyCode) {
        setIsOpen(true)

        const response = await mutateAsync({
          organisationId,
          currency: accountConfig?.currency ?? partner.currencyCode
        })
        setUrl(response.data.url)
      }
    },
    [accountConfig?.currency, partner.currencyCode, mutateAsync]
  )

  const DonationFormWrapper = useCallback(
    (props: Omit<DonationFormProps, 'loading' | 'open' | 'url' | 'onClose'>) => (
      <DonationForm {...props} loading={isLoading} open={open} url={url} onClose={handleClose} />
    ),
    [isLoading, open, url, handleClose]
  )

  return useMemo(
    () => ({
      DonationFormWrapper,
      handleDonate
    }),
    [DonationFormWrapper, handleDonate]
  )
}
