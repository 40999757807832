import { Avatar, Text } from '@percent/lemonade'
import { createShortLink } from '@percent/utility'
import styled from 'styled-components'
import { OrganisationNameAndFlag } from '../organisation-name-and-flag/organisation-name-and-flag'
import { OrganisationHeaderWithImageProps } from './organisation-header-with-image.types'

const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const StyledCardContentLinks = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    p:nth-of-type(1) {
      margin-top: 4px;
    }
  }

  p:last-child {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      align-items: flex-start;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 25px;
      margin: 0 8px;
      background: #e1e2e3;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }
`

const StyledLink = styled.a`
  ${({ theme }) =>
    `color:  ${theme.colors.primary};
    font-weight: 400;
  `}
`

const StyledOrganisationHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const OrganisationHeaderWithImage = ({
  registryId,
  iconUrl,
  title,
  countryCode,
  website,
}: OrganisationHeaderWithImageProps) => {
  return (
    <StyledCardHeader>
      <StyledOrganisationHeader>
        <OrganisationNameAndFlag name={title} countryCode={countryCode} />
        <StyledCardContentLinks>
          {website ? (
            <StyledLink
              href={website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {createShortLink(website)}
            </StyledLink>
          ) : null}
          {registryId && (
            <Text as="p" size="small">
              {registryId}
            </Text>
          )}
        </StyledCardContentLinks>
      </StyledOrganisationHeader>
      {iconUrl ? (
        <Avatar image={iconUrl} alt={title || ''} size="large" />
      ) : null}
    </StyledCardHeader>
  )
}
