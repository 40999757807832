import { Axios } from 'axios'

import { GsResponse } from '../../goodstack'
import { Opportunity, OpportunityType } from '../../search/searchOpportunities/searchOpportunities.types'

import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CreateOpportunityParams } from './create-opportunity.types'
import { createEvent } from './create-event'
import { createProject } from './create-project'
import { createEventSeries } from './create-event-series'
import { createEventSeriesSession } from './create-event-series-session'

export const createOpportunity: MutationFn<[CreateOpportunityParams], GsResponse<Opportunity>> = async (
  client: Axios,
  [params]
) => {
  switch (params.type) {
    case OpportunityType.EVENT:
      return createEvent(client, [params])
    case OpportunityType.PROJECT:
      return createProject(client, [params])
    case OpportunityType.EVENT_SERIES:
      return createEventSeries(client, [params])
    case OpportunityType.EVENT_SERIES_SESSION:
      return createEventSeriesSession(client, [params])
    default:
      throw new Error('Invalid opportunity type')
  }
}
