import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Icon } from '@percent/lemonade'
import { Opportunity } from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'
import { LocaleKey } from '@percent/workplace-giving/i18n/config'
import * as Styles from './SkillsAndImpact.styles'

type SkillsAndImpactProps = {
  activities: Opportunity['activities']
  skills: Opportunity['skills']
}

export function SkillsAndImpact({ activities, skills }: Readonly<SkillsAndImpactProps>) {
  const { t } = useTranslation()
  const skillsList = skills?.map((skill: { id: string; name: string }) =>
    t(`workplace_giving.volunteering.skills.${skill.id}` as LocaleKey, {
      defaultValue: skill.name
    })
  )

  const activitiesList = activities?.map(activity => activity.name)

  return (
    <Box>
      {skillsList?.length ? (
        <Box sx={Styles.Wrapper}>
          <Box sx={Styles.IconWrapper}>
            <Icon name="hammer" color="black" size={6} />
          </Box>
          <Box>
            <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.requiredSkills')}</Typography>
            <Typography sx={Styles.Content}>{skillsList.join(', ')}</Typography>
          </Box>
        </Box>
      ) : null}

      {activitiesList?.length ? (
        <Box sx={Styles.Wrapper}>
          <Box sx={Styles.IconWrapper}>
            <Icon name="heart" color="alert400" size={6} />
          </Box>
          <Box>
            <Typography sx={Styles.Title}>{t('workplace_giving.volunteering.impactActivityTags')}</Typography>
            <Typography sx={Styles.Content}>{activitiesList.join(', ')}</Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
