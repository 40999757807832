import { Typography, Card } from '@mui/material'

import { Icon } from '@percent/lemonade'
import { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'
import * as Styles from './SelectOptionCard.styles'

type SelectOptionCardProps = {
  testId: string
  icon?: IconProps['name']
  title: string
  description: string
  active: boolean
  onClick: VoidFunction
}

export function SelectOptionCard({
  testId,
  icon,
  title,
  description,
  active,
  onClick
}: Readonly<SelectOptionCardProps>) {
  return (
    <Card data-testid={testId} onClick={onClick} sx={Styles.Card(active)}>
      {icon ? <Icon name={icon} size={6} color="primary400" /> : null}
      <Typography sx={Styles.Title(!!icon)} component="p">
        {title}
      </Typography>
      <Typography sx={Styles.Description} component="p">
        {description}
      </Typography>
    </Card>
  )
}
