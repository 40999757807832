import { Box } from '@mui/material'

import * as Styles from './AchievementBadge.styles'
import { Icon } from '@percent/lemonade'

type AchievementBadgeProps = {
  image: string
  name: string
  isBlocked?: boolean
}

export function AchievementBadge({ image, name, isBlocked }: Readonly<AchievementBadgeProps>) {
  return (
    <Box sx={Styles.BadgeWrapper}>
      <img src={image} alt={name} />
      {isBlocked ? (
        <Box sx={Styles.LockedBadgeCover}>
          <Icon name="lock-outline" color="primary" size={5} />
        </Box>
      ) : null}
    </Box>
  )
}
