import { Styles } from '@percent/workplace-giving/theme'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const MainWrapper: Styles = theme => ({
  marginTop: '80px',
  marginX: '64px',
  [theme.breakpoints.down('md')]: {
    marginX: '24px'
  },

  '*': {
    fontFamily: 'Inter, sans-serif'
  },

  '@media (min-width: 1568px)': {
    marginX: 'auto',
    maxWidth: '1440px'
  },
  [queryWidthLessThan('l')]: {
    marginX: lemonadeTheme.sizes[6]
  }
})
