import { Styles } from '@percent/workplace-giving/theme'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const Wrapper = (offset?: number): Styles => ({
  width: '360px',
  zIndex: 10,
  marginTop: `${offset ?? 0}px`,

  [queryWidthLessThan('xl')]: {
    position: 'relative',
    width: 'unset',
    marginTop: '0px',
    zIndex: 0
  }
})

export const Filler = (panelHeight?: number): Styles => ({
  height: `calc(100% - ${panelHeight ?? 800}px)`,

  [queryWidthLessThan('xl')]: {
    display: 'none'
  }
})

export const StickyContainer = (maxHeight?: number): Styles => ({
  position: 'sticky',
  top: '110px',

  [queryWidthLessThan('xl')]: {
    position: 'relative',
    bottom: 'unset !important',
    top: 'unset !important'
  },

  [`@media (max-height: ${maxHeight ?? 20}px)`]: {
    bottom: '24px',
    top: 'unset'
  }
})
