import { useCallback, useEffect, useMemo, useState } from 'react'

import { RateLimitContext } from '../rateLimitContext/RateLimitContext'

import { GenericError } from '@percent/workplace-giving/common/components/GenericError/GenericError'

const DEFAULT_RETRY_AFTER = 10000

export function RateLimitContextController({ children }: { children: React.ReactNode }) {
  const [rateLimitState, setRateLimitHitValue] = useState({
    rateLimitHit: false,
    retryAfter: DEFAULT_RETRY_AFTER
  })
  const setRateLimitHit = useCallback(({ retryAfter }: { retryAfter?: number }) => {
    setRateLimitHitValue({
      rateLimitHit: true,
      retryAfter: retryAfter || DEFAULT_RETRY_AFTER
    })
  }, [])

  useEffect(() => {
    if (rateLimitState.rateLimitHit) {
      setTimeout(() => {
        setRateLimitHitValue({
          rateLimitHit: false,
          retryAfter: DEFAULT_RETRY_AFTER
        })
      }, rateLimitState.retryAfter)
    }
  }, [rateLimitState.rateLimitHit, rateLimitState.retryAfter])

  const value = useMemo(
    () => ({
      rateLimitHit: rateLimitState.rateLimitHit,
      setRateLimitHit
    }),
    [rateLimitState.rateLimitHit, setRateLimitHit]
  )

  if (rateLimitState.rateLimitHit) {
    return (
      <GenericError
        title="429 Error"
        description="Sorry you have sent us too many requests recently, please try again shortly"
      />
    )
  }

  return <RateLimitContext.Provider value={value}>{children}</RateLimitContext.Provider>
}
