import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { getLoginHeadersForApp } from '@percent/domain/iam'
import { EmailLoginProps, EmailLoginResponse } from './emailLogin.types'

export const emailLogin: MutationFn<EmailLoginProps, EmailLoginResponse> = async (client, { email, password }) => {
  try {
    const res = await client.post(
      `/auth/login`,
      {
        email,
        password,
        enforce: true
      },
      {
        headers: getLoginHeadersForApp('workplaceGiving')
      }
    )

    return {
      result: 'success',
      data: res.data
    }
  } catch (error) {
    if (error?.response?.data?.error?.code === 'auth/invalid_credentials_2' || error?.response?.data?.error?.reasons) {
      return {
        result: 'error',
        code: 'auth/invalid_credentials_2'
      }
    }

    throw error
  }
}
