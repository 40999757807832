import { ThemeProvider } from '@mui/material'

import { ThemeContextProps } from './ThemeProvider.types'
import { theme } from '@percent/workplace-giving/theme/theme'

export function ThemeContext({ children }: ThemeContextProps) {
  return (
    <ThemeProvider
      theme={{
        ...theme,
        palette: {
          ...theme.palette
          // todo update clientColor and detectedTextColor based on partnerConfig
          // clientColor: partnerConfig?.branding.primaryColor || theme.palette.clientColor
          // detectedTextColor: partnerPrimaryColor
          //   ? tinycolor(partnerPrimaryColor).isDark() ? 'white' : 'black'
          //   : theme.palette.detectedTextColor
        }
      }}
    >
      {children}
    </ThemeProvider>
  )
}
