import { AxiosInstance } from 'axios'

import { CoreListResponse } from '../../goodstack'

export type AccountTerms = { key: string }

export const getAccountTerms = async (client: AxiosInstance): Promise<string[]> => {
  const {
    data: { data: allTerms }
  } = await client.get<CoreListResponse<AccountTerms>>('/account/terms', {
    withCredentials: true
  })

  return allTerms.map(t => t.key)
}
