import { AxiosInstance } from 'axios'
import { Alpha3Code } from 'i18n-iso-countries'

import { RawResponse, toGsResponse } from '../../goodstack'

import { CurrencyCode } from '@percent/utility'

export type AccountConfig = {
  accountId?: string | null
  currency?: CurrencyCode | null
  country?: Alpha3Code | null
  city?: string | null
  role?: string | null
}

export const getAccountConfig = async (client: AxiosInstance): Promise<AccountConfig | undefined> =>
  client
    .get<RawResponse<AccountConfig>>('/account/giving/config', {
      withCredentials: true,
      validateStatus: (code: number) => code === 200 || code === 404,
      params: {
        t: new Date().getTime()
      }
    })
    .then(r => toGsResponse(r.status, r.data))
    .then(r => (r.result === 'success' ? r.data : undefined))
    .catch(() => undefined)
