import { Typography, Box } from '@mui/material'

import { AchievementBadge } from '../AchievementBadge/AchievementBadge'

import * as Styles from './GamificationBadges.styles'
import { Loader, Tooltip } from '@percent/lemonade'
import { useQuery } from '@percent/workplace-giving/common/hooks'
import { getAccountAchievements } from '@percent/workplace-giving/api/account/achievements/achievements'

export function GamificationBadges() {
  const { data, status } = useQuery(['get'], getAccountAchievements)

  if (status === 'loading' || status === 'error' || !data)
    return (
      <div data-testid="gamification-badges-container-loader">
        <Loader />
      </div>
    )

  return (
    <Box sx={Styles.AchievementsBox} data-testid="gamification-badges-container">
      {data.rulesAndAchievements.map(({ accountAchievement, achievement: { name, image }, rule }) => {
        return (
          <Tooltip
            key={`${name}-tooltip`}
            content={
              <Box paddingY={1} paddingX={0.5}>
                {accountAchievement ? (
                  <Typography sx={Styles.AchievementName}>You have earned the {name} badge</Typography>
                ) : (
                  <Typography sx={Styles.AchievementName}>{name}</Typography>
                )}
                <Typography sx={Styles.AchievementStepsDescription}>{rule.conditions.description}</Typography>
                <Box component="ul" sx={Styles.StepsList}>
                  {rule.conditions.conditions.map(({ pass, factKind, operator, description }) => (
                    <Typography component="li" sx={Styles.StepsListItem(pass)} key={`${operator}-${factKind}`}>
                      {description}
                    </Typography>
                  ))}
                </Box>
              </Box>
            }
            placement="bottom-start"
            lightBg
          >
            <div data-testid="achievement-tooltip-trigger">
              <AchievementBadge image={image} name={name} isBlocked={!accountAchievement} />
            </div>
          </Tooltip>
        )
      })}
    </Box>
  )
}
