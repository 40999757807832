import { Button, ButtonText } from '@percent/lemonade'
import { OrganisationCardProps } from './organisation-card.types'
import styled from 'styled-components'
import { OrganisationHeaderWithImage } from '../organisation-header-with-image/organisation-header-with-image'
import { useEffect, useRef, useState } from 'react'

const StyledCardContainer = styled.div`
  ${({ theme }) => `
  padding: ${theme.sizes.m};
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 #0D0D0D14;
  `}
`

const StyledCardContentDescriptionContainer = styled.div`
  margin-bottom: 24px;
`

const StyledCardContentDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #494550;
  margin-bottom: 0;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.showMore {
    -webkit-line-clamp: unset;
  }
`

const StyledCardButton = styled.div`
  display: flex;
  align-items: center;

  button {
    ${({ theme }) => `
      margin-right: ${theme.sizes.xs};
    `}
  }
`

export const OrganisationCard = ({
  id,
  registryId,
  iconUrl,
  title,
  description,
  countryCode,
  website,
  cardFooterArea,
  buttonText,
  moreText,
  lessText,
  onDonateClicked,
  cardButtonInfo,
}: OrganisationCardProps) => {
  const descriptionRef = useRef<HTMLParagraphElement>(null)
  const [hasMore, setHasMore] = useState(false)
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    if (descriptionRef.current) {
      setHasMore(
        descriptionRef.current.scrollHeight >
          descriptionRef.current.clientHeight
      )
    }
  }, [descriptionRef.current])

  return (
    <StyledCardContainer>
      <div>
        <OrganisationHeaderWithImage
          title={title}
          countryCode={countryCode}
          registryId={registryId}
          iconUrl={iconUrl}
          website={website}
        />
        {description ? (
          <StyledCardContentDescriptionContainer>
            <StyledCardContentDescription
              ref={descriptionRef}
              className={showMore ? 'showMore' : ''}
            >
              {description}
            </StyledCardContentDescription>
            {hasMore && (
              <ButtonText onPress={() => setShowMore((prev) => !prev)}>
                {showMore ? lessText : moreText}
              </ButtonText>
            )}
          </StyledCardContentDescriptionContainer>
        ) : null}
        <StyledCardButton>
          <Button onPress={() => onDonateClicked(id)} size="small">
            {buttonText}
          </Button>
          {cardButtonInfo}
        </StyledCardButton>
        {cardFooterArea}
      </div>
    </StyledCardContainer>
  )
}
