import { Link as Mlink, LinkProps } from '@mui/material'

import * as Styles from './Link.styles'

export function Link({ children, sx, ...props }: LinkProps) {
  return (
    <Mlink sx={sx || Styles.Link} {...props}>
      {children}
    </Mlink>
  )
}
