import { MutationFn } from '@percent/workplace-giving/common/hooks/useMutation/useMutation.types'
import { CreateDonationSessionProps, CreateDonationSessionResponse } from './create-donation-session.types'

export const createDonationSession: MutationFn<CreateDonationSessionProps, CreateDonationSessionResponse> = async (
  client,
  { organisationId, currency }
) => {
  const res = await client.post<CreateDonationSessionResponse>(
    `/donation-sessions`,
    {
      organisationId,
      currency,
      askAnonymity: false
    },
    {
      withCredentials: true
    }
  )

  return res.data
}
