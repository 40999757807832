import { Card, Box, Skeleton } from '@mui/material'

import * as Styles from './SearchResultCardSkeleton.styles'

export function SearchResultCardSkeleton({ supportedByColleaguesVariant }: { supportedByColleaguesVariant?: boolean }) {
  return (
    <Card sx={Styles.Card} elevation={0} data-testid="search-result-skeleton">
      <Box sx={Styles.CardContent}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1} marginBottom={1}>
          <Skeleton variant="rectangular" width={180} height={24} />
          <Skeleton variant="rectangular" width={30} height={24} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1} marginBottom={2.5}>
          <Skeleton variant="rectangular" width="80%" height={25} />
        </Box>

        <Skeleton variant="rectangular" height={96} style={{ marginBottom: 24 }} />
        <Skeleton variant="rectangular" width={92} height={32} style={{ borderRadius: 4 }} />

        {supportedByColleaguesVariant && (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1} marginTop={3}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rectangular" width="60%" height={24} />
          </Box>
        )}
      </Box>

      <Skeleton variant="rectangular" />
    </Card>
  )
}
