import { createContext } from 'react'

import { AnalyticsContextType, AnalyticsEvent } from './AnalyticsContext.types'

export const defaultAnalyticsContext: AnalyticsContextType = {
  track: (_: { event: AnalyticsEvent; properties?: Record<string, any>; accountId?: string }) => {},
  page: (_: { category: string; name: string; properties?: Record<string, any>; accountId?: string }) => {},
  identify: (_: { accountId: string; traits?: Record<string, any> }) => {},
  loaded: false
}

export const AnalyticsContext = createContext<AnalyticsContextType>(defaultAnalyticsContext)
